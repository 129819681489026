import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { getRgbaStrFromHexColor } from 'styles/utils/color';

const SuccessMessage = styled.p`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        background-color: ${getRgbaStrFromHexColor(colors.system.success, 0.5)};
        padding: ${rem(8)} ${rem(12)};
        text-align: center;
    `}
`;

export { SuccessMessage };
