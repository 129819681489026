import { ReactElement } from 'react';
import { ButtonPrimary } from '../../button/button';
import { AzureMP } from 'react-azure-mp';
import {
    ModalVideoWrapper,
    ModalVideoSource,
    ModalVideoFooter,
    ModalVideoDataList,
    ModalVideoDataDescription
} from './ModalVideoWrapper';
import Player from 'modules/app/Player';

export type ModalVideoProps = {
    videoSrc?: string;
    title?: string;
    description?: string;
    btnLabel?: string;
    dataSection?: string;
    thubnailSrc?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const ModalVideo = ({
    videoSrc,
    title,
    description,
    btnLabel,
    dataSection,
    thubnailSrc,
    onClick
}: ModalVideoProps): ReactElement => {
    return (
        <ModalVideoWrapper>
            <ModalVideoSource>
                <Player
                    id={videoSrc!}
                    sourceConfig={{
                        hls: videoSrc!,
                        poster: thubnailSrc
                    }}
                ></Player>
            </ModalVideoSource>
            <ModalVideoFooter>
                <ModalVideoDataList>
                    <li>{title}</li>
                    <ModalVideoDataDescription>
                        {description}
                    </ModalVideoDataDescription>
                </ModalVideoDataList>
                <ButtonPrimary
                    iconName='arrow-right'
                    iconPosition='right'
                    btnSize='fullWidth'
                    btnAlign='between'
                    isFontSizeLarge
                    onClick={onClick}
                    data-section={dataSection}
                >
                    {btnLabel}
                </ButtonPrimary>
            </ModalVideoFooter>
        </ModalVideoWrapper>
    );
};

export { ModalVideo };
