import { useMemo, useCallback, MouseEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { ThemeColors } from 'styles/theme';
import { useTracker } from 'hooks/use-tracker';
import { ClinicArea } from 'domain/clinic-overview';
import { routes } from 'modules/routes/routes-config';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { HotspotModel, HotspotPositionCSS } from 'domain/hotspot';
import { Hotspot, HotspotButton } from 'modules/app/shared/hotspot/hotspot';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';
import { useTranslation } from 'react-i18next';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import {
    TopicSelectorInfo,
    TopicSelectorModal
} from 'modules/app/clinic-overview/topic-selector-modal/topic-selector-modal';

type ClinicHotspotWrapperProps = {
    index: number;
    color: keyof ThemeColors['navigation'];
};

const ClinicHotspotWrapper = styled.div<ClinicHotspotWrapperProps>`
    ${({
        theme: {
            base: { colors, zindex }
        },
        index,
        color
    }) => `
        animation: show 0.5s;
        animation-delay: ${index / 3}s;
        animation-fill-mode: forwards;
        position: absolute;
        visibility: hidden;

        @keyframes show {
            99% { visibility: hidden; }
            100% { visibility: visible; }
        }

        &:hover {
            z-index: ${zindex.above};
        }

        ${HotspotButton} {
            &, &:active {
                background-color: ${colors.navigation[color]};
                box-shadow: 0 0 0 ${rem(6)} ${getRgbaStrFromHexColor(
        colors.navigation[color],
        0.3
    )};
                color: ${colors.neutral.fullLight};
            }

            &:hover {
                box-shadow: 0 0 0 ${rem(6)} ${getRgbaStrFromHexColor(
        colors.navigation[color],
        0.4
    )};
            }
        }
    `}
`;

export type ClinicHotspotsLayerProps = {
    area: ClinicArea | null;
    onModalVisible: (visible: boolean) => void;
};

const ClinicHotspotsLayer = ({
    area,
    onModalVisible
}: ClinicHotspotsLayerProps) => {
    const { t } = useTranslation();
    const { state } = useClinicOverviewContext();
    const history = useHistory();
    const { setDataForTracker } = useTracker();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const country = getCountryFromUrl();
    const [
        showWaterTreatmentSelectorModal,
        setShowWaterTreatmentSelectorModal
    ] = useState(false);

    useEffect(() => {
        onModalVisible(showWaterTreatmentSelectorModal);
    }, [showWaterTreatmentSelectorModal]);

    const hotspots = useMemo(() => {
        return area?.paintingInfo
            .flatMap((info) => info.hotspots ?? [])
            .map((hotspot) => ({
                hotspot,
                topic: state.topics.find((t) => t.name === hotspot.roomId)
            }))
            .filter((x) => x.hotspot && x.topic)
            .map((x) => ({
                ...x.hotspot,
                title: x.topic!.nameLanguage,
                topicId: x.topic!.id
            }));
    }, [area, state.topics]);

    const navigateToRoom = useCallback(
        ({ areaId, roomId }: { areaId: string; roomId: string }) => {
            history.push(
                routes.room.getRoute(
                    areaId,
                    roomId,
                    country,
                    supportedCountries
                )
            );
        },
        []
    );

    if (area && hotspots) {
        const waterTreatment1Info = {
            id: 10,
            subTitle: t('waterTreatments.waterTreatment1.subTitle'),
            description: (
                <>
                    The Water Treatment Room 1 consists of
                    <ul className='dashed'>
                        <li>Dialysis Water Pre-Treatment,</li>
                        <li>the AquaA product portfolio in combination with</li>
                        <li>
                            Granumixplus, the in-house dialysis concentrate
                            preparation with integrated CDS module (CDSplus)
                        </li>
                    </ul>
                </>
            )
        };
        const waterTreatment2Info = {
            id: 50,
            subTitle: t('waterTreatments.waterTreatment2.subTitle'),
            description: (
                <>
                    The Water Treatment Room 2 consists of
                    <ul className='dashed'>
                        <li>Dialysis Water Pre-Treatment,</li>
                        <li>
                            the AquaBplus product portfolio in combination with
                        </li>
                        <li>
                            Granumix 507S, the in-house dialysis concentrate
                            preparation
                        </li>
                        <li>
                            CDS3, the central distribution system for dialysis
                            concentrates.
                        </li>
                    </ul>
                </>
            )
        };
        const waterTreatmentInfos = [waterTreatment1Info, waterTreatment2Info];

        const topicSelectorInfos: TopicSelectorInfo[] = [];

        const renderHotspots = hotspots.map((hotspot, index) => {
            const waterTreatmentInfo = waterTreatmentInfos.find(
                (x) => x.id === hotspot.topicId
            );
            if (waterTreatmentInfo) {
                topicSelectorInfos.push({
                    topic: state.topics.find((t) => t.id === hotspot.topicId)!,
                    subTitle: waterTreatmentInfo.subTitle,
                    description: waterTreatmentInfo.description,
                    roomId: hotspot.roomId,
                    areaId: hotspot.areaId
                });
            }

            return (
                <ClinicHotspotWrapper
                    key={hotspot.roomId}
                    index={index}
                    color={area.color}
                    style={hotspot.position}
                >
                    {country === 'de' &&
                    hotspot.topicId === waterTreatment1Info.id ? (
                        <Hotspot
                            title={t('waterTreatments.defaultTitle')}
                            onClick={(event: MouseEvent<HTMLElement>) => {
                                setShowWaterTreatmentSelectorModal(true);
                                event.stopPropagation();
                            }}
                            hotspotStyle='default'
                        />
                    ) : (
                        <Hotspot
                            {...setDataForTracker({
                                category: 'hotspot',
                                action: `${hotspot.areaId}-${hotspot.roomId}`
                            })}
                            title={hotspot.title}
                            onClick={(event: MouseEvent<HTMLElement>) => {
                                navigateToRoom(hotspot);
                                event.stopPropagation();
                            }}
                            hotspotStyle='default'
                        />
                    )}
                </ClinicHotspotWrapper>
            );
        });

        const renderWaterTreatmentSelectorModal = () => {
            if (!showWaterTreatmentSelectorModal) {
                return <></>;
            }

            return (
                <TopicSelectorModal
                    topicInfos={topicSelectorInfos}
                    onCancel={() => setShowWaterTreatmentSelectorModal(false)}
                    onSubmit={(areaId, roomId) => {
                        setShowWaterTreatmentSelectorModal(false);
                        navigateToRoom({ areaId, roomId });
                    }}
                />
            );
        };

        return (
            <>
                {renderHotspots}
                {renderWaterTreatmentSelectorModal()}
            </>
        );
    }

    return null;
};

export { ClinicHotspotsLayer };
