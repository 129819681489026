import React, { PropsWithChildren, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { PanoramicNavigation } from '../../../viewer/navigation/panoramic-navigation';
import { Iframe, IframeWrapper } from 'modules/app/shared/iframe/iframe';
import { useTranslation } from 'react-i18next';
import { Content } from 'domain/hotspot';

export type ModalHotspotContentProps = {
    contents?: Content[];
    therapyAlphaNumericId?: string;
    onClose?: () => void;
};

const HotspotSingleContentWrapper = styled.div`
    ${({
        theme: {
            base: { breakpoints }
        }
    }) => `
        margin: ${rem(57)} ${rem(45)} ${rem(44)} 0;

        iframe {
            height: ${rem(620)};
            width: 100%;

            @media (min-width: ${rem(breakpoints.large)}) {
                height: 68vh;
            }
        }
    `}
`;

const HotspotContentPreview = styled.div`
    ${({
        theme: {
            base: { breakpoints }
        }
    }) => `
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        iframe, div {
            height: ${rem(270)};
            pointer-events: none;

            @media (min-width: ${rem(breakpoints.large)}) {
                height: ${rem(440)};
            }
        }

        div[class*="azure-mp-container"] {
            padding-bottom: 0 !important;
        }
    `}
`;

const HotspotContentPreviewTitle = styled.h4`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig }
            }
        }
    }) => `
        ${getTextsConfig('ML')};
        margin: ${rem(20)} 0 ${rem(15)};
    `}
`;

const HotspotMultiContentWrapper = styled.div`
    ${({
        theme: {
            base: { colors, breakpoints }
        }
    }) => `
        background-color: ${colors.neutral.warmGrey};
        box-sizing: border-box;
        display: grid;
        gap: ${rem(36)};
        grid-auto-rows: max-content;
        grid-template-columns: repeat(2, 1fr);
        max-height: ${rem(620)};
        min-height: ${rem(620)};
        margin: ${rem(57)} ${rem(45)} ${rem(44)} 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding: ${rem(50)};

        @media (min-width: ${rem(breakpoints.large)}) {
            max-height: 68vh;
            min-height: 68vh;
        }
    `}
`;

const ModalHotspotContent: React.FC<ModalHotspotContentProps> = ({
    contents = [],
    therapyAlphaNumericId,
    onClose
}: PropsWithChildren<ModalHotspotContentProps>) => {
    const { t } = useTranslation();

    const [activeContent, setActiveContent] = useState<Content>();

    useEffect(() => {
        if (contents?.length === 1) {
            setActiveContent(contents[0]);
        }
    }, [contents]);

    const showPieceOfContent = (content: Content) => {
        setActiveContent(content);
    };

    const onClickNext = () => {
        setActiveContent(undefined);
    };

    if (activeContent) {
        return (
            <>
                <HotspotSingleContentWrapper>
                    <Iframe
                        src={activeContent.contentUrl}
                        kind={activeContent.kind}
                        bgColor
                        thumbnailUrl={activeContent.thumbnailUrl}
                    ></Iframe>
                </HotspotSingleContentWrapper>
                <PanoramicNavigation
                    textLabel={therapyAlphaNumericId}
                    nextLabel={
                        contents?.length > 1
                            ? t('hotspots.navigation.contentOverview')
                            : t('hotspots.navigation.backToRoom')
                    }
                    onClickNext={contents?.length > 1 ? onClickNext : onClose}
                    isFontSizeLarge
                />
            </>
        );
    } else {
        return (
            <>
                <HotspotMultiContentWrapper>
                    {contents?.map((content, index) => {
                        return (
                            <HotspotContentPreview
                                key={index}
                                onClick={() => showPieceOfContent(content)}
                            >
                                <Iframe
                                    src={content.contentUrl}
                                    kind={content.kind}
                                    bgColor
                                    thumbnailUrl={content.thumbnailUrl}
                                ></Iframe>
                                <HotspotContentPreviewTitle>
                                    {content.title}
                                </HotspotContentPreviewTitle>
                            </HotspotContentPreview>
                        );
                    })}
                </HotspotMultiContentWrapper>
                <PanoramicNavigation
                    textLabel={therapyAlphaNumericId}
                    nextLabel={t('hotspots.navigation.backToRoom')}
                    onClickNext={onClose}
                    isFontSizeLarge
                />
            </>
        );
    }
};

export { ModalHotspotContent };
