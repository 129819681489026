import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { getRgbaStrFromHexColor } from 'styles/utils/color';

const Select = styled.select<{ hasError: boolean }>`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont },
                colors
            }
        },
        hasError
    }) => `
        background: transparent;
        border: ${rem(2)} solid;
        border-radius: 0;
        box-sizing: border-box;
        border-color: ${hasError ? colors.system.error : colors.primary.deep};
        color: ${hasError ? colors.system.error : colors.primary.deep};
        ${getTextsConfig('S')};
        font-family: ${lightFont};
        min-height: ${rem(40)};
        outline: none;
        padding: ${rem(7)} ${rem(20)};
        width: 100%;

        &::placeholder {
            color: ${getRgbaStrFromHexColor(
                hasError ? colors.system.error : colors.primary.deep,
                0.4
            )};
        }

        &:focus {
            border-color: ${
                hasError ? colors.system.error : colors.neutral.fullLight
            };
        }

        &:disabled {
            opacity: 0.7;
        }

        option {
            color: ${colors.primary.deep};
        }
    `}
`;

export { Select };
