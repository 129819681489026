import styled from '@emotion/styled';
import { Translate } from 'modules/app/shared/translate';
import { rem } from 'styles/utils/sizes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Feedback, feedbackFormSchema } from 'domain/feedback';
import { SatisfactionInputRadio } from './feedback-form/satisfaction-input-radio';
import { ButtonPrimary } from 'modules/app/shared/button/button';
import { useMutationVirtualClinic } from 'hooks/use-mutation-virtual-clinic';
import { useTranslation } from 'react-i18next';
import { GridTemplate } from 'modules/app/shared/grid-template/grid-template';
import { feedbackPost } from 'services/api/mutations/survey-mutations';
import { Input } from 'modules/app/shared/form/input/input';
import { SuccessMessage } from 'modules/app/shared/form/messages/success-message';
import { Spinner } from 'modules/app/shared/spinner/spinner';
import { useContext } from 'react';
import { AppContext } from 'modules/context/app';
import { useTracker } from 'hooks/use-tracker';
import {
    HotspotContentSurvey,
    HotspotContentSurveyOptions
} from 'domain/hotspot';

const FeedbackFormTitle = styled.h3`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('M')};
        margin-bottom: ${rem(20)};
        width: 75%;
    `}
`;

const FeedbackFormForm = styled.form`
    margin-bottom: ${rem(16)};
`;

const FeedbackFormDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        font-family: ${lightFont};
        margin-bottom: ${rem(30)};
    `}
`;

type FeedbackFormProps = {
    areaId: string;
    roomId: string;
    hotspotId: string;
    survey: HotspotContentSurvey;
};

const FeedbackForm = ({
    areaId,
    roomId,
    hotspotId,
    survey
}: FeedbackFormProps) => {
    const { t } = useTranslation();
    const { state } = useContext(AppContext);
    const { trackEvent } = useTracker();

    const {
        mutate: createFeedback,
        isSuccess,
        isLoading
    } = useMutationVirtualClinic(feedbackPost);

    const { register, handleSubmit, formState } = useForm<Feedback>({
        resolver: yupResolver(feedbackFormSchema)
    });

    const onSubmit = (data: Feedback) => {
        trackEvent({
            category: 'form',
            action: 'feedback-form submit'
        });
        createFeedback({
            ...data,
            areaId,
            roomId,
            hotspotId,
            userId: state.userId
        });
    };

    return (
        <>
            <FeedbackFormTitle>
                <Translate i18nKey='surveys.feedback.title' />
            </FeedbackFormTitle>
            <FeedbackFormDescription>
                {survey.description}
            </FeedbackFormDescription>

            {isLoading && <Spinner />}
            {isSuccess ? (
                <SuccessMessage>
                    <Translate i18nKey='surveys.feedback.successMessage' />
                </SuccessMessage>
            ) : (
                <FeedbackFormForm onSubmit={handleSubmit(onSubmit)}>
                    <GridTemplate>
                        <GridTemplate>
                            {Object.keys(survey.options)
                                .map(
                                    (option) =>
                                        option as keyof HotspotContentSurveyOptions
                                )
                                .map((option) => (
                                    <SatisfactionInputRadio
                                        key={option}
                                        {...register('satisfaction')}
                                        satisfactionType={option}
                                        description={survey.options[option]}
                                        hasError={Boolean(
                                            formState.errors.satisfaction
                                        )}
                                    />
                                ))}
                        </GridTemplate>

                        <Input
                            placeholder={t(
                                'surveys.feedback.input.placeholder'
                            )}
                            lightStyle
                            {...register('comments')}
                        />

                        <ButtonPrimary
                            type='submit'
                            btnSize='fullWidth'
                            btnAlign='left'
                        >
                            <Translate i18nKey='generics.submit' />
                        </ButtonPrimary>
                    </GridTemplate>
                </FeedbackFormForm>
            )}
        </>
    );
};

export { FeedbackForm };
