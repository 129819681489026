import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Icon } from '../icon/icon';
import { LegacyRef } from 'react';

export type ButtonAlign = 'left' | 'center' | 'right' | 'between';
export type IconPosition = 'left' | 'right';
export type ButtonSize = 'mini' | 'default' | 'fullWidth';
export type ButtonStyle = 'primary' | 'secondary' | 'tertiary';

export type ButtonBaseProps = {
    iconPosition?: IconPosition;
    btnAlign?: ButtonAlign;
    isFontSizeLarge?: boolean;
};

const btnAlignDictionary: { [key: string]: string } = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
    between: 'space-between'
};

const ButtonBase = styled.button<ButtonBaseProps>`
    ${({
        theme: {
            base: { colors }
        },
        iconPosition,
        btnAlign,
        isFontSizeLarge
    }) => `
        align-items: center;
        display: flex;
        height: ${rem(isFontSizeLarge ? 60 : 40)};
        justify-content: ${btnAlignDictionary[btnAlign ?? 'center']};
        position: relative;
        overflow: hidden;
        width: auto;

        &:focus {
            outline: 0;
        }

        &:disabled {
            opacity: 0.5;
        }

        &:hover:not(:disabled) {
            cursor: pointer;
        }

        &.primary {
            background-color: ${colors.primary.brand};

            &:hover:not(:disabled) {
                background-color: ${colors.primary.deep};
            }

            > * {
                color: ${colors.neutral.fullLight};
            }
        }

        &.secondary {
            background-color: ${colors.neutral.fullLight};

            &:hover:not(:disabled) {
                > * {
                    color: ${colors.primary.deep};
                }
            }

            > * {
                color: ${colors.primary.brand};
            }
        }

        &.tertiary {
            background-color: ${colors.primary.medical};

            &:hover:not(:disabled) {
                > * {
                    color: ${colors.primary.deep};
                }
            }

            > * {
                color: ${colors.neutral.fullLight};
            }
        }

        &.mini {
            padding: 0;
        }

        &.default {
            padding: 0 ${rem(30)};
        }

        &.fullWidth {
            padding: 0 ${rem(30)};
            width: 100%;
        }

        > div {
            display: inherit;
            ${
                iconPosition === 'left'
                    ? `margin-right: ${rem(20)};`
                    : `margin-left: ${rem(20)};`
            }
        }

        > * {
            pointer-events: none;
        }
    `}
`;

const ButtonContent = styled.span<{ isFontSizeLarge?: boolean }>`
    ${({
        theme: {
            base: {
                fonts: { getButtonsConfig, boldFont }
            }
        },
        isFontSizeLarge
    }) => `
        font-family: ${boldFont};
        ${getButtonsConfig(isFontSizeLarge ? 'L' : 'M')};
    `}
`;

export type ButtonProps = {
    iconName?: string;
    iconPosition?: IconPosition;
    btnAlign?: ButtonAlign;
    btnSize?: ButtonSize;
    btnStyle?: ButtonStyle;
    isFontSizeLarge?: boolean;
    className?: string;
    refBtn?: LegacyRef<HTMLButtonElement> | null;
} & React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

type ButtonTypeProps = Omit<ButtonProps, 'btnStyle'>;

const Button = ({
    iconName,
    iconPosition = 'left',
    btnAlign = 'center',
    btnSize = 'default',
    btnStyle = 'primary',
    isFontSizeLarge,
    children,
    color,
    refBtn,
    ...restProps
}: PropsWithChildren<ButtonProps>) => (
    <ButtonBase
        type='button'
        iconPosition={iconPosition}
        btnAlign={btnAlign}
        className={`${btnSize} ${btnStyle}`}
        {...restProps}
        isFontSizeLarge={isFontSizeLarge}
        disabled={restProps.disabled}
        ref={refBtn}
    >
        {iconName && iconPosition === 'left' ? (
            <div data-testid='icon'>
                <Icon iconName={iconName} iconSize={20} />
            </div>
        ) : null}
        <ButtonContent isFontSizeLarge={isFontSizeLarge}>
            {children}
        </ButtonContent>
        {iconName && iconPosition === 'right' ? (
            <div data-testid='icon'>
                <Icon iconName={iconName} iconSize={20} />
            </div>
        ) : null}
    </ButtonBase>
);

const ButtonPrimary = ({
    ...restProps
}: PropsWithChildren<ButtonTypeProps>) => (
    <Button btnStyle='primary' {...restProps} />
);

const ButtonSecondary = ({
    ...restProps
}: PropsWithChildren<ButtonTypeProps>) => (
    <Button btnStyle='secondary' {...restProps} />
);

const ButtonTertiary = ({
    ...restProps
}: PropsWithChildren<ButtonTypeProps>) => (
    <Button btnStyle='tertiary' {...restProps} />
);

export { Button, ButtonBase, ButtonPrimary, ButtonSecondary, ButtonTertiary };
