import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const ModalTutorialWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: ${rem(450)};
    justify-content: center;
    margin: 0 auto;
    position: relative;

    .tablet {
        width: ${rem(250)};
    }

    .tap {
        animation-delay: 2s;
        animation: moveTap 6s infinite;
        bottom: 0;
        left: 0;
        position: absolute;
        transform: translate(${rem(340)}, ${rem(30)});
        width: ${rem(50)};

        @keyframes moveTap {
            0%,
            20% {
                transform: translate(${rem(340)}, ${rem(30)});
            }
            30%,
            40% {
                transform: translate(${rem(190)}, ${rem(-70)});
            }
            50%,
            60% {
                transform: translate(${rem(80)}, ${rem(-70)});
            }
            70%,
            80% {
                transform: translate(${rem(190)}, ${rem(-70)});
            }
            100% {
                transform: translate(${rem(340)}, ${rem(30)});
            }
        }
    }
`;
export const ModalTutorialDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont }
            }
        }
    }) => `
        ${getTextsConfig('L')};
        font-family: ${lightFont};
        margin-bottom: ${rem(30)};
        text-align: center;
        width: ${rem(400)};
    `}
`;
export const BoxOne = styled.div`
    position: relative;
`;
export const BoxTwo = styled.div`
    align-items: center;
    display: flex;
    height: ${rem(165)};
    justify-content: center;
    left: ${rem(2)};
    overflow: hidden;
    position: absolute;
    top: ${rem(21)};
    width: ${rem(246)};

    > img {
        animation-delay: 2s;
        animation: moveLandscape 6s infinite;
        left: 50%;
        opacity: 0.3;
        position: absolute;
        top: 50%;
        transform: translate(-35%, -50%);
        width: ${rem(350)};

        @keyframes moveLandscape {
            0%,
            40% {
                transform: translate(-35%, -50%);
            }
            50%,
            60% {
                transform: translate(-65%, -50%);
            }
            70%,
            100% {
                transform: translate(-35%, -50%);
            }
        }
    }
`;
