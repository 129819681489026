import { configuration } from 'configuration';
import { FC, useEffect, useState } from 'react';

const playerConfig = {
    key: configuration.streamsPlayer.key,
    playback: {
        muted: false,
        autoplay: false
    },
    skin: {
        screenLogoImage: ''
    }
};

type PlayerProps = {
    id: string;
    sourceConfig: {
        hls: string;
        poster?: string;
    };
};

type Player = {
    load: (...params: unknown[]) => Promise<unknown>;
};

const Player: FC<PlayerProps> = ({ id, sourceConfig }) => {
    const [player, setPlayer] = useState<Player | undefined>();
    useEffect(() => {
        if (!player) {
            const container = document.getElementById(id);
            console.log('container', container);
            setPlayer(
                // @ts-expect-error It's in the global context
                new window.bitmovin.player.Player(
                    container,
                    playerConfig
                ) as Player
            );
        } else
            player.load(sourceConfig).catch(function (reason: string) {
                console.log('Error while loading source:', reason);
            });
    }, [id, player, sourceConfig]);

    return <div id={id} />;
};

export default Player;
