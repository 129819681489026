import React, { useCallback } from 'react';

const useStopPropagationEvent = () => {
    const stopPropagation = useCallback((fn: () => void) => {
        return function (event: React.MouseEvent) {
            event.stopPropagation();
            fn();
        };
    }, []);

    return { stopPropagation };
};

export { useStopPropagationEvent };
