import { useEffect, useState } from 'react';

type InnerWindowSize = {
    width: number;
    height: number;
};

const useInnerWindowSize = (): InnerWindowSize => {
    const [canvasSize, setCanvasSize] = useState<{
        width: number;
        height: number;
    }>({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        const action = () => {
            setCanvasSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', action);
        return () => {
            window.removeEventListener('resize', action);
        };
    }, []);

    return canvasSize;
};

export { useInnerWindowSize };
