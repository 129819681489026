import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { rem } from 'styles/utils/sizes';
import { useTracker } from 'hooks/use-tracker';
import { ClinicArea } from 'domain/clinic-overview';
import { useClinicOverviewArea } from 'modules/context/clinic-overview';
import { useStopPropagationEvent } from 'hooks/use-stop-propagation-event';

import ClinicImg from 'assets/images/clinic/floors/clinic-build.png';
import ClinicBuildImg from 'assets/images/clinic/floors/homedialysis02.png';

const ClinicMapWrapper = styled.div`
    ${({
        theme: {
            base: { zindex }
        }
    }) => `
        line-height: 0;
        z-index: ${zindex.S};

        area {
            cursor: pointer;
        }
    `}
`;

const ClinicImage = styled.img`
    ${({
        theme: {
            base: { breakpoints, zindex }
        }
    }) => `
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        width: ${rem(breakpoints.default)};
        z-index: ${zindex.XS};
    `}
`;

const ClinicRoofImage = styled.img`
    animation: hideRoof 3s;
    left: ${rem(1266)};
    opacity: 0;
    position: absolute;
    top: ${rem(636)};
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: -1;

    @keyframes hideRoof {
        0%,
        70% {
            opacity: 1;
            z-index: 0;
        }
        99% {
            z-index: 0;
        }
        100% {
            opacity: 0;
            z-index: -1;
        }
    }
`;

export type ClinicMapAreasLayerProps = {
    areas: ClinicArea[];
    activeAreaIndex: number;
    onClickArea: (area: ClinicArea) => void;
    onFocusArea: (area: ClinicArea) => void;
    onUnfocusArea: (area: ClinicArea) => void;
};

const ClinicMapAreasLayer = ({
    areas,
    activeAreaIndex,
    onClickArea,
    onFocusArea,
    onUnfocusArea
}: ClinicMapAreasLayerProps) => {
    const { t } = useTranslation();
    const mapAreaId = 'clinic-map-areas';

    const { setDataForTracker } = useTracker();
    const { stopPropagation } = useStopPropagationEvent();
    const { recalculateCoords } = useClinicOverviewArea({
        areas,
        activeAreaIndex
    });

    return (
        <ClinicMapWrapper>
            <ClinicImage
                draggable='false'
                id='clinic'
                src={ClinicImg}
                alt={t('areas.doctorOffice.title')}
                useMap={`#${mapAreaId}`}
            />
            <ClinicRoofImage src={ClinicBuildImg} draggable='false' />

            <map name={mapAreaId}>
                {areas.map((area, areaIndex) =>
                    area.paintingInfo.map(
                        (info, index) =>
                            info.base.coords && (
                                <area
                                    {...setDataForTracker({
                                        category: 'area',
                                        action: area.id
                                    })}
                                    key={`${area.id}-${index}`}
                                    shape='poly'
                                    coords={recalculateCoords(areaIndex, info)}
                                    alt={t(`areas.${area.id}.title`)}
                                    onClick={stopPropagation(() =>
                                        onClickArea(area)
                                    )}
                                    onMouseEnter={() => onFocusArea(area)}
                                    onMouseLeave={() => onUnfocusArea(area)}
                                />
                            )
                    )
                )}
            </map>
        </ClinicMapWrapper>
    );
};

export { ClinicMapAreasLayer };
