import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const MenuWrapper = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        align-items: flex-start;
        background-color: ${colors.neutral.fullLight};
        box-sizing: border-box;
        color: ${colors.primary.deep};
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, 100%);
        overflow: hidden;
        padding-bottom: ${rem(40)};
        padding-top: ${rem(30)};
        width: ${rem(312)};
    `}
`;
export const MenuHeader = styled.header`
    padding: 0 ${rem(40)} ${rem(20)} ${rem(40)};
`;
