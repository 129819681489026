import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const NavListItem = styled.li<{
    color: string;
    highlighted: boolean;
    withBorder?: boolean;
}>`
    ${({
        theme: {
            base: { colors }
        },
        color,
        highlighted,
        withBorder = true
    }) => `
        align-items: center;
        border-right: ${withBorder ? `${rem(9)} solid ${color}` : 'auto'};
        box-sizing: border-box;
        display: flex;
        min-height: ${rem(40)};
        padding: ${rem(8)} ${rem(20)} ${rem(8)} ${rem(40)};
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: ${rem(10)};
        }

        &:hover {
            background-color: ${color};
            color: ${colors.neutral.fullLight};
            cursor:pointer;
        }

        ${
            highlighted &&
            `
                background-color: ${color};
                color: ${colors.neutral.fullLight};
                cursor:pointer;
            `
        }
    `}
`;
