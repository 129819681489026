import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/use-modal';
import { ClinicQuickTourValue } from 'domain/clinic-overview';
import { ModalVideo } from 'modules/app/shared/modal/templates/modal-video';
import { DefaultModal } from 'modules/app/shared/modal/default/default-modal';
import { ClinicQuickTourPreview } from './clinic-quick-tour/clinic-quick-tour-preview';
import { useCallback } from 'react';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';

type ClinicQuickTourModal = { type: 'quickTour' };

export type ClinicQuickTourProps = {
    areaid?: string;
    quickTour: ClinicQuickTourValue;
    onOpenMenu: (open: boolean) => void;
};

const ClinicQuickTour = ({
    areaid,
    quickTour,
    onOpenMenu
}: ClinicQuickTourProps) => {
    const { i18n, t } = useTranslation();
    const { modal, openModal, closeModal } = useModal<ClinicQuickTourModal>();
    const {
        state: { therapies }
    } = useClinicOverviewContext();

    const title = useCallback(
        () =>
            areaid
                ? `${
                      therapies.find((th) => th.name === areaid)?.nameLanguage
                  } ${t('generics.quick-tour')}`
                : t('generics.quick-tour'),
        [areaid, i18n.language, therapies]
    );

    const onOpen = useCallback(() => {
        openModal({ type: 'quickTour' });
        onOpenMenu(false);
    }, []);

    const onClose = useCallback(() => {
        closeModal();
        onOpenMenu(true);
    }, []);

    return quickTour ? (
        <>
            <ClinicQuickTourPreview
                title={title()}
                videoPoster={quickTour.video.poster}
                onClick={onOpen}
            />

            {modal.type === 'quickTour' && (
                <DefaultModal showCloseBtn bgStyle='dark' onClose={onClose}>
                    <ModalVideo
                        title={title()}
                        videoSrc={quickTour.video.src}
                        btnLabel={t('areas.home.modal.btnLabel')}
                        onClick={onClose}
                    />
                </DefaultModal>
            )}
        </>
    ) : null;
};

export { ClinicQuickTour };
