import { Theme } from 'styles/theme';

export type Room = {
    id: string;
};

export type RoomsConfiguration = {
    ckd: {
        patientEngagement: Room;
        ckdManagement: Room;
    };
    homeDialysis: {
        vrTraining: Room;
        treatmentIndividualization: Room;
        supportingPatientsAtHome: Room;
    };
    hemodialysis: {
        patientEmpowerment: Room;
        dataDrivenCare: Room;
        individualizePatientCare: Room;
        clinicalOperationalPerformance: Room;
        waterTreatment: Room;
    };
    criticalCare: {
        heartLung: Room;
        kidneyReplacement: Room;
        ckrt: Room;
        bloodPurification: Room;
        immunoadsorption: Room;
        lipoprotein: Room;
    };
};

const roomsConfiguration: RoomsConfiguration = {
    ckd: {
        ckdManagement: {
            id: 'ckdManagement'
        },
        patientEngagement: {
            id: 'patientEngagement'
        }
    },
    homeDialysis: {
        vrTraining: {
            id: 'vrTraining'
        },
        treatmentIndividualization: {
            id: 'treatmentIndividualization'
        },
        supportingPatientsAtHome: {
            id: 'supportingPatientsAtHome'
        }
    },
    hemodialysis: {
        patientEmpowerment: {
            id: 'patientEmpowerment'
        },
        clinicalOperationalPerformance: {
            id: 'clinicalOperationalPerformance'
        },
        individualizePatientCare: {
            id: 'individualizePatientCare'
        },
        dataDrivenCare: {
            id: 'dataDrivenCare'
        },
        waterTreatment: {
            id: 'waterTreatment'
        }
    },
    criticalCare: {
        heartLung: {
            id: 'heartLung'
        },
        kidneyReplacement: {
            id: 'kidneyReplacement'
        },
        ckrt: {
            id: 'ckrt'
        },
        bloodPurification: {
            id: 'bloodPurification'
        },
        immunoadsorption: {
            id: 'immunoadsorption'
        },
        lipoprotein: {
            id: 'lipoprotein'
        }
    }
};

const getAreaColorFromTheme = (theme: Theme, areaId: string) => {
    return theme.base.colors.navigation[areaId as keyof RoomsConfiguration];
};

const getAreasIdFromConfig = (): string[] => {
    return Object.keys(roomsConfiguration).map((key) => key);
};

export { getAreasIdFromConfig, getAreaColorFromTheme };
