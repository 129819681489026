import React from 'react';

type Func = (...arg: any) => any;

type Point = {
    x: number;
    y: number;
};

type TransformCSSParams = {
    scale?: number;
    translateX?: number;
    translateY?: number;
};

const transformCSS = ({
    scale = 1,
    translateX = 0,
    translateY = 0
}: TransformCSSParams): React.CSSProperties => {
    return {
        transition: 'transform 0.5s ease-out',
        transform: `matrix(${scale},0,0,${scale},${translateX},${translateY})`
    };
};

const compose =
    <TData>(...fns: Func[]): any =>
    (data: TData) => {
        return fns.reduceRight((result, fn) => fn(result), data);
    };

const translate =
    (translateX: number = 0, translateY: number = 0) =>
    (points: Point[]): Point[] => {
        return points.map(({ x, y }) => ({
            x: Math.round(x + translateX),
            y: Math.round(y + translateY)
        }));
    };

const toPoints = (coords: string): Point[] => {
    const parsedCoords = coords.split(',').map((x) => Number(x.trim()));

    const points: Point[] = [];
    for (let i = 0; i < parsedCoords.length / 2; i++) {
        const position = i * 2;
        points.push({
            x: parsedCoords[position],
            y: parsedCoords[position + 1]
        });
    }

    return points;
};

const toCoords = (points: Point[]): string => {
    return points.map(({ x, y }) => [x, y].join(',')).join(',');
};

const translateCoords = (translateX: number = 0, translateY: number = 0) =>
    compose(toCoords, translate(translateX, translateY), toPoints);

export { transformCSS, translateCoords };
