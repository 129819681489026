import { QueryClient } from 'react-query';

const createQueryClient = (): QueryClient =>
    new QueryClient({
        defaultOptions: {
            queries: {
                suspense: true,
                refetchOnWindowFocus: false,
                useErrorBoundary: true
            },
            mutations: {
                useErrorBoundary: true
            }
        }
    });

export { createQueryClient };
