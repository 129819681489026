import { Ref } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { forwardRef } from 'react';

type TextAreaProps = {
    rows?: number;
    placeholder?: string;
    lightStyle?: boolean;
    hasError: boolean;
} & JSX.IntrinsicElements['textarea'];

const TextAreaWrapper = styled.textarea<TextAreaProps>`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont },
                colors
            }
        },
        lightStyle,
        hasError
    }) => `
        background: transparent;
        border: 2px solid ${colors.neutral.fullLight};
        border-color: ${
            hasError
                ? colors.system.error
                : lightStyle
                ? colors.neutral.fullLight
                : colors.primary.deep
        };
        border-radius: 0;
        box-sizing: border-box;
        color: ${hasError ? colors.system.error : colors.primary.deep};
        ${getTextsConfig('S')};
        font-family: ${lightFont};
        min-height: ${rem(40)};
        outline: none;
        padding: ${rem(7)} ${rem(20)};
        resize: none;

        &::placeholder {
            color: ${getRgbaStrFromHexColor(
                hasError ? colors.system.error : colors.primary.deep,
                0.4
            )};
        }

        &:focus {
            border-color: ${
                lightStyle
                    ? colors.primary.deep
                    : hasError
                    ? colors.system.error
                    : colors.neutral.fullLight
            };
        }

        &:disabled {
            opacity: 0.7;
        }
    `}
`;

type InputFormGroupProps = {
    hasError: boolean;
} & JSX.IntrinsicElements['textarea'];

const TextArea = forwardRef(
    (
        {
            name,
            rows = 1,
            placeholder,
            hasError,
            ...props
        }: InputFormGroupProps,
        ref: Ref<HTMLTextAreaElement>
    ) => (
        <TextAreaWrapper
            name={name}
            rows={rows}
            placeholder={placeholder}
            maxLength={1000}
            ref={ref}
            hasError={hasError}
            {...props}
        />
    )
);

export { TextArea };
