import { Ref } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import React from 'react';

type InputProps = {
    hasError?: boolean;
    lightStyle?: boolean;
} & JSX.IntrinsicElements['input'];

const InputBase = styled.input<InputProps>`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont },
                colors
            }
        },
        hasError,
        lightStyle
    }) => `
        background: transparent;
        border: 2px solid;
        border-radius: 0;
        box-sizing: border-box;
        border-color: ${
            hasError
                ? colors.system.error
                : lightStyle
                ? colors.neutral.fullLight
                : colors.primary.deep
        };
        color: ${hasError ? colors.system.error : colors.primary.deep};
        ${getTextsConfig('S')};
        font-family: ${lightFont};
        min-height: ${rem(40)};
        outline: none;
        padding: ${rem(7)} ${rem(20)};
        width: 100%;

        &::placeholder {
            color: ${getRgbaStrFromHexColor(
                hasError ? colors.system.error : colors.primary.deep,
                0.4
            )};
        }

        &:focus {
            border-color: ${
                lightStyle
                    ? colors.primary.deep
                    : hasError
                    ? colors.system.error
                    : colors.neutral.fullLight
            };
        }

        &:disabled {
            opacity: 0.7;
        }
    `}
`;

export type InputFormGroupProps = {
    errorType?: 'message' | 'tooltip' | 'none';
    innerRef?: Ref<HTMLInputElement>;
    isInline?: boolean;
} & JSX.IntrinsicElements['input'];

const Input = React.forwardRef(
    (
        {
            placeholder,
            hasError,
            lightStyle,
            ...props
        }: InputProps & InputFormGroupProps,
        ref: Ref<HTMLInputElement>
    ) => {
        return (
            <InputBase
                placeholder={!props.disabled ? placeholder : undefined}
                hasError={hasError}
                lightStyle={lightStyle}
                ref={ref}
                maxLength={150}
                {...props}
            />
        );
    }
);

export { Input };
