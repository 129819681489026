import styled from '@emotion/styled';
import { PatientsMenuItem } from './patients-menu-item';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { routes } from 'modules/routes/routes-config';
import { Therapy } from 'domain/therapy';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';

const PatientsMenuWrapper = styled.section<{
    withColumn: number;
}>`
    ${({ withColumn }) => `
    display: grid;
    grid-template-columns: repeat(4, ${withColumn}%);
    `}
`;

const PatientsMenu = () => {
    const { state } = useClinicOverviewContext();
    const { t } = useTranslation();
    const history = useHistory();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });

    const withColumn = 100 / state.therapies.length;

    const country = getCountryFromUrl();

    return (
        <PatientsMenuWrapper withColumn={withColumn}>
            {state.therapies.map((therapy: Therapy) => (
                <PatientsMenuItem
                    key={therapy.id}
                    title={therapy.nameLanguage}
                    description={therapy.descriptionLanguage}
                    sector={therapy.sectorLanguage}
                    image={`/static/images/menu/${therapy.name}.png`}
                    color={therapy.name}
                    onClick={() =>
                        history.push(
                            routes.clinicOverview.getRoute(
                                country,
                                supportedCountries,
                                therapy.name
                            )
                        )
                    }
                />
            ))}
        </PatientsMenuWrapper>
    );
};

export { PatientsMenu };
