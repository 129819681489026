import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { countryRepositoryImplementation } from 'services/api/http/repositores/countries/countries';
import { actions } from 'store';
import { withDisclaimerModal } from '../../hoc/withDisclaimerModal';
import { routes } from '../routes/routes-config';
import { ClinicOverview } from './clinic-overview';
import { ErrorPage } from './error/error';
import { Home } from './home';
import { Room2d } from './room2d';
import { Room3d } from './room3d';
import { getCountryFromUrl } from 'utils/url-utils';

const Routing: React.FC = () => {
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });

    useEffect(() => {
        countryRepositoryImplementation
            .getCountries()
            .then((countries) => actions.setSupportedCountries(countries));
    }, []);

    const country = getCountryFromUrl();
    return (
        <>
            {supportedCountries.length > 0 && (
                <Switch>
                    <Route
                        exact
                        path={routes.home.getStaticRoute(supportedCountries)}
                        component={withDisclaimerModal(Home)({})}
                    />
                    <Route
                        exact
                        path={routes.clinicOverview.getStaticRoute(
                            supportedCountries
                        )}
                        component={withDisclaimerModal(ClinicOverview)({})}
                    />
                    {country !== 'us' && (
                        <Route
                            path={routes.doctorOffice.getStaticRoute(
                                supportedCountries
                            )}
                            component={withDisclaimerModal(Room2d)({
                                executeOnLoad: true
                            })}
                        />
                    )}

                    <Route
                        path={routes.room.getStaticRoute(supportedCountries)}
                        component={withDisclaimerModal(Room3d)({
                            executeOnLoad: true
                        })}
                    />

                    <Route path='*' component={ErrorPage} />
                </Switch>
            )}
        </>
    );
};

export { Routing };
