import { useCallback, useState } from 'react';

type WithNone<T> = T & { type: 'none' };

function useModal<TPayload>() {
    const [modal, openModal] = useState<TPayload>({
        type: 'none'
    } as WithNone<TPayload>);

    const closeModal = useCallback(() => {
        openModal({ type: 'none' } as WithNone<TPayload>);
    }, []);

    return { modal, closeModal, openModal };
}

export { useModal };
