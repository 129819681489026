import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { PropsWithChildren } from 'react';
import { ErrorPage } from './error';

const QueryErrorResetBoundaryWrapper: React.FC = ({
    children
}: PropsWithChildren<unknown>) => (
    <QueryErrorResetBoundary>
        {({ reset }) => (
            <ErrorBoundary
                onReset={reset}
                fallbackRender={({ resetErrorBoundary }) => (
                    <ErrorPage resetError={() => resetErrorBoundary()} />
                )}
            >
                {children}
            </ErrorBoundary>
        )}
    </QueryErrorResetBoundary>
);

export { QueryErrorResetBoundaryWrapper };
