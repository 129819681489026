import {
    ClinicAreaName,
    ClinicArea,
    clinicAreaIds,
    ClinicAreaColor,
    clinicQuickTours,
    clinicAreaPaintingInfo
} from 'domain/clinic-overview';
import { Actions, State } from './clinic-overview.model';
import { Hotspot } from 'modules/app/shared/hotspot/hotspot';
import { HotspotModel, HotspotPositionCSS } from 'domain/hotspot';
import { Topic } from 'domain/topic';

export const initialValues: State = {
    openMenu: false,
    therapies: [],
    topics: [],
    isDisclaimerModalOpen: false,
    activeAreaIndex: -1,
    areas: clinicAreaIds.map((id) => ({
        id,
        color: id as ClinicAreaColor,
        quickTour: clinicQuickTours[id],
        paintingInfo: clinicAreaPaintingInfo[id],
        state: { active: false, highlight: false }
    }))
};

export const reducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case 'INITIALIZE': {
            const hotspots = state.areas?.map((x) => x.paintingInfo);

            for (let i = 0; i < hotspots.length; i++) {
                initialValues.areas[i].paintingInfo = hotspots[i];
            }

            return {
                ...state,
                areas: initialValues.areas,
                openMenu: true
            };
        }

        case 'FOCUS_AREA': {
            if (!allowExecuteActionGuard(state)) return state;

            const { areas } = state;
            const { id } = action.payload;

            const therapiesMap:
                | { key: ClinicAreaName; value: boolean }
                | Record<string, boolean> = {};
            state.therapies.forEach((therapy) => {
                therapiesMap[therapy.name as keyof typeof therapiesMap] = true;
            });

            const updatedAreas = areas.map((area) => ({
                ...area,
                state: calculateFocusState(id, area, therapiesMap)
            }));

            return { ...state, areas: updatedAreas };
        }

        case 'UNFOCUS_AREA': {
            if (!allowExecuteActionGuard(state)) return state;

            const { areas } = state;
            const updatedAreas = areas.map((area) => ({
                ...area,
                state: calculateUnfocusState(area)
            }));

            return { ...state, areas: updatedAreas };
        }

        case 'ACTIVE_AREA': {
            if (!allowExecuteActionGuard(state)) return state;

            const { areas } = state;
            const { id } = action.payload;

            const therapiesMap:
                | { key: ClinicAreaName; value: boolean }
                | Record<string, boolean> = {};
            state.therapies.forEach((therapy) => {
                therapiesMap[therapy.name as keyof typeof therapiesMap] = true;
            });

            const updatedAreas = areas.map((area) => ({
                ...area,
                state: calculateActiveState(id, area, therapiesMap)
            }));

            const activeAreaIndex = updatedAreas.findIndex(
                (area) => area.state.active
            );

            return { ...state, activeAreaIndex, areas: updatedAreas };
        }

        case 'UNACTIVE_AREA': {
            if (!allowExecuteActionGuard(state)) return state;

            const { areas } = state;

            const updatedAreas = areas.map((area) => ({
                ...area,
                state: calculateUactiveState()
            }));

            const activeAreaIndex = updatedAreas.findIndex(
                (area) => area.state.active
            );

            return { ...state, activeAreaIndex, areas: updatedAreas };
        }

        case 'OPEN_MENU': {
            const { open } = action.payload;

            return { ...state, openMenu: open };
        }

        case 'SET_THERAPIES': {
            return { ...state, therapies: action.payload.therapies };
        }

        case 'SET_TOPICS': {
            const updatedAreas: ClinicArea[] = state.areas.map((area) => ({
                ...area,
                paintingInfo: updatePaintingInfo(area, action.payload.topics)
            }));

            return {
                ...state,
                topics: action.payload.topics,
                areas: updatedAreas
            };
        }

        case 'FINALIZE': {
            return {
                ...state,
                areas: [],
                openMenu: false,
                activeAreaIndex: -1
            };
        }

        case 'OPEN_DISCLAIMER_MODAL': {
            return { ...state, isDisclaimerModalOpen: true };
        }

        case 'CLOSE_DISCLAIMER_MODAL': {
            return { ...state, isDisclaimerModalOpen: false };
        }

        default:
            return state;
    }
};

const allowExecuteActionGuard = (state: State) => {
    return state.openMenu;
};

const updatePaintingInfo = (area: ClinicArea, topics: Topic[]) => {
    area.paintingInfo[0].hotspots = topics
        .filter((topic) => topic.therapyName == area.id)
        .map(
            (topic): HotspotModel<HotspotPositionCSS> => ({
                areaId: topic.therapyName,
                roomId: topic.name,
                position: {
                    left: topic.positionX,
                    top: topic.positionY
                }
            })
        );

    return area.paintingInfo;
};

const calculateFocusState = (
    areaId: string,
    area: ClinicArea,
    therapiesMap: Record<string, unknown>
) => {
    return area.id === areaId && therapiesMap[area.id]
        ? { ...area.state, highlight: true }
        : area.state;
};

const calculateUnfocusState = (area: ClinicArea) => {
    return !area.state.active
        ? { ...area.state, highlight: false }
        : area.state;
};

const calculateActiveState = (
    areaId: string | null,
    area: ClinicArea,
    therapiesMap: Record<string, unknown>
) => {
    return area.id === areaId && therapiesMap[area.id]
        ? area.state.active
            ? { active: false, highlight: false }
            : { active: true, highlight: true }
        : { active: false, highlight: false };
};

const calculateUactiveState = () => {
    return { active: false, highlight: false };
};
