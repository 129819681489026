import { ActionTypes } from '../action-types';
import {
    SetCurrentCountryAction,
    SetSupportedCountriesAction
} from '../actions';

export interface AppState {
    defaultCountry: string;
    currentCountry: string;
    supportedCountries: string[];
}

const initialState = {
    defaultCountry: 'de',
    currentCountry: '',
    supportedCountries: []
};

const app = (
    state: AppState = initialState,
    action: SetCurrentCountryAction | SetSupportedCountriesAction
): AppState => {
    switch (action.type) {
        case ActionTypes.SET_COUNTRY:
            return { ...state, currentCountry: action.payload };
        case ActionTypes.SET_SUPPORTED_COUNTRIES:
            return {
                ...state,
                supportedCountries: action.payload
            };
        default:
            return state;
    }
};

export { app };
