import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const MenuHeaderWrapper = styled.div`
    ${({
        theme: {
            base: { layout }
        }
    }) => `
        animation: translateX 0.3s;
        left: 0;
        position: absolute;
        top: ${rem(layout.header)};

        @keyframes translateX {
            0% {
                transform: translateX(-100%);
            }
            100% {
                transform: translateX(0);
            }
        }
    `}
`;
