import {
    ApplicationInsights,
    IExceptionTelemetry,
    ITelemetryItem
} from '@microsoft/applicationinsights-web';
import { configuration } from 'configuration';

const appInsightsInstance = configuration.appInsights.connectionString
    ? new ApplicationInsights({
          config: {
              connectionString: configuration.appInsights.connectionString,
              enableDebug: configuration.app.environment !== 'production',
              loggingLevelConsole: 2,
              loggingLevelTelemetry: 2,
              enableAutoRouteTracking: true
          }
      })
    : null;

const telemetryInitializer = (envelope: ITelemetryItem): void => {
    if (envelope.tags) {
        envelope.tags['ai.application.ver'] = configuration.app.version;
        envelope.tags['ai.application.build'] = configuration.app.buildId;
        envelope.tags['ai.cloud.role'] = 'VC-SPA';
    }
};

const initializeAppInsights = (): void => {
    appInsightsInstance?.loadAppInsights();
    appInsightsInstance?.addTelemetryInitializer(telemetryInitializer);
};

const trackException = (error: IExceptionTelemetry): void => {
    appInsightsInstance?.trackException(error);
};

export { initializeAppInsights, trackException };
