import { Feedback } from 'domain/feedback';
import { Survey } from 'domain/survey';
import { getCountryFromUrl } from 'utils/url-utils';
import { ApiRequest } from '../http/axios-client';

type ApplicationInfo = {
    areaId: string;
    roomId: string;
    hotspotId: string;
    userId: string;
};

const feedbackPost = (
    data: Feedback & ApplicationInfo
): ApiRequest<Feedback & ApplicationInfo, void> => {
    const country = getCountryFromUrl();
    return {
        httpMethod: 'POST',
        path: `${country}/surveys/feedback`,
        data: data
    };
};

const surveyPost = (
    data: Survey & ApplicationInfo
): ApiRequest<Survey & ApplicationInfo, void> => {
    const country = getCountryFromUrl();
    return {
        httpMethod: 'POST',
        path: `${country}/surveys/information-request`,
        data: data
    };
};

export { feedbackPost, surveyPost };
