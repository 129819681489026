import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { DefaultModal } from '../default/default-modal';

const ModalIframeWrapper = styled.div`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        align-items: center;
        display: flex;
        flex-direction: column;
        font-family: ${lightFont};
        justify-content: flex-start;
        padding: ${rem(56)} ${rem(48)} ${rem(48)};
    `}
`;

const ModalIframeContent = styled.div`
    height: ${rem(500)};
    overflow: hidden;
    width: 100%;

    > iframe {
        height: 100%;
        width: 100%;
    }
`;

export type ModalIframeProps = {
    content: 'cookieList' | 'privacyPolicy';
    onClose: (confirm: boolean) => void;
};

const ModalIframe: React.FC<ModalIframeProps> = ({
    content,
    onClose
}: PropsWithChildren<ModalIframeProps>) => {
    return (
        <DefaultModal
            onClose={() => onClose(false)}
            bgStyle='dark'
            modalSize='medium'
            originModal='bottom'
            isBgBlur={false}
            showCloseBtn={true}
        >
            <ModalIframeWrapper>
                <ModalIframeContent>
                    <iframe src={`static/pdf/${content}.pdf`} allowFullScreen />
                </ModalIframeContent>
            </ModalIframeWrapper>
        </DefaultModal>
    );
};

export { ModalIframe };
