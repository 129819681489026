import { getHotspotsFromArea, Rooms2DConfiguration } from 'domain/room2d';
import { useInnerWindowSize } from 'hooks/use-inner-window-size';
import { StaticViewer } from './shared/viewer/static-viewer';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams
} from 'react-router-dom';
import { routes } from 'modules/routes/routes-config';
import { Room2dModal } from './room2d/room2d-modal';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';

const Room2d = () => {
    const area = useParams<{
        areaId: keyof Rooms2DConfiguration;
        hospotId: string;
    }>();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const canvasSize = useInnerWindowSize();
    const history = useHistory();
    const currentLocation = history.location.pathname;

    const country = getCountryFromUrl();

    return (
        <>
            <StaticViewer
                rendererWidth={canvasSize.width}
                rendererHeight={canvasSize.height}
                hotspots={getHotspotsFromArea(area.areaId)}
                staticImage={`/static/images/${area.areaId}/${
                    currentLocation.split('/')[2]
                }.jpg`}
                onHotspotClick={({ areaId, contentId }) =>
                    history.push(
                        routes.doctorOfficeModal.getRoute(
                            areaId,
                            contentId,
                            country,
                            supportedCountries
                        )
                    )
                }
            />

            <Switch>
                <Route
                    exact
                    path={routes.doctorOfficeModal.getStaticRoute(
                        supportedCountries
                    )}
                    render={() => (
                        <Room2dModal
                            onClose={() =>
                                history.replace(
                                    routes.doctorOffice.getRoute(
                                        area.areaId,
                                        country,
                                        supportedCountries
                                    )
                                )
                            }
                        />
                    )}
                />
                <Route
                    render={() => (
                        <Redirect
                            to={routes.doctorOffice.getRoute(
                                area.areaId,
                                country,
                                supportedCountries
                            )}
                        />
                    )}
                />
            </Switch>
        </>
    );
};

export { Room2d };
