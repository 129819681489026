import { MouseEvent } from 'react';
import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { rem } from 'styles/utils/sizes';
import { Icon, IconWrapper } from '../icon/icon';

const HotspotButton = styled.button<{ isAnimated?: boolean }>`
    ${({
        theme: {
            base: { colors, zindex }
        },
        isAnimated
    }) => `
        align-items: center;
        border-radius: 50%;
        box-sizing: content-box;
        display: flex;
        height: ${rem(48)};
        justify-content: center;
        line-height: 0;
        position: relative;
        transition: box-shadow 0.3s ease;
        width: ${rem(48)};
        z-index: ${zindex.M};
        opacity: 1;

        ${
            isAnimated &&
            `
            animation: showPanoramicHotspot 0.8s;
        `
        };

        @keyframes showPanoramicHotspot {
            0%,
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        > div {
          display: inherit;
          line-height: 0;
        }

        &.primary {
          &,
          &:active {
            background-color: ${colors.primary.brand};
            box-shadow: 0 0 0 ${rem(6)} ${getRgbaStrFromHexColor(
        colors.primary.brand,
        0.3
    )};
            color: ${colors.neutral.fullLight};
          }

          &:hover {
            box-shadow: 0 0 0 ${rem(6)} ${getRgbaStrFromHexColor(
        colors.primary.brand,
        0.4
    )};
          }
        }

        &:hover {
            ~ ${HotspotBubble} {
                display: flex;
            }
        }

        @media (-webkit-min-device-pixel-ratio: 2) {
            ~ ${HotspotBubble} {
                display: flex;
            }
        }

        ${IconWrapper} {
            pointer-events: none;
        }
    `}
`;

const HotspotBubble = styled.div`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getTextsConfig },
                zindex
            }
        }
    }) => `
        animation: showBubble 0.3s;
        align-items: flex-start;
        background-color: ${colors.neutral.fullLight};
        border-radius: 0 ${rem(50)} ${rem(50)} ${rem(50)};
        box-sizing: border-box;
        color: ${colors.primary.deep};
        display: none;
        flex-direction: column;
        ${getTextsConfig('XS')};
        justify-content: center;
        left: ${rem(24)};
        min-height: ${rem(48)};
        padding: ${rem(4)} ${rem(24)} ${rem(4)} ${rem(44)};
        position: absolute;
        top: 0;
        white-space: nowrap;
        z-index: ${zindex.S};

        @keyframes showBubble {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    `}
`;

const HotspotWrapper = styled.div`
    align-items: center;
    display: block;
    position: relative;
`;

const HotspotBubbleDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        font-family: ${lightFont};

        &:not(:first-of-type) {
            margin-top: ${rem(2)};
        }
    `}
`;

type HotspotProps = {
    title: string;
    description?: string;
    hotspotStyle?: string;
    isAnimated?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
};

const Hotspot = ({
    title,
    description,
    hotspotStyle = 'primary',
    isAnimated = false,
    onClick,
    ...rest
}: HotspotProps) => (
    <HotspotWrapper>
        <HotspotButton
            {...rest}
            isAnimated={isAnimated}
            className={hotspotStyle}
            onClick={onClick}
        >
            <Icon iconName='plus' iconSize={20} />
        </HotspotButton>
        <HotspotBubble>
            {description ? (
                <>
                    <h3>{title}</h3>
                    <HotspotBubbleDescription>
                        {description}
                    </HotspotBubbleDescription>
                </>
            ) : (
                <HotspotBubbleDescription>{title}</HotspotBubbleDescription>
            )}
        </HotspotBubble>
    </HotspotWrapper>
);

export { HotspotButton, Hotspot, HotspotWrapper };
