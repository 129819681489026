import { HotspotContent2DModel } from './hotspot';
import { routes } from '../modules/routes/routes-config';
import { getCountryFromUrl } from 'utils/url-utils';
import { store } from 'store';

export type Room2D = {
    id: string;
    hotspots: HotspotContent2DModel[];
};

export type Rooms2DConfiguration = {
    home: Room2D;
    doctorOffice: Room2D;
};

const country = getCountryFromUrl();
const supportedCountries = store.getState().app.supportedCountries;

const homeConfiguration: Rooms2DConfiguration = {
    home: {
        id: 'home',
        hotspots: [
            {
                contentId: 'homeModal',
                type: 'video',
                roomId: '',
                areaId: 'home',
                position: { x: -310, y: -150 },
                navigate: routes.doctorOffice.getRoute(
                    'doctorOffice',
                    country,
                    supportedCountries
                ),
                content: {
                    id: 'homeModal',
                    kind: 'Video',
                    contentUrl:
                        'https://player.vimeo.com/video/174770945?h=a8a2895831'
                }
            }
        ]
    },
    doctorOffice: {
        id: 'doctorOffice',
        hotspots: [
            {
                contentId: 'menu',
                type: 'menu',
                roomId: '',
                areaId: 'doctorOffice',
                position: { x: -70, y: 165 }
            },
            {
                contentId: 'quickTour',
                type: 'video',
                roomId: '',
                areaId: 'doctorOffice',
                position: { x: 300, y: 200 },
                content: {
                    id: 'doctorOfficeQuickTour',
                    kind: 'Video',
                    contentUrl:
                        'https://vclincprodams-euwe.streaming.media.azure.net/c0be810a-85b9-4719-ad82-ef1412d66657/VC-overall_Quick-Tour_QT-whole_0.ism/manifest(format=mpd-time-cmaf)'
                }
            },
            {
                contentId: 'gameBreak',
                type: 'game',
                roomId: 'doctorOffice',
                areaId: 'doctorOffice',
                position: { x: 585, y: -230 },
                contents: [
                    {
                        id: 'gameBreak-acm',
                        kind: 'Game',
                        contentUrl:
                            'https://www.2can.it/Demo/life2021/index.html'
                    },
                    {
                        id: 'gameBreak-beat-the-algorithm',
                        kind: 'Game',
                        contentUrl: 'https://apps.fmc-data-solutions.com/ufr/'
                    },
                    {
                        id: 'gameBreak-how-are-you-today',
                        kind: 'Game',
                        contentUrl: 'https://fmc-data-solutions-demo.com/en'
                    },
                    {
                        id: 'gameBreak-ckd',
                        kind: 'Game',
                        contentUrl: 'https://www.2can.it/Demo/CKD/index.html'
                    }
                ]
            }
        ]
    }
};

const getHotspotsFromArea = (
    areaId: keyof Rooms2DConfiguration
): HotspotContent2DModel[] => {
    const room = Object.values(homeConfiguration[areaId]);

    return room ? (room[1] as HotspotContent2DModel[]) : [];
};

const getHotspotFromAreaAndId = (
    areaId: keyof Rooms2DConfiguration,
    hotspotId: string
) => {
    const hotspots = getHotspotsFromArea(areaId);

    return hotspots.find((hotspot) => hotspot.contentId === hotspotId);
};

export { getHotspotsFromArea, homeConfiguration, getHotspotFromAreaAndId };
