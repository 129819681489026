import { ActionTypes } from './action-types';
import { store } from './store';

const setCurrentCountry = (country: string) => {
    store.dispatch({ type: ActionTypes.SET_COUNTRY, payload: country });
};

const setSupportedCountries = (countries: string[]) => {
    store.dispatch({
        type: ActionTypes.SET_SUPPORTED_COUNTRIES,
        payload: countries
    });
};

export { setCurrentCountry, setSupportedCountries };
