import { useState } from 'react';
import { localPersistance } from 'services/local-persistance/local-persistance';

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const [storedValue, setStoredValue] = useState<T>(() => {
        try {
            const item = localPersistance.getItem<T>(key) || initialValue;
            return item;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value: T) => {
        try {
            setStoredValue(value);
            localPersistance.setItem<string>(key, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    };
    return [storedValue, setValue] as const;
};
