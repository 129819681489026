const app = {
    version: process.env.REACT_APP_VERSION,
    buildId: process.env.REACT_APP_BUILD_ID,
    environment: process.env.NODE_ENV
};

const api = {
    baseUrl: process.env.REACT_APP_API_BASE_URL
};

const appInsights = {
    connectionString: process.env.REACT_APP_INSIGHTS_CONN_STRING
};

const matomo = {
    serverUrl: process.env.REACT_APP_TRACKERSERVERURL,
    site: Number(process.env.REACT_APP_TRACKERSITE)
};

const oneTrust = {
    url: process.env.REACT_APP_ONETRUSTURL,
    data: process.env.REACT_APP_ONETRUSTDATAURL
};

const streamsPlayer = {
    key: process.env.REACT_APP_STREAMS_PLAYER_KEY
};

export { app, api, appInsights, matomo, oneTrust, streamsPlayer };
