import styled from '@emotion/styled';
import { HotspotWrapper } from './shared/hotspot/hotspot';

export const HomeWrapper = styled.div`
    ${({
        theme: {
            base: { zindex }
        }
    }) => `
        position: relative;

        ${HotspotWrapper} {
            animation: showHomeHotspot 1.5s;
            opacity: 1;
            position: absolute;
            z-index: ${zindex.above};

            @keyframes showHomeHotspot {
                0%,
                50% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
            }
        }
    `}
`;
