import { useParams } from 'react-router-dom';
import { RoomsConfiguration } from 'domain/room';
import { ModalHotspot } from '../shared/modal/templates/modal-hotspot';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { subtopicRepositoryImplementation } from 'services/api/http/repositores/subtopics/subtopics';
import { Subtopic } from 'domain/subtopic';

type Room3dModalProps = {
    therapyAlphaNumericId?: string;
    onClose: () => void;
};

const Room3dModal = ({ onClose, therapyAlphaNumericId }: Room3dModalProps) => {
    const { areaId, roomId, hotspotId } = useParams<{
        areaId: keyof RoomsConfiguration;
        roomId: string;
        hotspotId: string;
    }>();
    const { i18n } = useTranslation();

    const { state } = useClinicOverviewContext();
    const currentTopic = state.topics.find((topic) => topic.name === roomId);
    const [subtopics, setSubtopics] = useState<Subtopic[]>([]);
    useEffect(() => {
        if (currentTopic) {
            subtopicRepositoryImplementation
                .getSubtopics(String(currentTopic.id))
                .then((res) => setSubtopics(res || []));
        }
    }, [currentTopic?.id, i18n.language]);
    const currentSubtopic = subtopics.find(
        (subtopic) => subtopic.name === hotspotId
    );

    return currentSubtopic ? (
        <ModalHotspot
            hotspotContent={{
                areaId: areaId,
                roomId: roomId,
                contentId: currentSubtopic.name,
                title: currentSubtopic.nameLanguage,
                description: currentSubtopic.descriptionLanguage,
                position: {
                    ...currentSubtopic.position,
                    z: 0
                },
                survey: currentSubtopic.survey,
                contents: currentSubtopic.contents.map((c) => ({
                    id: c.nameLanguage,
                    kind: c.kind,
                    contentUrl: c.url,
                    title: c.nameLanguage,
                    thumbnailUrl: c.thumbnailUrl
                }))
            }}
            therapyAlphaNumericId={therapyAlphaNumericId}
            onClose={onClose}
        />
    ) : null;
};

export { Room3dModal };
