import React, { useRef, useEffect, RefObject } from 'react';
import { ThemeProvider, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import * as THREE from 'three';

import { useTracker } from 'hooks/use-tracker';
import { HotspotContent3DModel } from 'domain/hotspot';

import { Hotspot } from '../hotspot/hotspot';
import theme from '../../../../styles/theme';
import { CSS3DSprite, CSS3DRenderer } from './css-3d-renderer';

export type PanoramicViewerProps = {
    hasAnimation?: boolean;
    renderWidth: number;
    renderHeight: number;
    panoramicImage: string;
    hotspots: HotspotContent3DModel[];
    onHotspotClick: (hotspot: HotspotContent3DModel) => void;
};

const PanoramicWrapper = styled.div<{ hasAnimation?: boolean }>`
    ${({
        theme: {
            base: { zindex }
        },
        hasAnimation
    }) => `
        z-index: ${zindex.above};

        &:hover {
            cursor: grab;
        }

        &:active,
        &:focus {
            cursor: grabbing;
        }

        ${
            hasAnimation &&
            `canvas {
                animation: showPanoramic 1.5s;
                transform: scale(1);

                @keyframes showPanoramic {
                    0% { transform: scale(0); }
                    100% { transform: scale(1); }
                }
            }`
        }
    `}
`;

const PanoramicViewer: React.FC<PanoramicViewerProps> = ({
    hasAnimation,
    renderWidth,
    renderHeight,
    hotspots,
    panoramicImage,
    onHotspotClick
}: PanoramicViewerProps) => {
    const container = useRef<HTMLDivElement>();
    const { setDataForTracker } = useTracker();

    const {
        base: { layout }
    } = useTheme();

    let sceneContainer: HTMLDivElement;

    let onPointerDownPointerX: number;
    let onPointerDownPointerY: number;
    let onPointerDownLon: number;
    let onPointerDownLat: number;
    let lon = 0;
    let lat = 0;
    let phi = 0;
    let theta = 0;

    // Scene
    const scene = new THREE.Scene();

    // Camera
    const fieldOfView: number = 70;
    const aspectRatio: number = renderWidth / (renderHeight - layout.header);
    const near: number = 1;
    const far: number = 10000;
    const camera = new THREE.PerspectiveCamera(
        fieldOfView,
        aspectRatio,
        near,
        far
    );

    // Renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight - layout.header);

    // Sphere
    const geometry = new THREE.SphereGeometry(5000, 60, 40);

    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(panoramicImage);
    const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide
    });

    geometry.scale(-1, 1, 1);

    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);

    // CSS3DRenderer
    const htmlRenderer = new CSS3DRenderer();
    htmlRenderer.setSize(renderWidth, renderHeight);
    htmlRenderer.domElement.style.position = 'absolute';
    htmlRenderer.domElement.style.top = '0';
    htmlRenderer.domElement.style.pointerEvents = 'none';
    document.body.appendChild(htmlRenderer.domElement);

    useEffect(() => {
        // Add hotspots sprites to scene
        hotspots.forEach(insertHotspot);

        // Add scene container to renderer three
        sceneContainer = container.current as HTMLDivElement;

        sceneContainer?.appendChild(renderer.domElement);

        sceneContainer?.addEventListener('mousedown', onDocumentMouseDown);
        sceneContainer?.addEventListener('touchstart', onTouchstart);
        sceneContainer?.addEventListener('wheel', onDocumentMouseWheel as any);
        sceneContainer?.addEventListener(
            'DOMMouseScroll',
            onDocumentMouseWheel as any
        );

        // Init frame animation loop
        animate();

        // Clear dom elements when exit
        return function cleanupDOMElements() {
            document.body.removeChild(htmlRenderer.domElement);
            sceneContainer.removeChild(renderer.domElement);
        };
    }, [hotspots, container, panoramicImage, renderWidth, renderHeight]);

    const insertHotspot = (hotspot: HotspotContent3DModel) => {
        const panoramicImageSize = {
            width: 4000,
            height: 2000
        };
        const position2d = {
            x: hotspot.position.x - panoramicImageSize.width / 2,
            y: -hotspot.position.y + panoramicImageSize.height / 2
        };
        const positionSpherical = {
            azimuth: (2 * Math.PI * position2d.x) / panoramicImageSize.width,
            inclination: (Math.PI * position2d.y) / panoramicImageSize.height,
            radius: 5000
        };
        const position3d = {
            x:
                -positionSpherical.radius *
                Math.cos(positionSpherical.inclination) *
                Math.cos(positionSpherical.azimuth),
            y:
                positionSpherical.radius *
                Math.sin(positionSpherical.inclination),
            z:
                -positionSpherical.radius *
                Math.cos(positionSpherical.inclination) *
                Math.sin(positionSpherical.azimuth)
        };

        const perspectiveCoeficient = 10;

        const spriteContainer = document.createElement('div');

        const sprite = new CSS3DSprite(spriteContainer);

        sprite.position.set(position3d.x, position3d.y, position3d.z);
        sprite.scale.set(
            perspectiveCoeficient,
            perspectiveCoeficient,
            perspectiveCoeficient
        );

        const hotspotMarker = (
            <ThemeProvider theme={theme}>
                <Hotspot
                    {...setDataForTracker({
                        category: 'hotspot',
                        action: `${hotspot.areaId}-${hotspot.roomId}`
                    })}
                    title={hotspot.title}
                    description={hotspot.description}
                    isAnimated={hotspot.isAnimated}
                    onClick={() => onHotspotClick(hotspot)}
                />
            </ThemeProvider>
        );

        ReactDOM.render(hotspotMarker, spriteContainer);

        scene.add(sprite);
    };

    const onDocumentMouseDown = (e: MouseEvent) => {
        e.preventDefault();

        onPointerDownPointerX = e.clientX;
        onPointerDownPointerY = e.clientY;
        onPointerDownLon = lon;
        onPointerDownLat = lat;

        sceneContainer.addEventListener('mousemove', onDocumentMouseMove);
        sceneContainer.addEventListener('mouseup', onInteractionEnds);
    };

    const onTouchstart = (e: TouchEvent) => {
        e.preventDefault();

        onPointerDownPointerX = e.touches[0].clientX;
        onPointerDownPointerY = e.touches[0].clientY;
        onPointerDownLon = lon;
        onPointerDownLat = lat;

        sceneContainer.addEventListener('touchmove', onTouchmove);
        sceneContainer.addEventListener('touchend', onInteractionEnds);
    };

    const onDocumentMouseMove = (e: MouseEvent) => {
        lon = (e.clientX - onPointerDownPointerX) * -0.175 + onPointerDownLon;
        lat = (e.clientY - onPointerDownPointerY) * -0.175 + onPointerDownLat;
    };

    const onTouchmove = (e: TouchEvent) => {
        lon =
            (e.touches[0].clientX - onPointerDownPointerX) * -0.175 +
            onPointerDownLon;
        lat =
            (e.touches[0].clientY - onPointerDownPointerY) * -0.175 +
            onPointerDownLat;
    };

    const onInteractionEnds = () => {
        sceneContainer.removeEventListener('mousemove', onDocumentMouseMove);
        sceneContainer.removeEventListener('mouseup', onInteractionEnds);
        sceneContainer.removeEventListener('touchmove', onTouchmove);
        sceneContainer.removeEventListener('touchend', onInteractionEnds);
    };

    const onDocumentMouseWheel = (e: React.WheelEvent) => {
        const fov = e.deltaY ? camera.fov + e.deltaY * 0.05 : camera.fov;

        camera.fov = THREE.MathUtils.clamp(fov, 10, 75);
        camera.updateProjectionMatrix();
    };

    const render = () => {
        lat = Math.max(-85, Math.min(85, lat));
        phi = THREE.MathUtils.degToRad(90 - lat);
        theta = THREE.MathUtils.degToRad(lon);

        camera.position.y = 100 * Math.cos(phi);

        const positionX = 100 * Math.sin(phi) * Math.cos(theta);
        const positionZ = 100 * Math.sin(phi) * Math.sin(theta);

        camera.position.x = positionX;
        camera.position.z = positionZ;

        camera.lookAt(scene.position);

        renderer.render(scene, camera);
        htmlRenderer.render(scene, camera);
    };

    const animate = () => {
        requestAnimationFrame(animate);
        render();
    };

    return (
        <PanoramicWrapper hasAnimation={hasAnimation}>
            <div ref={container as RefObject<HTMLDivElement>}></div>
        </PanoramicWrapper>
    );
};

export { PanoramicViewer };
