import { useHistory, useParams } from 'react-router-dom';
import { routes } from 'modules/routes/routes-config';
import { getCountryFromUrl } from 'utils/url-utils';
import { store } from 'store';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';

const useRoomNavigation = () => {
    const { areaId, roomId } =
        useParams<{ country: string; areaId: string; roomId: string }>();
    const history = useHistory();
    const { state } = useClinicOverviewContext();
    const rooms = state.topics
        .filter((t) => t.therapyName === areaId)
        .map((t) => t.name);

    const country = getCountryFromUrl();
    const supportedCountries = store.getState().app.supportedCountries;

    const currentIndex = rooms.indexOf(roomId);
    const nextIndex = (currentIndex + 1) % rooms.length;
    const nextRoom = rooms[nextIndex];

    const goToFloor = () => {
        history.push(
            routes.clinicOverview.getRoute(country, supportedCountries, areaId)
        );
    };

    const goToNext = nextRoom
        ? () => {
              history.push(
                  routes.room.getRoute(
                      areaId,
                      nextRoom,
                      country,
                      supportedCountries
                  )
              );
          }
        : undefined;

    return { goToFloor, goToNext };
};

export { useRoomNavigation };
