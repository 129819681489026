import {
    createContext,
    useReducer,
    Dispatch,
    useEffect,
    useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { therapyRepositoryImplementation } from 'services/api/http/repositores/therapy/therapies';
import { topicRepositoryImplementation } from 'services/api/http/repositores/topic/topic';

import { Actions, State } from './clinic-overview.model';
import { initialValues, reducer } from './clinic-overview.reducer';

const ClinicOverviewContext = createContext<{
    state: State;
    dispatch: Dispatch<Actions>;
}>({ state: initialValues, dispatch: () => null });

const ClinicOverviewProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialValues);
    const { i18n } = useTranslation();

    useEffect(() => {
        therapyRepositoryImplementation.getTherapies().then((therapies) => {
            dispatch({ type: 'SET_THERAPIES', payload: { therapies } });
        });
        topicRepositoryImplementation.getTopics().then((topics) => {
            dispatch({ type: 'SET_TOPICS', payload: { topics } });
        });
        return () => dispatch({ type: 'FINALIZE' });
    }, [i18n.language]);

    return (
        <ClinicOverviewContext.Provider value={{ state, dispatch }}>
            {children}
        </ClinicOverviewContext.Provider>
    );
};

const useClinicOverviewContext = () => {
    const context = useContext(ClinicOverviewContext);
    if (!context) {
        throw new Error(
            'useClinicOverviewContext must be used within a ClinicOverviewContext'
        );
    }
    return context;
};

export {
    ClinicOverviewContext,
    ClinicOverviewProvider,
    useClinicOverviewContext
};
