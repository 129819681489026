const localPersistanceFactory = () => {
    const setItem = <T>(key: string, value: T) =>
        window.localStorage.setItem(key, JSON.stringify(value));

    const getItem = <T>(key: string): T => {
        const item = window.localStorage.getItem(key);
        return JSON.parse(item!);
    };

    return {
        setItem,
        getItem
    };
};

const localPersistance = localPersistanceFactory();

export { localPersistance };
