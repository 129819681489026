import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

type VideoProps = {
    src?: string;
    poster?: string;
    height?: number;
    width?: number;
    onEndVideo: () => void;
};

const VideoWrapper = styled.div<Partial<VideoProps>>`
    ${({ height, width }) => `
        display: block;
        line-height: 0;
        position: relative;

        &,
        > video {
            height: ${height ? rem(height) : 'auto'};
            width: ${width ? rem(width) : '100%'};
        }
    `}
`;

const Video = ({ src, poster, height, width, onEndVideo }: VideoProps) => {
    return (
        <VideoWrapper>
            <video
                src={src}
                poster={poster}
                height={height}
                width={width}
                autoPlay
                muted
                onEnded={onEndVideo}
            />
        </VideoWrapper>
    );
};

export { Video };
