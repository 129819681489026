import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Icon } from '../icon/icon';

const LogoWrapper = styled.div`
    ${({
        theme: {
            base: { colors, layout }
        }
    }) => `
        align-items: center;
        background-color: ${colors.primary.brand};
        color: ${colors.neutral.fullLight};
        display: flex;
        height: ${rem(layout.header)};
        padding: 0 ${rem(20)};
        cursor: pointer;
    `}
`;

const LogoText = styled.h1`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig }
            }
        }
    }) => `
        ${getTextsConfig('S')};
        margin-left: ${rem(20)};
    `}
`;

const Logo = () => {
    return (
        <LogoWrapper title='Virtual Clinic Overview'>
            <Icon iconName='logo' iconSize={35} />
            <LogoText>Overview</LogoText>
        </LogoWrapper>
    );
};

export { Logo };
