import { useHistory, useParams } from 'react-router-dom';
import { getHotspotFromAreaAndId, Rooms2DConfiguration } from 'domain/room2d';
import { useTranslation } from 'react-i18next';
import { DefaultModal } from '../shared/modal/default/default-modal';
import { ModalVideo } from '../shared/modal/templates/modal-video';
import { PatientsModal } from '../doctor-office/patients-modal';
import { ModalGameBreak } from '../shared/modal/templates/modal-game-break';

type Room2dModalProps = {
    onClose: () => void;
};

const Room2dModal = ({ onClose }: Room2dModalProps) => {
    const { t } = useTranslation();

    const history = useHistory();

    const { areaId, hotspotId } = useParams<{
        areaId: keyof Rooms2DConfiguration;
        hotspotId: string;
    }>();

    const hotspotContent = getHotspotFromAreaAndId(areaId, hotspotId);

    if (hotspotContent === undefined) {
        onClose();
    }

    return hotspotContent ? (
        hotspotContent?.type === 'game' ? (
            <ModalGameBreak hotspotContent={hotspotContent} onClose={onClose} />
        ) : hotspotContent?.type === 'video' ? (
            <DefaultModal
                bgStyle='dark'
                modalSize='medium'
                isBgBlur={true}
                onClose={onClose}
            >
                <ModalVideo
                    videoSrc={hotspotContent.content?.contentUrl}
                    title={t(
                        `areas.${areaId}.${hotspotContent.contentId}.content.title`
                    )}
                    description={t(
                        `areas.${areaId}.${hotspotContent.contentId}.content.description`
                    )}
                    btnLabel={t(`areas.${areaId}.modal.btnLabel`)}
                    onClick={
                        hotspotContent.navigate
                            ? () => history.push(hotspotContent.navigate ?? '')
                            : onClose
                    }
                />
            </DefaultModal>
        ) : hotspotContent?.type === 'menu' ? (
            <DefaultModal
                bgStyle='light'
                modalSize='fullWidth'
                originModal='left'
                isBgBlur={false}
                showCloseBtn={true}
                onClose={onClose}
            >
                <PatientsModal onClose={onClose} />
            </DefaultModal>
        ) : null
    ) : null;
};

export { Room2dModal };
