import React, { useRef, useEffect, RefObject } from 'react';
import { ThemeProvider, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import * as THREE from 'three';

import { useTracker } from 'hooks/use-tracker';
import { HotspotContent2DModel } from 'domain/hotspot';

import theme from '../../../../styles/theme';
import { Hotspot } from '../hotspot/hotspot';
import { CSS3DSprite, CSS3DRenderer } from './css-3d-renderer';
import { useTranslation } from 'react-i18next';

const StaticWrapper = styled.div`
    ${({
        theme: {
            base: { zindex }
        }
    }) => `
        z-index: ${zindex.above};
    `}
`;

export type StaticViewerProps = {
    staticImage: string;
    rendererWidth?: number;
    rendererHeight?: number;
    hotspots: HotspotContent2DModel[];
    onHotspotClick: (hotspot: HotspotContent2DModel) => void;
};

const StaticViewer: React.FC<StaticViewerProps> = ({
    rendererWidth,
    rendererHeight,
    staticImage,
    hotspots,
    onHotspotClick
}: StaticViewerProps) => {
    const container = useRef<HTMLDivElement>();
    const { setDataForTracker } = useTracker();
    const { t } = useTranslation();

    const {
        base: { layout }
    } = useTheme();

    let sceneContainer: HTMLDivElement;

    // Scene
    const scene = new THREE.Scene();

    // Camera
    const cameraWidth = rendererWidth || 0;
    const cameraHeight = rendererHeight || 0 - layout.header;
    const near: number = -1;
    const far: number = 1;

    const camera = new THREE.OrthographicCamera(
        cameraWidth / -2,
        cameraWidth / 2,
        cameraHeight / 2,
        cameraHeight / -2,
        near,
        far
    );

    // Renderer
    const renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(cameraWidth, cameraHeight);

    // CSS3DRenderer
    const htmlRenderer = new CSS3DRenderer();
    htmlRenderer.setSize(window.innerWidth, window.innerHeight);
    htmlRenderer.domElement.style.position = 'absolute';
    htmlRenderer.domElement.style.top = '0';
    htmlRenderer.domElement.style.pointerEvents = 'none';
    document.body.appendChild(htmlRenderer.domElement);

    useEffect(() => {
        sceneContainer = container.current as HTMLDivElement;

        sceneContainer?.appendChild(renderer.domElement);

        animate();
    }, [container, rendererWidth, rendererHeight]);

    const insertBackgroundImage = () => {
        const spriteContainer = document.createElement('div');
        spriteContainer.id = 'spriteBackgroundContainer';
        spriteContainer.style.width = '100%';
        spriteContainer.style.height = 'auto';

        const sprite = new CSS3DSprite(spriteContainer);

        sprite.position.set(0, 0, 0);

        const image = (
            <img
                style={{ width: '100%', height: 'auto' }}
                src={staticImage}
                alt='background'
            />
        );

        ReactDOM.render(image, spriteContainer);

        scene.add(sprite);
        animate();
    };

    useEffect(() => {
        cleanupDOMElements();

        insertBackgroundImage();

        hotspots.forEach(insertHotspot);

        return function cleanup() {
            cleanupDOMElements();
        };
    }, [staticImage]);

    const cleanupDOMElements = () => {
        // Remove rendered components
        if (document.getElementById('spriteBackgroundContainer')) {
            unmountComponentAtNode(
                document.getElementById(
                    'spriteBackgroundContainer'
                ) as HTMLDivElement
            );
        }

        // Remove scene children
        for (let i = scene.children.length - 1; i >= 0; i--) {
            const obj = scene.children[i];
            scene.remove(obj);
        }
    };

    const insertHotspot = (hotspot: HotspotContent2DModel) => {
        const { position } = hotspot;

        const spriteContainer = document.createElement('div');

        const sprite = new CSS3DSprite(spriteContainer);

        const heigthBase = (cameraWidth * 1080) / 1920 - layout.header;

        const width = (cameraWidth * position.x) / 1920;
        const heigth = (heigthBase * position.y) / 1080;
        sprite.position.set(width, heigth, 0);

        const hotspotMarker = (
            <ThemeProvider theme={theme}>
                <Hotspot
                    {...setDataForTracker({
                        category: 'hotspot',
                        action: `${hotspot.areaId}`
                    })}
                    title={t(
                        `areas.${hotspot.areaId}.${hotspot.contentId}.title`
                    )}
                    description={t(
                        `areas.${hotspot.areaId}.${hotspot.contentId}.description`
                    )}
                    onClick={() => onHotspotClick(hotspot)}
                />
            </ThemeProvider>
        );

        ReactDOM.render(hotspotMarker, spriteContainer);
        scene.add(sprite);
    };

    const render = () => {
        renderer.render(scene, camera);
        htmlRenderer.render(scene, camera);
    };

    const animate = () => {
        camera.position.x = 0;
        camera.position.y = 0;

        camera.lookAt(scene.position);

        requestAnimationFrame(animate);
        render();
    };

    return (
        <StaticWrapper>
            <div ref={container as RefObject<HTMLDivElement>}></div>
        </StaticWrapper>
    );
};

export { StaticViewer };
