import React, { ReactNode, Suspense } from 'react';
import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import { Spinner } from '../spinner/spinner';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { OriginModal } from './default/default-modal';
import { rem } from 'styles/utils/sizes';
import { useTheme } from '@emotion/react';

export type BgModalStyle = 'transparent' | 'light' | 'dark';
export type ModalSize = 'small' | 'medium' | 'fullWidth';

type ChildrenProps = {
    closeModal: (event: React.MouseEvent) => void;
};

export type ModalProps = {
    bgStyle?: BgModalStyle;
    modalSize?: ModalSize;
    originModal?: OriginModal;
    isBgBlur?: boolean;
    className?: string;
    fullScreen?: boolean;
    onClose: (event: React.MouseEvent) => void;
    children: ((props: ChildrenProps) => ReactNode) | ReactNode;
};

const ModalContentStyled = styled(ReactModal)<{ isBgBlur?: boolean }>`
    ${({
        theme: {
            base: { colors, zindex }
        },
        isBgBlur
    }) => `
        height: 100%;
        outline: none;
        width: 100vw;

        ${
            isBgBlur &&
            `
                animation: showBlur 2s;
                backdrop-filter: blur(${rem(12)})
            `
        };

        @keyframes showBlur {
            0% { backdrop-filter: blur(${rem(0)}) }
            100% { backdrop-filter: blur(${rem(12)}) }
        }

        @keyframes showOverlay {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        &.light {
            background-color: ${getRgbaStrFromHexColor(
                colors.neutral.fullLight,
                0.5
            )};
        }

        &.dark {
            background-color: ${getRgbaStrFromHexColor(
                colors.primary.deep,
                0.5
            )};
        }

        &.transparent {
            background-color: transparent;
        }

        $.fullScreen {
            z-index: ${zindex.fullScreen};
        }
    `}
`;

const Modal = ({
    className,
    bgStyle = 'light',
    modalSize = 'medium',
    isBgBlur,
    originModal,
    fullScreen,
    onClose,
    children
}: ModalProps) => {
    const {
        base: { zindex }
    } = useTheme();

    return (
        <ModalContentStyled
            portalClassName={className}
            className={bgStyle + ' ' + modalSize + ' ' + originModal}
            style={{
                overlay: {
                    zIndex: fullScreen ? zindex.fullScreen : zindex.modalContent
                }
            }}
            isBgBlur={isBgBlur}
            ariaHideApp={false}
            onRequestClose={onClose}
            isOpen
            shouldCloseOnEsc
        >
            <Suspense fallback={<Spinner />}>
                {typeof children === 'function'
                    ? children({ closeModal: onClose })
                    : children}
            </Suspense>
        </ModalContentStyled>
    );
};
export { Modal };
