import styled from '@emotion/styled';
import React, { forwardRef, Ref } from 'react';
import { rem } from 'styles/utils/sizes';
import { Icon } from '../icon/icon';

export type ButtonIconStyle = 'primary' | 'secondary' | 'tertiary';

type ButtonIconBaseProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

const ButtonIconBase = styled.button<ButtonIconBaseProps>`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        align-items: center;
        display: flex;
        height: ${rem(40)};
        justify-content: center;
        line-height: 0;
        width: ${rem(40)};

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        > div {
          display: inherit;
          line-height: 0;
        }

        &.primary {
          &,
          &:active {
            background-color: ${colors.primary.brand};
            color: ${colors.neutral.fullLight};
          }

          &:hover {
            background-color: ${colors.neutral.fullLight};
            color: ${colors.primary.brand};
          }
        }

        &.secondary {
          &,
          &:active {
            background-color: ${colors.neutral.fullLight};
            color: ${colors.primary.brand};
          }

          &:hover {
            background-color: ${colors.primary.brand};
            color: ${colors.neutral.fullLight};
          }
        }

        &.tertiary {
          &,
          &:active {
            background-color: ${colors.primary.deep};
            color: ${colors.neutral.fullLight};
          }

          &:hover {
            color: ${colors.primary.efective};
          }
        }
    `}
`;

interface ButtonIconProps extends ButtonIconBaseProps {
    iconName: string;
    iconSize?: number;
    btnStyle?: ButtonIconStyle;
}

const ButtonIcon = forwardRef(
    (
        {
            iconName,
            iconSize = 20,
            btnStyle = 'primary',
            ...restProps
        }: ButtonIconProps,
        ref: Ref<HTMLButtonElement>
    ) => (
        <ButtonIconBase className={btnStyle} {...restProps} ref={ref}>
            <div data-testid='button-icon'>
                <Icon iconName={iconName} iconSize={iconSize} />
            </div>
        </ButtonIconBase>
    )
);
ButtonIcon.displayName = 'ButtonIcon';

const ButtonIconPrimary = forwardRef(
    (props: ButtonIconProps, ref: Ref<HTMLButtonElement>) => (
        <ButtonIcon btnStyle='primary' {...props} ref={ref} />
    )
);

ButtonIconPrimary.displayName = 'ButtonIconPrimary';

const ButtonIconSecondary = forwardRef(
    (props: ButtonIconProps, ref: Ref<HTMLButtonElement>) => (
        <ButtonIcon btnStyle='secondary' {...props} ref={ref} />
    )
);

ButtonIconSecondary.displayName = 'ButtonIconSecondary';

const ButtonIconTertiary = forwardRef(
    (props: ButtonIconProps, ref: Ref<HTMLButtonElement>) => (
        <ButtonIcon btnStyle='tertiary' {...props} ref={ref} />
    )
);

ButtonIconTertiary.displayName = 'ButtonIconTertiary';

export {
    ButtonIcon,
    ButtonIconBase,
    ButtonIconPrimary,
    ButtonIconSecondary,
    ButtonIconTertiary
};
