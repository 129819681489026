import { useEffect, useContext, useCallback } from 'react';

import { ClinicArea } from 'domain/clinic-overview';
import { useQueryStringParams } from 'hooks/use-query-string-params';
import { ClinicOverviewContext } from 'modules/context/clinic-overview';

import { Clinic } from './clinic-overview/clinic';
import { useHistory } from 'react-router-dom';
import { routes } from 'modules/routes/routes-config';
import { useTranslation } from 'react-i18next';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';

const ClinicOverview = () => {
    const {
        state: { areas, activeAreaIndex },
        dispatch: clinicOverviewDispatch
    } = useContext(ClinicOverviewContext);

    const areaParam = useQueryStringParams('area');
    const { i18n } = useTranslation();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const history = useHistory();

    const activeAreaParam = useCallback(() => {
        if (areaParam) {
            clinicOverviewDispatch({
                type: 'ACTIVE_AREA',
                payload: { id: areaParam }
            });
        }
    }, [areaParam]);

    const country = getCountryFromUrl();

    useEffect(() => {
        clinicOverviewDispatch({ type: 'INITIALIZE' });

        return () => clinicOverviewDispatch({ type: 'FINALIZE' });
    }, [i18n.language]);

    const onOpenMenu = useCallback((open: boolean) => {
        clinicOverviewDispatch({ type: 'OPEN_MENU', payload: { open } });
    }, []);

    const onClickArea = useCallback(({ id }: ClinicArea) => {
        clinicOverviewDispatch({ type: 'ACTIVE_AREA', payload: { id } });
        history.push(
            routes.clinicOverview.getRoute(country, supportedCountries, id)
        );
    }, []);

    const onClickOutArea = useCallback(() => {
        clinicOverviewDispatch({ type: 'UNACTIVE_AREA' });
        history.replace(
            routes.clinicOverview.getRoute(country, supportedCountries)
        );
    }, []);

    const onFocusArea = useCallback(({ id }: ClinicArea) => {
        clinicOverviewDispatch({ type: 'FOCUS_AREA', payload: { id } });
    }, []);

    const onUnfocusArea = useCallback(() => {
        clinicOverviewDispatch({ type: 'UNFOCUS_AREA' });
    }, []);

    return (
        <Clinic
            areas={areas}
            activeAreaIndex={activeAreaIndex}
            scaledImage={activeAreaIndex}
            onOpenMenu={onOpenMenu}
            onClickArea={onClickArea}
            onClickOutArea={onClickOutArea}
            onFocusArea={onFocusArea}
            onUnfocusArea={onUnfocusArea}
            onAnimationEnd={() => {
                clinicOverviewDispatch({
                    type: 'OPEN_DISCLAIMER_MODAL'
                });
                activeAreaParam();
            }}
        />
    );
};

export { ClinicOverview };
