import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Icon, IconWrapper } from 'modules/app/shared/icon/icon';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { ThemeColors } from 'styles/theme';

export type PatientsMenuItemProps = {
    title: string;
    description: string;
    sector: string;
    image: string;
    color?: string;
    dataSection?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const PatientsMenuPicture = styled.picture`
    line-height: 0;
    max-height: ${rem(500)};
    overflow: hidden;
    width: 100%;
`;

const PatientsMenuImage = styled.img`
    width: 100%;
`;

const PatientsMenuTitle = styled.h3`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        box-sizing: border-box;
        color: ${colors.neutral.fullLight};
        ${getHeadlinesConfig('M')};
        margin-bottom: ${rem(32)};
        padding: ${rem(40)} ${rem(40)} 0;
        width: 100%;
    `}
`;

const PatientsMenuText = styled.p`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getTextsConfig }
            }
        }
    }) => `
        box-sizing: border-box;
        color: ${colors.neutral.fullLight};
        ${getTextsConfig('S')};
        padding: 0 ${rem(40)};
        width: 100%;
    `}
`;

const PatientsMenuArrow = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        align-items: center;
        background-color: ${getRgbaStrFromHexColor(
            colors.neutral.fullLight,
            0.6
        )};
        box-sizing: border-box;
        display: flex;
        height: ${rem(60)};
        justify-content: flex-end;
        margin-top: auto;
        padding: 0 ${rem(20)};
        transition: height 0.3s ease;
        width: 100%;

        ${IconWrapper} {
            color: ${colors.neutral.fullLight};
        }
    `}
`;

const PatientsMenuItemWrapper = styled.article<{
    color: string;
}>`
    ${({
        theme: {
            base: { colors }
        },
        color
    }) => `
        align-items: flex-start;
        background-color: ${
            colors.navigation[color as keyof ThemeColors['navigation']]
        };
        display: flex;
        flex-direction: column;
        position: relative;

        &:hover {
            cursor: pointer;

            ${PatientsMenuArrow} {
                height: ${rem(80)};
            }
        }

        > * {
            pointer-events: none;
        }
    `}
`;

const PatientsMenuItem = ({
    title,
    description,
    sector,
    color = 'ckd',
    image,
    dataSection,
    onClick
}: PatientsMenuItemProps) => {
    return (
        <PatientsMenuItemWrapper
            onClick={onClick}
            color={color}
            data-section={dataSection}
        >
            <PatientsMenuPicture>
                <PatientsMenuImage src={image} alt={title} />
            </PatientsMenuPicture>
            <PatientsMenuTitle>{title}</PatientsMenuTitle>
            <PatientsMenuText>{description}</PatientsMenuText>
            <PatientsMenuText>{sector}</PatientsMenuText>
            <PatientsMenuArrow>
                <Icon iconName='arrow-right' iconSize={20} />
            </PatientsMenuArrow>
        </PatientsMenuItemWrapper>
    );
};

export { PatientsMenuItem };
