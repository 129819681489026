import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next from 'i18next';

const defaultLanguage = 'en';
const namespaces = [''];
const defaultNamespace = '';
const debug = true;

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: '/static/locales/{{lng}}.json',
            allowMultiLoading: false,
            queryStringParams: {}
        },
        fallbackLng: defaultLanguage,
        ns: namespaces,
        defaultNS: defaultNamespace,
        debug: debug,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        react: {
            wait: true,
            useSuspense: false
        }
    });

export { i18next };
