import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Logo } from './logo';
import { Menu, MenuProps } from './menu';
import { Breadcrumb } from './breadcrumb';
import { ButtonIconTertiary } from '../button-icon/button-icon';
import { NavLink } from 'react-router-dom';
import { routes } from 'modules/routes/routes-config';
import { ModalHelp } from '../modal/templates/modal-help';
import { useTranslation } from 'react-i18next';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import i18next from 'i18next';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import { languageRepositoryImplementation } from 'services/api/http/repositores/languages/languages';

const HeaderWrapper = styled.header`
    ${({
        theme: {
            base: { colors, layout, zindex }
        }
    }) => `
        align-items: center;
        background-color: ${colors.primary.deep};
        color: ${colors.neutral.fullLight};
        display: flex;
        height: ${rem(layout.header)};
        justify-content: center;
        z-index: ${zindex.modalContent + 1};
    `}
`;

const MenuContainer = styled.div`
    ${({
        theme: {
            base: { zindex }
        }
    }) => `
        align-items: center;
        display: flex;
        z-index: ${zindex.above};
    `}
`;

const HelpWrapper = styled.div`
    ${() => `
        display: flex;
        justify-content: flex-end;
        min-width: ${rem(205)};
        position: relative;
        width: ${rem(205)};
    `}
`;

const SelectLanguage = styled.select`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont },
                colors
            }
        }
    }) => `
        background: ${colors.primary.deep};
        border: ${rem(2)} solid;
        border-radius: 0;
        box-sizing: border-box;
        border-color: ${colors.primary.deep};
        color: white;
        ${getTextsConfig('S')};
        font-family: ${lightFont};
        min-height: ${rem(40)};
        outline: none;
        padding: ${rem(7)} ${rem(10)};
        width: 100%;
        cursor: pointer;
    
        &::placeholder {
            color: ${getRgbaStrFromHexColor(colors.primary.deep, 0.4)};
        }

        option {
            color: ${colors.primary.deep};
            background: white;
        }
    `}
`;

const Header = ({ openMenu }: MenuProps) => {
    const [showHelpModal, setShowHelpModal] = useState(false);
    const { i18n } = useTranslation();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });

    const handleChangeLanguage = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        i18next.changeLanguage(event.target.value);
    };
    const country = getCountryFromUrl();

    const [languages, setLanguages] =
        useState<{ code: string; name: string }[]>();
    useEffect(() => {
        languageRepositoryImplementation
            .getLanguages()
            .then((languages) => setLanguages(languages));
    }, []);

    return (
        <HeaderWrapper>
            <MenuContainer>
                <Menu openMenu={openMenu} />
                <NavLink
                    exact
                    to={routes.clinicOverview.getRoute(
                        country,
                        supportedCountries
                    )}
                >
                    <Logo />
                </NavLink>
            </MenuContainer>
            <Breadcrumb />

            <HelpWrapper>
                <form onSubmit={void 0}>
                    <SelectLanguage
                        onChange={handleChangeLanguage}
                        value={i18n.language}
                        defaultValue={i18n.language}
                    >
                        {languages?.map((lang) => (
                            <option key={lang.code} value={lang.code}>
                                {lang.name}
                            </option>
                        ))}
                    </SelectLanguage>
                </form>

                <ButtonIconTertiary
                    iconName='question-circle'
                    onClick={() => {
                        setShowHelpModal(!showHelpModal);
                    }}
                />
                {showHelpModal && (
                    <ModalHelp
                        onClose={() => {
                            setShowHelpModal(false);
                        }}
                    />
                )}
            </HelpWrapper>
        </HeaderWrapper>
    );
};

export { Header };
