import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

type TranslateWithFormattingProps = {
    i18nKey: string;
};

const SpanBold = styled.span`
    ${({
        theme: {
            base: {
                fonts: { boldFont }
            }
        }
    }) => `
        font-family: ${boldFont};
   `}
`;

const TranslateWithFormatting = ({ i18nKey }: TranslateWithFormattingProps) => {
    const { t } = useTranslation();
    return (
        <Trans t={t} i18nKey={i18nKey} components={{ bold: <SpanBold /> }} />
    );
};

export { TranslateWithFormatting };
