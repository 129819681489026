import { rem } from '../utils/sizes';

export type HeadlinesSize = 'XL' | 'L' | 'M' | 'S' | 'XS';
type FontHeadlinesTypeSize = {
    XL: number;
    L: number;
    M: number;
    S: number;
    XS: number;
};

export type TextsSize = 'L' | 'ML' | 'M' | 'S' | 'XS';
type FontTextsTypeSize = {
    L: number;
    ML: number;
    M: number;
    S: number;
    XS: number;
};

export type ButtonsSize = 'L' | 'M';
type FontButtonsTypeSize = {
    L: number;
    M: number;
};

type FontType = {
    headline: FontHeadlinesTypeSize;
    lineHeightHeadline: FontHeadlinesTypeSize;
    text: FontTextsTypeSize;
    lineHeightText: FontTextsTypeSize;
    button: FontButtonsTypeSize;
    lineHeightButton: FontButtonsTypeSize;
};

export type Font = {
    boldFont: string;
    lightFont: string;
    getHeadlinesConfig: (size: HeadlinesSize) => string;
    getTextsConfig: (size: TextsSize) => string;
    getButtonsConfig: (size: ButtonsSize) => string;
};

const createFont = (
    boldFont: string,
    lightFont: string,
    fontSize: FontType
): Font => ({
    boldFont,
    lightFont,
    getHeadlinesConfig: (size: HeadlinesSize): string => `
            font-size: ${rem(fontSize.headline[size])};
            line-height: ${rem(fontSize.lineHeightHeadline[size])};
        `,
    getTextsConfig: (size: TextsSize): string => `
            font-size: ${rem(fontSize.text[size])};
            line-height: ${rem(fontSize.lineHeightText[size])};
        `,
    getButtonsConfig: (size: ButtonsSize): string => `
            font-size: ${rem(fontSize.button[size])};
            line-height: ${rem(fontSize.lineHeightButton[size])};
        `
});

export { createFont };
