import { RoomsConfiguration } from 'domain/room';
import { useInnerWindowSize } from 'hooks/use-inner-window-size';
import { useRoomNavigation } from 'hooks/use-room-navigation';
import { AppContext } from 'modules/context/app';
import { Area } from 'modules/context/app/app.model';
import { useContext, useEffect, useState } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useParams
} from 'react-router-dom';
import { PanoramicNavigation } from './shared/viewer/navigation/panoramic-navigation';
import { PanoramicViewer } from './shared/viewer/panoramic-viewer';
import { ModalTutorial } from './shared/modal/templates/modal-tutorial';
import { routes } from 'modules/routes/routes-config';
import { Room3dModal } from './room3d/room3d-modal';
import { subtopicRepositoryImplementation } from 'services/api/http/repositores/subtopics/subtopics';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';
import { Topic } from 'domain/topic';
import { HotspotContent3DModel } from 'domain/hotspot';
import { useTranslation } from 'react-i18next';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import { therapyRepositoryImplementation } from 'services/api/http/repositores/therapy/therapies';

const Room3d = () => {
    const [hotspots, setHotspots] = useState<HotspotContent3DModel[]>([]);
    const [therapyAlphaNumericId, setTherapyAlphaNumericId] =
        useState<string>();

    const area = useParams<{
        areaId: keyof RoomsConfiguration;
        roomId: string;
    }>();
    const { state } = useClinicOverviewContext();
    const currentTopic =
        state.topics.find((topic) => topic.name === area.roomId) ||
        ({} as Topic);

    const { goToFloor: onClickPrev, goToNext: onClickNext } =
        useRoomNavigation();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const canvasSize = useInnerWindowSize();
    const { dispatch: appDispatch, state: appState } = useContext(AppContext);
    const history = useHistory();
    const { i18n } = useTranslation();
    const closeModalTutorial = () => {
        appDispatch({
            type: 'SHOW_TUTORIAL',
            payload: { showTutorial: false }
        });
    };
    const country = getCountryFromUrl();

    useEffect(() => {
        appDispatch({
            type: 'CHANGE_CURRENT_AREA',
            payload: { currentArea: area as Area }
        });

        return () => {
            appDispatch({
                type: 'CHANGE_CURRENT_AREA',
                payload: { currentArea: undefined }
            });
        };
    }, [area.areaId, area.roomId]);

    useEffect(() => {
        if (currentTopic.id) {
            subtopicRepositoryImplementation
                .getHotspots(String(currentTopic.id), area)
                .then((res) => setHotspots(res));
            therapyRepositoryImplementation
                .getTherapies()
                .then((res) =>
                    setTherapyAlphaNumericId(
                        res.filter((x) => x.name === area?.areaId)[0]
                            .alphaNumericId
                    )
                );
        }
    }, [currentTopic.id, i18n.language]);

    return (
        <>
            <PanoramicViewer
                renderWidth={canvasSize.width}
                renderHeight={canvasSize.height}
                hotspots={hotspots}
                panoramicImage={currentTopic.panoramicImageUrl}
                onHotspotClick={(hotspot) =>
                    history.push(
                        routes.modal3DHotspot.getRoute(
                            hotspot.areaId,
                            hotspot.roomId,
                            hotspot.contentId,
                            country,
                            supportedCountries
                        )
                    )
                }
            />

            <PanoramicNavigation
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
            />

            <Switch>
                <Route
                    exact
                    path={routes.modal3DHotspot.getStaticRoute(
                        supportedCountries
                    )}
                    render={() => (
                        <Room3dModal
                            onClose={() =>
                                history.replace(
                                    routes.room.getRoute(
                                        area.areaId,
                                        area.roomId,
                                        country,
                                        supportedCountries
                                    )
                                )
                            }
                            therapyAlphaNumericId={therapyAlphaNumericId}
                        />
                    )}
                />
                <Route
                    render={() => (
                        <Redirect
                            to={routes.room.getRoute(
                                area.areaId,
                                area.roomId,
                                country,
                                supportedCountries
                            )}
                        />
                    )}
                />
            </Switch>

            {appState.showTutorial ? (
                <ModalTutorial onClose={() => closeModalTutorial()} />
            ) : null}
        </>
    );
};

export { Room3d };
