import { routes } from 'modules/routes/routes-config';
import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { store } from 'store';
import { getCountryFromUrl } from 'utils/url-utils';

import { ClinicOverviewContext } from '../clinic-overview.context';
import { useClinicOverviewActiveArea } from './use-clinic-overview-active-area';
import { useClinicOverviewHighlightArea } from './use-clinic-overview-highlight-area';

const useClinicOverviewMenu = () => {
    const {
        state: { areas },
        dispatch: clinicOverviewDispatch
    } = useContext(ClinicOverviewContext);

    const { activeArea } = useClinicOverviewActiveArea(areas);

    const { highlightedArea } = useClinicOverviewHighlightArea(areas);

    const country = getCountryFromUrl();
    const supportedCountries = store.getState().app.supportedCountries;

    const history = useHistory();

    const onMenuGoBack = useCallback(() => {
        clinicOverviewDispatch({ type: 'UNACTIVE_AREA' });
        history.replace(
            routes.clinicOverview.getRoute(country, supportedCountries)
        );
    }, []);

    const onMenuOptionClicked = useCallback((id: string) => {
        clinicOverviewDispatch({ type: 'ACTIVE_AREA', payload: { id } });
        history.push(
            routes.clinicOverview.getRoute(country, supportedCountries, id)
        );
    }, []);

    const onMenuOptionHighlighted = useCallback((id: string | null) => {
        id
            ? clinicOverviewDispatch({ type: 'FOCUS_AREA', payload: { id } })
            : clinicOverviewDispatch({ type: 'UNFOCUS_AREA' });
    }, []);

    return {
        activeArea,
        highlightedArea,
        onMenuGoBack,
        onMenuOptionClicked,
        onMenuOptionHighlighted
    };
};

export { useClinicOverviewMenu };
