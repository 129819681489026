import React from 'react';

import { ThemeColors } from 'styles/theme';

import CkdImg from 'assets/images/clinic/floors/ckd.png';
import RoofImg from 'assets/images/clinic/floors/roof.png';
import TransplantsImg from 'assets/images/clinic/floors/transplants.png';
import HaemodialysisImg from 'assets/images/clinic/floors/hemodialysis.png';
import HomeDialysisFloorImg from 'assets/images/clinic/floors/homedialysis01.png';
import HomeDialysisBuildImg from 'assets/images/clinic/floors/homedialysis03.png';
import CriticalCareImg from 'assets/images/clinic/floors/criticalcare.png';

import CkdHighlightImg from 'assets/images/clinic/highlight/ckd.svg';
import TransplantsHighlightImg from 'assets/images/clinic/highlight/transplants.svg';
import HomeDialysisHighlightImg from 'assets/images/clinic/highlight/homedialysis01.svg';
import HomeDialysisBuildHighlightImg from 'assets/images/clinic/highlight/homedialysis02.svg';
import HaemodialysisHighlightImg from 'assets/images/clinic/highlight/haemodialysis.svg';
import CriticalCareHighlightImg from 'assets/images/clinic/highlight/criticalcare.svg';

import { HotspotModel, HotspotPositionCSS } from './hotspot';

export type ClinicAreaName = typeof clinicAreaIds[number];

export type ClinicAreaColor = keyof ThemeColors['navigation'];

type ClinicAreaState = {
    active: boolean;
    highlight: boolean;
};

type ClinicAreaPosition = Pick<
    React.CSSProperties,
    'top' | 'bottom' | 'left' | 'right' | 'width' | 'height'
>;

export type ClinicTransform = {
    scale: number;
    translateX: number;
    translateY: number;
};

export type ClinicQuickTourValue = {
    video: { src: string; poster: string };
};

export type ClinicAreaPaintingInfo = {
    order: number;
    base: {
        coords?: string;
        fullCoords?: string;
        imagePath: string;
        transform?: ClinicTransform;
        imagePosition: ClinicAreaPosition;
    };
    highlight?: {
        imagePath: string;
        transform?: ClinicTransform;
        imagePosition: ClinicAreaPosition;
    };
    hotspots?: HotspotModel<HotspotPositionCSS>[];
};

export type ClinicArea = {
    id: ClinicAreaName;
    color: ClinicAreaColor;
    state: ClinicAreaState;
    quickTour?: ClinicQuickTourValue;
    paintingInfo: ClinicAreaPaintingInfo[];
};

const clinicAreaIds = [
    'ckd',
    'homeDialysis',
    'transplants',
    'hemodialysis',
    'criticalCare',
    'roof'
] as const;

const clinicAreaPaintingInfo: {
    [key in ClinicAreaName]: ClinicAreaPaintingInfo[];
} = {
    roof: [
        {
            order: 0,
            base: {
                imagePath: RoofImg,
                imagePosition: { top: 0, left: 0 },
                transform: { scale: 1, translateX: 0, translateY: -500 }
            }
        }
    ],
    criticalCare: [
        {
            order: 1,
            base: {
                imagePath: CriticalCareImg,
                imagePosition: { right: -545, top: -325 },
                transform: { scale: 1.2, translateX: 0, translateY: -160 },
                coords: '576,324,525,350,525,390,650,490,969,300,1173,460,1303,378,1303,338,1253,302,1173,348,969,188,647,380',
                fullCoords:
                    '525,348,527,394,647,489,973,304,1173,453,1303,375,1300,339,976,83'
            },
            highlight: {
                imagePath: CriticalCareHighlightImg,
                imagePosition: { left: 505, top: 305, width: 1107 },
                transform: { scale: 1.2, translateX: 20, translateY: -45 }
            },
            hotspots: []
        }
    ],
    hemodialysis: [
        {
            order: 2,
            base: {
                imagePath: HaemodialysisImg,
                imagePosition: { left: -522, top: -265 },
                transform: { scale: 1.05, translateX: 0, translateY: -160 },
                coords: '578,433,528,460,527,502,650,606,969,411,1169,567,1302,485,1300,450,1247,411,1171,460,969,304,649,492',
                fullCoords:
                    '578,433,528,460,527,502,650,606,969,411,1169,567,1302,485,1300,450,1247,411,1171,460,969,304,649,492'
            },
            highlight: {
                imagePath: HaemodialysisHighlightImg,
                imagePosition: { left: 515, top: 400, width: 1023 },
                transform: { scale: 1.045, translateX: 3, translateY: -1.5 }
            },
            hotspots: []
        }
    ],
    ckd: [
        {
            order: 3,
            base: {
                imagePath: CkdImg,
                imagePosition: { left: 530, top: 384 },
                transform: { scale: 1.2, translateX: -46, translateY: 34 },
                coords: '723,670,969,521,888,460,652,606,603,565,598,575,718,672',
                fullCoords:
                    '523,562,522,601,561,633,671,723,969,551,917,509,917,467,818,387'
            },
            highlight: {
                imagePath: CkdHighlightImg,
                imagePosition: { left: 590, top: 510 },
                transform: { scale: 1.2, translateX: -37, translateY: 40 }
            },
            hotspots: []
        }
    ],
    transplants: [
        {
            order: 3,
            base: {
                imagePath: TransplantsImg,
                imagePosition: { left: 844, top: 307 },
                transform: { scale: 1.2, translateX: 0, translateY: 8 },
                coords: '968,518,1041,470,968,411,891,460',
                fullCoords:
                    '818,385,973,292,1149,424,988,518,983,528,966,543,920,506,924,468'
            },
            highlight: {
                imagePath: TransplantsHighlightImg,
                imagePosition: { left: 827, top: 415 },
                transform: { scale: 1.2, translateX: 9, translateY: 28 }
            }
        }
    ],
    homeDialysis: [
        {
            order: 3,
            base: {
                imagePath: HomeDialysisFloorImg,
                imagePosition: { left: 965, top: 417 },
                transform: { scale: 1.22, translateX: 35, translateY: 35 },
                coords: '969,480,969,519,1169,680,1300,596,1298,558,1249,521,1169,565,1041,463,993,497',
                fullCoords:
                    '1212,740,1371,643,1371,594,1146,426,1032,495,986,519,983,535,966,546'
            },
            highlight: {
                imagePath: HomeDialysisHighlightImg,
                imagePosition: { left: 960, top: 522, width: 347 },
                transform: { scale: 1.22, translateX: 34, translateY: 53 }
            },
            hotspots: []
        },
        {
            order: 3,
            base: {
                imagePath: HomeDialysisBuildImg,
                imagePosition: { left: 1266, top: 636 },
                coords: '1341,935,1334,954,1348,969,1393,969,1417,960,1480,920,1495,933,1636,840,1639,792,1668,784,1670,738,1580,665,1524,672,1375,767,1373,825,1368,840,1336,865',
                fullCoords:
                    '1341,935,1334,954,1348,969,1393,969,1417,960,1480,920,1495,933,1636,840,1639,792,1668,784,1670,738,1580,665,1524,672,1375,767,1373,825,1368,840,1336,865',
                transform: {
                    scale: 1.22,
                    translateX: 20,
                    translateY: 20
                }
            },
            highlight: {
                imagePath: HomeDialysisBuildHighlightImg,
                imagePosition: { left: 1362, top: 715, width: 240 },
                transform: { scale: 1.22, translateX: 30, translateY: 20 }
            },
            hotspots: []
        }
    ]
};

const clinicQuickTours: Partial<
    {
        [key in ClinicAreaName | 'default']: ClinicQuickTourValue;
    }
> = {
    default: {
        video: {
            poster: '/static/images/quicktour/default-poster.jpg',
            src: 'https://vclincprodams-euwe.streaming.media.azure.net/7feef6fe-fe04-40b9-99cf-ecc5f7a2b1dc/VC_overall_Connected-Kidney-Care.ism/manifest(format=mpd-time-cmaf)'
        }
    },
    ckd: {
        video: {
            poster: '/static/images/quicktour/ckd-poster.jpg',
            src: 'https://vclincprodams-euwe.streaming.media.azure.net/17d8b955-e2b8-4a7e-a0c0-597722940260/VC-overall_Quick-Tour_QT-Therapi.ism/manifest(format=mpd-time-cmaf)'
        }
    },
    hemodialysis: {
        video: {
            poster: '/static/images/quicktour/hemodialysis-poster.jpg',
            src: ' https://vclincprodams-euwe.streaming.media.azure.net/6066c4c0-117e-417d-863b-cfa33f7c8345/VC-overall_Quick-Tour_QT-Therapi.ism/manifest(format=mpd-time-cmaf)'
        }
    },
    homeDialysis: {
        video: {
            poster: '/static/images/quicktour/home-dialysis-poster.jpg',
            src: ' https://vclincprodams-euwe.streaming.media.azure.net/72f08df4-a05c-40ff-9eb2-d3c88baa0843/VC-overall_Quick-Tour_QT-Therapi.ism/manifest(format=mpd-time-cmaf)'
        }
    },
    criticalCare: {
        video: {
            poster: '/static/images/quicktour/future-technologies-poster.jpg',
            src: 'https://vclincprodams-euwe.streaming.media.azure.net/2fb75a13-dcd9-4dc8-9fbb-ce7d4c3cd0b1/VC-overall_Quick-Tour_QT-Therapi.ism/manifest(format=mpd-time-cmaf)'
        }
    }
};

export { clinicAreaIds, clinicAreaPaintingInfo, clinicQuickTours };
