import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import Logo from 'assets/images/logo/fresenius-logo.png';
import { DisclaimerModal } from 'modules/app/shared/modal/templates/modal-disclaimer';
import {
    Translate,
    TranslateWithFormatting
} from 'modules/app/shared/translate';
import { ButtonTertiary } from 'modules/app/shared/button/button';
import { ClinicOverviewContext } from 'modules/context/clinic-overview';
import { useLocalStorage } from 'hooks/use-localstorage';

interface DisclaimerModalOptions {
    executeOnLoad?: boolean;
}

const ModalTutorialWrapper = styled.div`
    width: ${rem(460)};
    height: ${rem(750)};
    padding: ${rem(70)};
    margin-bottom: ${rem(40)};

    .tablet {
        width: ${rem(250)};
    }
`;

const DisclaimerText = styled.p`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont },
                colors
            }
        }
    }) => `
        ${getTextsConfig('L')};
        color: ${colors.primary.deep};;
        font-family: ${lightFont};
        margin-top: ${rem(40)};
    `}
`;

export const withDisclaimerModal =
    (Component: React.FC) =>
    ({ executeOnLoad }: DisclaimerModalOptions) =>
    () => {
        const {
            state: { isDisclaimerModalOpen },
            dispatch: clinicOverviewDispatch
        } = useContext(ClinicOverviewContext);

        const [hasAcceptedDisclaimerTerms, setHasAcceptedDisclaimerTerms] =
            useLocalStorage<boolean>('hasAcceptedDisclaimerTerms', false);

        useEffect(() => {
            if (executeOnLoad) {
                clinicOverviewDispatch({
                    type: 'OPEN_DISCLAIMER_MODAL'
                });
            }
        }, []);

        return (
            <>
                {!hasAcceptedDisclaimerTerms && isDisclaimerModalOpen && (
                    <DisclaimerModal
                        fullScreen
                        bgStyle='dark'
                        modalSize='small'
                        showCloseBtn={false}
                        isBgBlur={true}
                        originModal='bottom'
                        onClose={() => {
                            clinicOverviewDispatch({
                                type: 'CLOSE_DISCLAIMER_MODAL'
                            });
                        }}
                    >
                        <ModalTutorialWrapper>
                            <img src={Logo} alt='Fresenius Logo' />
                            <DisclaimerText>
                                <Translate
                                    i18nKey={
                                        'areas.home.disclaimerModal.designedby'
                                    }
                                />
                            </DisclaimerText>
                            <DisclaimerText>
                                <Translate
                                    i18nKey={
                                        'areas.home.disclaimerModal.informationPurpose'
                                    }
                                />
                            </DisclaimerText>
                            <DisclaimerText>
                                <TranslateWithFormatting
                                    i18nKey={
                                        'areas.home.disclaimerModal.clickingButton'
                                    }
                                />
                            </DisclaimerText>
                        </ModalTutorialWrapper>
                        <ButtonTertiary
                            iconName='arrow-right'
                            iconPosition='right'
                            btnSize='fullWidth'
                            btnAlign='between'
                            isFontSizeLarge={true}
                            onClick={() => {
                                setHasAcceptedDisclaimerTerms(true);
                                clinicOverviewDispatch({
                                    type: 'CLOSE_DISCLAIMER_MODAL'
                                });
                            }}
                        >
                            Accepted and Confirmed
                        </ButtonTertiary>
                    </DisclaimerModal>
                )}
                <Component></Component>
            </>
        );
    };
