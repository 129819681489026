import { useMemo } from 'react';

import { ClinicArea } from 'domain/clinic-overview';

const useClinicOverviewHighlightArea = (areas: ClinicArea[]) => {
    const highlightedArea = useMemo(
        () => areas.find((area) => area.state.highlight),
        [areas]
    );

    return { highlightedArea };
};

export { useClinicOverviewHighlightArea };
