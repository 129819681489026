import styled from '@emotion/styled';
import { getRgbaStrFromHexColor } from 'styles/utils/color';
import { rem } from 'styles/utils/sizes';
import { Translate } from '../translate';

export type VirtualClinicAdviceProps = {
    fixedStyle?: boolean;
};

const VirtualClinicAdviceWrapper = styled.p<VirtualClinicAdviceProps>`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getTextsConfig, lightFont },
                zindex
            }
        },
        fixedStyle
    }) => `
        animation: ${fixedStyle ? 'none' : 'changeTextColor 3s'};
        background-color: ${
            fixedStyle
                ? getRgbaStrFromHexColor(colors.neutral.fullLight, 0.7)
                : 'transparent'
        };
        bottom: ${rem(23)};
        color: ${colors.primary.deep};
        ${getTextsConfig('XS')};
        font-family: ${lightFont};
        left: ${rem(90)};
        padding: ${rem(4)} ${fixedStyle ? `${rem(8)}` : 0};
        position: fixed;
        z-index: ${zindex.S};

        @keyframes changeTextColor {
            0%,
            70% {
                color: ${colors.neutral.fullLight};
            }
            100% {
                color: ${colors.primary.deep};
            }
        }
    `}
`;

const VirtualClinicAdvice = ({
    fixedStyle = false
}: VirtualClinicAdviceProps) => {
    return (
        <VirtualClinicAdviceWrapper fixedStyle={fixedStyle}>
            <Translate i18nKey='generics.advice' />
        </VirtualClinicAdviceWrapper>
    );
};

export { VirtualClinicAdvice };
