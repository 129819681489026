import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export type GridTemplateAlign = 'top' | 'center' | 'bottom' | 'inherit';

type GridTemplateWrapperProps = {
    align?: GridTemplateAlign;
    columns?: number;
    gap?: number;
};

const GridTemplateDictionary: { [key: string]: string } = {
    center: 'center',
    bottom: 'flex-end',
    top: 'flex-start',
    inherit: 'inherit'
};

const GridTemplateWrapper = styled.div<GridTemplateWrapperProps>`
    ${({ align, columns, gap }) => `
        align-items: ${GridTemplateDictionary[align ?? 'top']};
        display: grid;
        grid-gap: ${rem(gap ?? 10)};
        grid-template-columns: repeat(${columns}, 1fr);
    `}
`;

const GridTemplate = ({
    align,
    columns = 1,
    children,
    gap
}: PropsWithChildren<GridTemplateWrapperProps>) => {
    return (
        <GridTemplateWrapper align={align} columns={columns} gap={gap}>
            {children}
        </GridTemplateWrapper>
    );
};

export { GridTemplate };
