import {
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useRef
} from 'react';
import { useHistory } from 'react-router-dom';
import {
    useMatomo,
    MatomoProvider,
    createInstance
} from '@datapunt/matomo-tracker-react';

import { AppContext } from 'modules/context/app';
import { configuration } from 'configuration';

const matomoInstance = configuration.matomo.serverUrl
    ? createInstance({
          urlBase: configuration.matomo.serverUrl,
          siteId: Number(configuration.matomo.site),
          trackerUrl: `${configuration.matomo.serverUrl}/piwik.php`,
          srcUrl: `${configuration.matomo.serverUrl}/piwik.js`,
          linkTracking: false
      })
    : null;

const AppTracker = ({ children }: PropsWithChildren<unknown>) => {
    return matomoInstance ? (
        <MatomoProvider value={matomoInstance}>
            <AppTrackerLogic>{children}</AppTrackerLogic>
        </MatomoProvider>
    ) : (
        <>{children}</>
    );
};

const AppTrackerLogic = ({ children }: PropsWithChildren<unknown>) => {
    const history = useHistory();
    const currentPath = useRef<string>();
    const { state } = useContext(AppContext);

    const { trackEvent, trackPageView, pushInstruction } = useMatomo();

    const generateUserId = useCallback(() => {
        const userId = state.userId;
        pushInstruction('setUserId', userId);
        console.info('TRACK', 'USER', { userId });
    }, []);

    const trackRouting = useCallback(() => {
        return history.listen(({ pathname }) => {
            if (currentPath.current !== pathname) {
                currentPath.current = pathname;
                const trackPageViewContent = {
                    href: `${window.location.origin}${pathname}`
                };

                trackPageView(trackPageViewContent);
                console.info('TRACK', 'ROUTE', trackPageViewContent);
            }
        });
    }, []);

    const trackLinks = useCallback((event: MouseEvent) => {
        const dataAttributtes = (event.target as any).dataset;
        const dataAttributtesForTracking = Object.entries<string>(
            dataAttributtes
        ).filter(([key]) => key.startsWith('tracker'));

        if (dataAttributtesForTracking.length > 0) {
            const trackEventContent = {
                category: dataAttributtes['trackerCategory'],
                action: dataAttributtes['trackerAction']
            };

            trackEvent(trackEventContent);
            console.info('TRACK', 'LINK', trackEventContent);
        }
    }, []);

    useEffect(() => {
        generateUserId();
        const unregisterTrackRouting = trackRouting();
        document.addEventListener('mousedown', trackLinks);

        return () => {
            unregisterTrackRouting();
            document.removeEventListener('mousedown', trackLinks);
        };
    }, []);

    return <>{children}</>;
};

export { AppTracker };
