import React, { PropsWithChildren } from 'react';
import { DefaultModal } from '../default/default-modal';
import ScreenImg from 'assets/images/panorama/screen.svg';
import TapImg from 'assets/images/panorama/tap.svg';
import LandscapeImg from 'assets/images/panorama/landscape.svg';
import { Translate } from '../../translate';
import {
    ModalTutorialWrapper,
    ModalTutorialDescription,
    BoxOne,
    BoxTwo
} from './ModalTutorialWrapper';

export type ModalTutorialProps = {
    onClose: (confirm: boolean) => void;
};

const ModalTutorial: React.FC<ModalTutorialProps> = ({
    onClose
}: PropsWithChildren<ModalTutorialProps>) => {
    return (
        <DefaultModal
            onClose={() => onClose(false)}
            bgStyle='dark'
            modalSize='small'
            originModal='bottom'
            isBgBlur={false}
            showCloseBtn={true}
        >
            <ModalTutorialWrapper>
                <ModalTutorialDescription>
                    <Translate i18nKey='help.panel.stepOne' />
                </ModalTutorialDescription>
                <BoxOne>
                    <img src={ScreenImg} alt='' className='tablet' />
                    <BoxTwo>
                        <img src={LandscapeImg} alt='' className='landscape' />
                    </BoxTwo>
                    <img src={TapImg} alt='' className='tap' />
                </BoxOne>
            </ModalTutorialWrapper>
        </DefaultModal>
    );
};

export { ModalTutorial };
