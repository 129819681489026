import { useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import { rem } from 'styles/utils/sizes';
import { AppContext } from 'modules/context/app/app.context';

import { Translate } from '../translate';
import { useHistory } from 'react-router-dom';
import { routes } from 'modules/routes/routes-config';
import { useClinicOverviewContext } from 'modules/context/clinic-overview';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';

const BreadcrumbWrapper = styled.div`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        align-items: center;
        display: flex;
        font-family: ${lightFont};
        justify-content: center;
        margin: 0 auto;
        padding: 0 ${rem(24)};
        width: 100%;
    `}
`;

const BreadcrumbLinkItem = styled.span<{ withContent: boolean }>`
    ${({
        theme: {
            base: { colors }
        },
        withContent
    }) => `
        align-items: center;
        display: flex;

        &:hover {
            color: ${colors.primary.efective};
            cursor: pointer;
        }

        ${
            withContent
                ? `
                    &:after {
                        background-color: ${colors.primary.efective};
                        content: '';
                        display: block;
                        margin: 0 ${rem(6)};
                        height: ${rem(1)};
                        width: ${rem(8)};
                    }
                `
                : ''
        }

    `}
`;

type BreadcrumbRoute = {
    getStaticRoute: (supportedCountries: string[]) => string | void;
    title: string;
};

const getCurrentRoute = (
    location: string,
    supportedCountries: string[]
): BreadcrumbRoute | undefined => {
    return Object.values(routes).find(
        (r) => r.getStaticRoute(supportedCountries) === location
    );
};

const Breadcrumb = () => {
    const { state } = useContext(AppContext);
    const { state: clinicOverviewState } = useClinicOverviewContext();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const history = useHistory();

    const [currentRoute, setcurrentRoute] = useState<
        BreadcrumbRoute | undefined
    >(getCurrentRoute(history.location.pathname, supportedCountries));

    useEffect(() => {
        history.listen((location) => {
            setcurrentRoute(
                getCurrentRoute(location.pathname, supportedCountries)
            );
        });
    }, []);

    const { therapyName, topicName } = useMemo(
        () => ({
            therapyName: clinicOverviewState.therapies.find(
                (th) => th.name === state.currentArea?.areaId
            )?.nameLanguage,
            topicName: clinicOverviewState.topics.find(
                (th) => th.name === state.currentArea?.roomId
            )?.nameLanguage
        }),
        [
            clinicOverviewState.therapies,
            clinicOverviewState.topics,
            state.currentArea
        ]
    );

    const country = getCountryFromUrl();

    return (
        <BreadcrumbWrapper>
            {state.currentArea ? (
                <>
                    <BreadcrumbLinkItem
                        onClick={() =>
                            history.push(
                                routes.clinicOverview.getRoute(
                                    country,
                                    supportedCountries,
                                    state.currentArea!.areaId
                                )
                            )
                        }
                        withContent={!!topicName && topicName !== therapyName}
                    >
                        {therapyName}
                    </BreadcrumbLinkItem>
                    {!!topicName && topicName !== therapyName ? (
                        <span>{topicName}</span>
                    ) : null}
                </>
            ) : (
                currentRoute && (
                    <span>
                        <Translate i18nKey={currentRoute.title} />
                    </span>
                )
            )}
        </BreadcrumbWrapper>
    );
};

export { Breadcrumb };
