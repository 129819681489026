const addOneTrustScript = (
    path: string,
    data: string,
    defer = false
): Promise<void> =>
    new Promise((resolve) => {
        if (!document.querySelector(`script[src='${path}']`)) {
            const script = document.createElement('script');

            script.setAttribute('src', path);
            script.setAttribute('data-domain-script', data);
            script.setAttribute('charset', 'UTF-8');
            script.setAttribute('type', 'text/javascript');

            if (defer) {
                script.setAttribute('defer', 'defer');
            }

            const scriptFunction = document.createElement('script');
            scriptFunction.setAttribute('type', 'text/javascript');
            scriptFunction.innerHTML = 'function OptanonWrapper() { }';

            document.body.appendChild(script);
            document.body.appendChild(scriptFunction);

            script.onload = () => {
                resolve();
            };
        } else {
            resolve();
        }
    });

export { addOneTrustScript };
