import { get } from 'services/api/http/axios-client';
import { Therapy } from 'domain/therapy';
import { getCountryFromUrl } from '../../../../../utils/url-utils';
import i18n from 'services/i18n';

export const therapyRepositoryImplementation = {
    getTherapies: async () => {
        const country = getCountryFromUrl();

        const data = await get<Therapy[]>(`${country}/therapies/`, {
            headers: { 'Accept-Language': i18n.language }
        }).catch();

        return data as Therapy[];
    }
};
