import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { PanoramicNavigationWrapper } from 'modules/app/shared/viewer/navigation/panoramic-navigation';
import { rem } from 'styles/utils/sizes';
import { DefaultModal } from '../default/default-modal';
import { ModalHotspotTitle } from './modal-hotspot/modal-hotspot-title';
import { ModalHotspotContent } from './modal-hotspot/modal-hotspot-content';
import { HotspotContentModel, HotspotPosition2D } from 'domain/hotspot';
import { useTranslation } from 'react-i18next';

const ModalHotspotWrapper = styled.div`
    align-items: flex-start;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 100%);
    overflow: hidden;
`;

const TITLEBOXSIZE = 355;

const HotspotOverviewWrapper = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        display: grid;
        grid-template-columns: ${rem(TITLEBOXSIZE)} calc(100% - ${rem(
        TITLEBOXSIZE
    )});
        transition: all ease 0.5s;

        &:after {
            background-color: ${colors.primary.brand};
            content: '';
            height: ${rem(61)};
            grid-column-end: 4;
            grid-column-start: 1;
        }

        > ${PanoramicNavigationWrapper} {
            position: absolute;
        }

        &.isVisible {
            transform: translateX(0);
        }

        &.isHidden {
            transform: translateX(-100%);
        }
    `}
`;

export type ModalHotspotProps = {
    hotspotContent: HotspotContentModel<HotspotPosition2D>;
    onClose: () => void;
};

const ModalGameBreak = ({
    hotspotContent: { areaId, roomId, contentId, contents },
    onClose
}: PropsWithChildren<ModalHotspotProps>) => {
    const { t } = useTranslation();

    return (
        <DefaultModal
            onClose={onClose}
            bgStyle='dark'
            modalSize='fullWidth'
            originModal='left'
            isBgBlur={false}
            showCloseBtn={true}
        >
            <ModalHotspotWrapper>
                <HotspotOverviewWrapper className='isVisible'>
                    <ModalHotspotTitle
                        title={t(
                            `areas.${areaId}.${roomId}.${contentId}.title`
                        )}
                        description={t(
                            `areas.${areaId}.${roomId}.${contentId}.description`
                        )}
                    />
                    <ModalHotspotContent
                        contents={contents?.map((c) => ({
                            ...c,
                            title: t(
                                `areas.${areaId}.${roomId}.${contentId}.content.values.${c.id}.title`
                            )
                        }))}
                        onClose={onClose}
                        therapyAlphaNumericId={undefined}
                    />
                </HotspotOverviewWrapper>
            </ModalHotspotWrapper>
        </DefaultModal>
    );
};

export { ModalGameBreak };
