import axios, { AxiosRequestConfig, AxiosInstance, ResponseType } from 'axios';
import { addErrorInterceptor } from './axios-interceptors';
import { configuration } from 'configuration';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ApiQueryRequest<TRequest, TResponse> = ApiRequest<
    TRequest,
    TResponse
> & {
    key: string | string[];
};

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export type ApiRequest<TRequest, TResponse> = {
    httpMethod?: HttpMethod;
    path: string;
    data?: TRequest;
    formData?: TRequest;
    responseType?: ResponseType;
};

const createHttpClient = (config: AxiosRequestConfig): AxiosInstance => {
    const instance = axios.create(config);
    instance?.interceptors.response.use(undefined, addErrorInterceptor);
    return instance;
};

const httpClient = createHttpClient({
    headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache'
    },
    baseURL: `${configuration.api.baseUrl}/api`
});

const getRequestData = <TRequest, TResponse>(
    request: ApiRequest<TRequest, TResponse>
): TRequest | FormData | undefined => {
    if (!request.formData) {
        return request.data;
    }

    const formData = new FormData();
    Object.entries(request.formData).forEach(([key, value]) => {
        if (value instanceof Blob) {
            formData?.append(key, value!);
        } else {
            formData?.set(key, value!);
        }
    });
    return formData;
};

const fetchApiRequest = async <TRequest, TResponse>(
    request: ApiRequest<TRequest, TResponse>
): Promise<TResponse> => {
    const { data } = await httpClient.request<TResponse>({
        method: request.httpMethod,
        url: `/${request.path}`,
        data: getRequestData(request),
        responseType: request.responseType
    });
    return data;
};

const get = async <T>(url: string, config: AxiosRequestConfig): Promise<T> => {
    const { data } = await httpClient.get<T>(`/${url}`, config);
    return data;
};

export { fetchApiRequest, get };
