import {
    MutateOptions,
    UseMutateAsyncFunction,
    useMutation,
    UseMutationResult
} from 'react-query';
import { ApiRequest, fetchApiRequest } from 'services/api/http/axios-client';
import { HttpError } from 'services/api/http/http-error';

export type UseMutation<TData, TError, TVariables, TContext> = {
    throwableMutateAsync: UseMutateAsyncFunction<
        TData,
        TError,
        TVariables,
        TContext
    >;
    mutateAsync: (
        variables: TVariables,
        options?: MutateOptions<TData, TError, TVariables, TContext>
    ) => Promise<TData | undefined>;
} & Omit<UseMutationResult<TData, TError, TVariables, TContext>, 'mutateAsync'>;

const useMutationVirtualClinic = <TRequest, TResponse>(
    apiRequest: (request: TRequest) => ApiRequest<TRequest, TResponse>
): UseMutation<TResponse, HttpError, TRequest, unknown> => {
    const mutation = useMutation<TResponse, HttpError, TRequest>(
        (request: TRequest) => fetchApiRequest(apiRequest(request))
    );

    const mutateAsync = async (
        variables: TRequest,
        options?: MutateOptions<TResponse, HttpError, TRequest>
    ): Promise<TResponse | undefined> => {
        try {
            return await mutation.mutateAsync(variables, options);
        } catch (err) {
            return undefined;
        }
    };

    return {
        ...mutation,
        throwableMutateAsync: mutation.mutateAsync,
        mutateAsync
    };
};

export { useMutationVirtualClinic };
