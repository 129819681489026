import styled from '@emotion/styled';
import BgErrorPage from 'assets/images/error-page.png';
import { routes } from 'modules/routes/routes-config';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { rem } from 'styles/utils/sizes';
import { getCountryFromUrl } from 'utils/url-utils';
import { Translate } from '../shared/translate';

const ErrorPageWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
`;

const ErrorPageContainer = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        align-items: flex-start;
        color: ${colors.primary.brand};
        display: flex;
        text-align: left;
    `}
`;

const ErrorPageImage = styled.img`
    opacity: 0.7;
`;

const ErrorPageTitle = styled.h2`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('L')};
        margin-bottom: ${rem(12)};
        max-width: ${rem(320)};
    `}
`;

const ErrorPageText = styled.p`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont }
            }
        }
    }) => `
        ${getTextsConfig('L')};
        font-family: ${lightFont};
    `}
`;

type ErrorPageProps = {
    resetError: () => void;
    title?: string;
    description?: string;
};

const ErrorPage = ({
    title = 'error.default.title',
    description = 'error.default.description',
    resetError
}: ErrorPageProps) => {
    const { listen } = useHistory();
    const [error, setError] = useState<boolean>(false);
    const defaultCountry: string = useSelector((state: any) => {
        return state.app.defaultCountry;
    });
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });

    useEffect(() => {
        const unlisten = listen(() => {
            resetError();
        });

        return () => unlisten();
    }, []);

    useEffect(() => {
        const country = getCountryFromUrl();

        if (
            window.location.pathname === '' ||
            window.location.pathname === '/'
        ) {
            window.location.href = `/${defaultCountry}/home?area=home`;
        } else if (!country) {
            console.log('no country, redirecting...');
            window.location.href = `/${defaultCountry}${window.location.pathname}${window.location.search}`;
        } else if (!supportedCountries.includes(country)) {
            console.log('unsupported country, redirecting...');
            const pathnameWithDefaultCountry = window.location.pathname.replace(
                country,
                defaultCountry
            );
            window.location.href = `${window.location.origin}${pathnameWithDefaultCountry}${window.location.search}`;
        } else if (
            window.location.pathname.split('/').length === 2 &&
            supportedCountries.includes(country)
        ) {
            console.log('redirecting to home...');
            window.location.href = `/${country}/home?area=home`;
        } else if (
            window.location.pathname.includes(
                routes.virtualClinicRoute.getRoute(country, supportedCountries)
            )
        ) {
            console.log('redirecting from virtualclinic to home...');
            window.location.href = `/${country}/home?area=home`;
        } else {
            setError(true);
        }
    }, []);

    if (error) {
        return (
            <ErrorPageWrapper>
                <ErrorPageContainer>
                    <ErrorPageImage src={BgErrorPage} />
                    <div>
                        <ErrorPageTitle>
                            <Translate i18nKey={title} />
                        </ErrorPageTitle>
                        <ErrorPageText>
                            <Translate i18nKey={description} />
                        </ErrorPageText>
                    </div>
                </ErrorPageContainer>
            </ErrorPageWrapper>
        );
    } else {
        return null;
    }
};

export { ErrorPage };
