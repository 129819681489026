import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { ModalHotspotExtraVideo } from './modal-hotspot-extra-video.tsx/modal-hotspot-extra-video';

const HotspotTitleWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: ${rem(75)} ${rem(23)} ${rem(44)} ${rem(50)};
`;

const HotspotTitle = styled.h2`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('L')};
        margin-bottom: ${rem(20)};
        word-break: normal;
    `}
`;

const HotspotTitleDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        font-family: ${lightFont};
        margin-bottom: auto;
    `}
`;

type ModalHotspotTitleProps = {
    title: string;
    description: string;
    hasExtraVideo?: boolean;
};

const ModalHotspotTitle: React.FC<ModalHotspotTitleProps> = ({
    title,
    description,
    hasExtraVideo
}: ModalHotspotTitleProps) => {
    return (
        <HotspotTitleWrapper>
            <HotspotTitle>{title}</HotspotTitle>
            <HotspotTitleDescription>{description}</HotspotTitleDescription>
            {hasExtraVideo ? <ModalHotspotExtraVideo /> : null}
        </HotspotTitleWrapper>
    );
};

export { ModalHotspotTitle };
