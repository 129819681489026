import React from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

type TranslateProps = {
    i18nKey: string;
};

const Translate = ({ i18nKey }: TranslateProps) => {
    const { t } = useTranslation();
    return <Trans t={t} i18nKey={i18nKey} />;
};

export { Translate };
