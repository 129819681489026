import { get } from 'services/api/http/axios-client';
import { Subtopic } from 'domain/subtopic';
import { getCountryFromUrl } from '../../../../../utils/url-utils';
import i18n from 'services/i18n';
import { RoomsConfiguration } from 'domain/room';
import { HotspotContent3DModel } from 'domain/hotspot';

export const subtopicRepositoryImplementation = {
    getSubtopics: async (topicId: string) => {
        const country = getCountryFromUrl();

        const data = await get<Subtopic[]>(
            `${country}/topics/${topicId}/subtopics`,
            {
                headers: { 'Accept-Language': i18n.language }
            }
        ).catch();

        return data as Subtopic[];
    },
    getHotspots: async (
        topicId: string,
        area: { areaId: keyof RoomsConfiguration; roomId: string }
    ) => {
        const country = getCountryFromUrl();
        const data = await get<Subtopic[]>(
            `${country}/topics/${topicId}/subtopics`,
            {
                headers: { 'Accept-Language': i18n.language }
            }
        ).catch();

        const subtopics = data.map((s) => ({
            areaId: area.areaId,
            roomId: area.roomId,
            contentId: s.name,
            title: s.nameLanguage,
            hasTitle: !!s.nameLanguage,
            description: s.descriptionLanguage,
            position: {
                ...s.position,
                z: 0
            },
            survey: s.survey,
            contents: s.contents.map((c) => ({
                id: c.nameLanguage,
                kind: c.kind,
                contentUrl: c.url,
                title: c.nameLanguage,
                thubnailUrl: c.thumbnailUrl
            }))
        }));

        return subtopics as HotspotContent3DModel[];
    }
};
