import { useMemo } from 'react';

import { ClinicArea } from 'domain/clinic-overview';

const useClinicOverviewActiveArea = (areas: ClinicArea[]) => {
    const activeArea = useMemo(
        () => areas.find((area) => area.state.active),
        [areas]
    );

    return { activeArea };
};

export { useClinicOverviewActiveArea };
