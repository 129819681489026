import * as yup from 'yup';

export type Survey = {
    title: number;
    firstName: string;
    lastName: string;
    email: string;
    clinicName: string;
    country: string;
    message: string;
};

const surveyFormSchema: yup.SchemaOf<Survey> = yup
    .object<Record<keyof Survey, yup.AnySchema>>()
    .shape<Record<keyof Survey, yup.AnySchema>>({
        title: yup.number().required(),
        firstName: yup.string().required().max(150),
        lastName: yup.string().required().max(150),
        email: yup.string().email().required(),
        clinicName: yup.string().max(150),
        country: yup.string().max(150),
        message: yup.string().required().max(1000)
    });

export { surveyFormSchema };
