import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { RoomName } from './RoomName';

export const MenuPrimary = styled.div`
    height: 100%;
    transition: all 0.3s ease;

    &.isHidden {
        transform: translateX(-100%);
    }
`;
export const MenuSecondary = styled.div`
    ${() => `
        height: 100%;
        transition: all 0.3s ease;

        &.isVisible {
            transform: translateX(-100%);

            ul {
                animation: showMenu 2s;

                @keyframes showMenu {
                    0%,
                    20% {
                        max-height: 0;
                    }
                    100% {
                        max-height: ${rem(290)};
                    }
                }
            }
        }

        &.isHidden {
            ul {
                max-height: 0;
            }
        }

        ul {
            overflow: hidden;

            > li {
                border-color: transparent;
            }
        }

        ${RoomName} {
            margin-top: ${rem(20)};
        }
    `}
`;
