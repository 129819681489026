import { createFont, Font } from './theme/fonts';

export type ThemeColors = {
    primary: {
        brand: string;
        deep: string;
        efective: string;
        medical: string;
    };
    secondary: {
        deep: string;
        soft: string;
    };
    navigation: {
        doctorOffice: string;
        ckd: string;
        homeDialysis: string;
        hemodialysis: string;
        criticalCare: string;
    };
    neutral: {
        grey: string;
        warmGrey: string;
        fullLight: string;
    };
    system: {
        success: string;
        error: string;
    };
    layout: {
        background: string;
    };
};

export type Theme = {
    base: {
        colors: ThemeColors;
        fonts: Font;
        layout: {
            header: number;
        };
        breakpoints: {
            default: number;
            large: number;
        };
        zindex: {
            spinner: number;
            fullScreen: number;
            toast: number;
            tooltip: number;
            modalContent: number;
            modalOverlay: number;
            super: number;
            above: number;
            XL: number;
            L: number;
            M: number;
            S: number;
            XS: number;
            default: number;
            below: number;
        };
    };
};

const theme: Theme = {
    base: {
        colors: {
            primary: {
                brand: '#0033A0',
                deep: '#071B45',
                efective: '#377CB5',
                medical: '#28AECA'
            },
            secondary: {
                deep: '#00867D',
                soft: '#75B94C'
            },
            navigation: {
                doctorOffice: '',
                ckd: '#28AECA',
                homeDialysis: '#071B45',
                hemodialysis: '#00867D',
                criticalCare: '#75B94C'
            },
            neutral: {
                grey: '#A8A39D',
                warmGrey: '#EEEDEB',
                fullLight: '#ffffff'
            },
            system: {
                success: '#75B94C',
                error: '#F66F6F'
            },
            layout: {
                background: '#F0F1F7'
            }
        },
        fonts: createFont(
            "'Helvetica Neue LT Pro 75 Bold'",
            "'Helvetica Neue LT Pro 45 Light'",
            {
                headline: {
                    XL: 45,
                    L: 36,
                    M: 24,
                    S: 18,
                    XS: 14
                },
                lineHeightHeadline: {
                    XL: 54,
                    L: 43,
                    M: 29,
                    S: 22,
                    XS: 17
                },
                text: {
                    L: 24,
                    ML: 20,
                    M: 18,
                    S: 16,
                    XS: 14
                },
                lineHeightText: {
                    L: 29,
                    ML: 24,
                    M: 22,
                    S: 22,
                    XS: 17
                },
                button: {
                    L: 20,
                    M: 16
                },
                lineHeightButton: {
                    L: 24,
                    M: 19
                }
            }
        ),
        layout: {
            header: 40
        },
        breakpoints: {
            default: 1920,
            large: 2560
        },
        zindex: {
            spinner: 9999,
            fullScreen: 9996,
            toast: 9005,
            tooltip: 9004,
            modalContent: 9003,
            modalOverlay: 9002,
            super: 9000,
            above: 900,
            XL: 40,
            L: 30,
            M: 20,
            S: 10,
            XS: 5,
            default: 1,
            below: -1
        }
    }
};

export default theme;
