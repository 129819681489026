import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultModal } from '../../../default/default-modal';
import { ModalVideo } from '../../modal-video';

const ModalHotspotExtraVideo = () => {
    const [showVideoModal, setShowVideoModal] = useState<boolean>();
    const { t } = useTranslation();

    return (
        <>
            {showVideoModal ? (
                <DefaultModal
                    bgStyle='dark'
                    modalSize='medium'
                    isBgBlur={true}
                    onClose={() => setShowVideoModal(false)}
                >
                    <ModalVideo
                        videoSrc='https://vclincprodams-euwe.streaming.media.azure.net/7feef6fe-fe04-40b9-99cf-ecc5f7a2b1dc/VC_overall_Connected-Kidney-Care.ism/manifest(format=mpd-time-cmaf)'
                        title={t('hotspots.extra-video.title')}
                        description={t('hotspots.extra-video.description')}
                        btnLabel={t(`areas.home.modal.btnLabel`)}
                        onClick={() => setShowVideoModal(false)}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export { ModalHotspotExtraVideo };
