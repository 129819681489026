import { createContext, useReducer, Dispatch } from 'react';

import { Actions, State } from './app.model';
import { initialValues, reducer } from './app.reducer';

const AppContext = createContext<{ state: State; dispatch: Dispatch<Actions> }>(
    { state: initialValues, dispatch: () => null }
);

const AppProvider: React.FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialValues);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider };
