import React from 'react';
import styled from '@emotion/styled';

import { rem } from 'styles/utils/sizes';
import { ClinicArea } from 'domain/clinic-overview';
import { useClinicOverviewArea } from 'modules/context/clinic-overview';

type ClinicAreaImgProps = {
    applyFilter: boolean;
};

const ClinicAreaImg = styled.img<ClinicAreaImgProps>`
    ${({ applyFilter }) => `
        margin-top: 0;
        position: absolute;
        z-index: -1;

        ${applyFilter && 'filter: grayscale(1) brightness(1.3) contrast(0.7);'};

        &[data-key='hemodialysis'] {
            animation: translateHemodialysis 3s ease;
        }

        &[data-key='criticalCare'] {
            animation: translateCriticalCare 3s ease;
        }

        &[data-key='roof'] {
            animation: translateRoof 3s ease;
        }

        @keyframes translateHemodialysis {
            0%,
            70% {
                margin-top: ${rem(70)};
            }
            100% {
                margin-top: 0;
            }
        }

        @keyframes translateCriticalCare {
            0%,
            70% {
                margin-top: ${rem(140)};
            }
            100% {
                margin-top: 0;
            }
        }

        @keyframes translateRoof {
            0%,
            70% {
                margin-top: ${rem(210)};
            }
            100% {
                margin-top: 0;
            }
        }
    `}
`;

type ClinicAreaHighlightImgProps = {
    show: boolean;
};

const ClinicAreaHighlightImg = styled.img<ClinicAreaHighlightImgProps>`
    ${({ show }) => `
        display: ${show ? 'block' : 'none'};
        position: absolute;
        z-index: -1;
    `}
`;

type ClinicAreasLayerProps = {
    areas: ClinicArea[];
    activeAreaIndex: number;
};

const ClinicAreasLayer = ({
    areas,
    activeAreaIndex
}: ClinicAreasLayerProps) => {
    const { applyCSSTransformations, applyCSSFilter, showHighlighted } =
        useClinicOverviewArea({ areas, activeAreaIndex });

    return (
        <>
            {areas.map((area, areaIndex) =>
                area.paintingInfo.map((info, index) => {
                    const { applyImageCSS, applyHighlightCSS } =
                        applyCSSTransformations(areaIndex, info);

                    return (
                        <React.Fragment key={`${area.id}-${index}`}>
                            <ClinicAreaImg
                                data-key={`${area.id}`}
                                src={info.base.imagePath}
                                style={{
                                    ...info.base.imagePosition,
                                    ...applyImageCSS
                                }}
                                applyFilter={applyCSSFilter(areaIndex)}
                            />
                            {info.highlight && (
                                <ClinicAreaHighlightImg
                                    src={info.highlight.imagePath}
                                    style={{
                                        ...info.highlight.imagePosition,
                                        ...applyHighlightCSS
                                    }}
                                    show={showHighlighted(area)}
                                />
                            )}
                        </React.Fragment>
                    );
                })
            )}
        </>
    );
};

export { ClinicAreasLayer };
