const roomRoute = `/:country()/:areaId/room/:roomId`;
const modalRoute = `/:country()/:areaId/room/:roomId/:hotspotId/modal`;
const clinicOverviewRoute = `/:country()/clinic-overview`;
const homeRoute = `/:country()/home`;
const doctorOfficeRoute = '/:country()/doctor-office/:areaId';
const doctorOfficeModalRoute = `/:country()/doctor-office/:areaId/:hotspotId/modal`;
const virtualClinicRoute = `/:country()/virtualclinic`;

const routes = {
    home: {
        getStaticRoute: (supportedCountries: string[]) =>
            homeRoute.replace('()', `(${supportedCountries.join('|')})`),
        title: 'routes.home',
        getRoute: (
            area: string,
            country: string,
            supportedCountries: string[]
        ) =>
            `${homeRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(
                    `:country(${supportedCountries.join('|')})`,
                    country
                )}?area=${area}`
    },
    clinicOverview: {
        getStaticRoute: (supportedCountries: string[]) =>
            clinicOverviewRoute.replace(
                '()',
                `(${supportedCountries.join('|')})`
            ),
        getRoute: (
            country: string,
            supportedCountries: string[],
            area?: string
        ) =>
            `${clinicOverviewRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(
                    `:country(${supportedCountries.join('|')})`,
                    country
                )}${area ? `?area=${area}` : ''}`,
        title: 'routes.clinic-overview'
    },
    room: {
        getStaticRoute: (supportedCountries: string[]) =>
            roomRoute.replace('()', `(${supportedCountries.join('|')})`),
        title: 'routes.room3d',
        getRoute: (
            areaId: string,
            roomId: string,
            country: string,
            supportedCountries: string[]
        ) =>
            roomRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(`:country(${supportedCountries.join('|')})`, country)
                .replace(':areaId', areaId)
                .replace(':roomId', roomId)
    },
    modal3DHotspot: {
        getStaticRoute: (supportedCountries: string[]) =>
            modalRoute.replace('()', `(${supportedCountries.join('|')})`),
        title: 'routes.room3d',
        getRoute: (
            areaId: string,
            roomId: string,
            hotspotId: string,
            country: string,
            supportedCountries: string[]
        ) =>
            modalRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(`:country(${supportedCountries.join('|')})`, country)
                .replace(':areaId', areaId)
                .replace(':roomId', roomId)
                .replace(':hotspotId', hotspotId)
    },
    doctorOffice: {
        getStaticRoute: (supportedCountries: string[]) =>
            doctorOfficeRoute.replace(
                '()',
                `(${supportedCountries.join('|')})`
            ),
        title: 'routes.doctor-office',
        getRoute: (
            area: string,
            country: string,
            supportedCountries: string[]
        ) =>
            doctorOfficeRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(`:country(${supportedCountries.join('|')})`, country)
                .replace(':areaId', area)
    },
    doctorOfficeModal: {
        getStaticRoute: (supportedCountries: string[]) =>
            doctorOfficeModalRoute.replace(
                '()',
                `(${supportedCountries.join('|')})`
            ),
        title: 'routes.doctor-office',
        getRoute: (
            area: string,
            hotspotId: string,
            country: string,
            supportedCountries: string[]
        ) =>
            doctorOfficeModalRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(`:country(${supportedCountries.join('|')})`, country)
                .replace(':areaId', area)
                .replace(':hotspotId', hotspotId)
    },
    virtualClinicRoute: {
        getStaticRoute: (supportedCountries: string[]) =>
            virtualClinicRoute.replace(
                '()',
                `(${supportedCountries.join('|')})`
            ),
        title: 'routes.virtualclinic',
        getRoute: (country: string, supportedCountries: string[]) =>
            `${virtualClinicRoute
                .replace('()', `(${supportedCountries.join('|')})`)
                .replace(`:country(${supportedCountries.join('|')})`, country)}`
    }
};

export { routes };
