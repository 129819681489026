import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { getAreasIdFromConfig, getAreaColorFromTheme } from 'domain/room';
import { useTracker } from 'hooks/use-tracker';
import { routes } from 'modules/routes/routes-config';
import { useClickOutsideAction } from 'hooks/use-click-outside-action';
import {
    useClinicOverviewContext,
    useClinicOverviewMenu
} from 'modules/context/clinic-overview';

import { Translate } from '../translate';
import { ButtonSecondary } from '../button/button';
import { ButtonIconSecondary } from '../button-icon/button-icon';
import { Therapy } from 'domain/therapy';
import { Topic } from 'domain/topic';
import { getCountryFromUrl } from 'utils/url-utils';
import { useSelector } from 'react-redux';
import { MenuHeaderWrapper } from './MenuHeaderWrapper';
import { NavListItem } from './NavListItem';
import { DoctorOfficeListItem } from './DoctorOfficeListItem';
import { RoomName } from './RoomName';
import { MenuPrimary, MenuSecondary } from './MenuPrimary';
import { DoctorOfficeHeader } from './DoctorOfficeHeader';
import { MenuWrapper, MenuHeader } from './MenuWrapper';

export type MenuProps = {
    openMenu?: boolean;
};

const Menu: React.FC<MenuProps> = ({ openMenu = false }: MenuProps) => {
    const { state } = useClinicOverviewContext();
    const [isShowSubmenu, setShowSubmenu] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>();
    const [isShowMenu, setShowMenu] = useState<boolean>();
    const { setDataForTracker } = useTracker();
    const theme = useTheme();
    const supportedCountries: string[] = useSelector((state: any) => {
        return state.app.supportedCountries;
    });
    const clinicOverviewMenu = useClinicOverviewMenu();

    const onMenuGoBack = useCallback(() => {
        setSelectedOption(undefined);
        clinicOverviewMenu.onMenuGoBack();
    }, []);

    const onMenuOptionClicked = useCallback((areaId: string) => {
        setSelectedOption(areaId);
        clinicOverviewMenu.onMenuOptionClicked(areaId);
    }, []);

    useEffect(() => setShowMenu(openMenu), [openMenu]);

    useEffect(() => setShowSubmenu(Boolean(selectedOption)), [selectedOption]);

    useEffect(() => {
        const areas = getAreasIdFromConfig();
        const areaId = clinicOverviewMenu.activeArea?.id;

        if (areaId && areas.includes(areaId)) setSelectedOption(areaId);
    }, [clinicOverviewMenu.activeArea]);

    const [containerRef] = useClickOutsideAction<HTMLDivElement>(
        () => !openMenu && setShowMenu(false)
    );

    const country = getCountryFromUrl();

    return (
        <>
            {supportedCountries.length > 0 && (
                <>
                    <div ref={containerRef}>
                        <ButtonIconSecondary
                            iconName='menu'
                            onClick={() => setShowMenu(!isShowMenu)}
                        />
                        {isShowMenu && (
                            <MenuHeaderWrapper>
                                <MenuWrapper>
                                    <MenuPrimary
                                        className={
                                            isShowSubmenu
                                                ? 'isHidden'
                                                : 'isVisible'
                                        }
                                    >
                                        {country !== 'us' && (
                                            <DoctorOfficeHeader>
                                                <ul>
                                                    <DoctorOfficeListItem>
                                                        <NavLink
                                                            exact
                                                            to={routes.doctorOffice.getRoute(
                                                                'doctorOffice',
                                                                country,
                                                                supportedCountries
                                                            )}
                                                        >
                                                            <Translate i18nKey='areas.doctorOffice.title' />
                                                        </NavLink>
                                                    </DoctorOfficeListItem>
                                                    <DoctorOfficeListItem>
                                                        <NavLink
                                                            exact
                                                            to={routes.doctorOfficeModal.getRoute(
                                                                'doctorOffice',
                                                                'quickTour',
                                                                country,
                                                                supportedCountries
                                                            )}
                                                        >
                                                            <Translate i18nKey='menu.quickTour' />
                                                        </NavLink>
                                                    </DoctorOfficeListItem>
                                                    <DoctorOfficeListItem>
                                                        <NavLink
                                                            exact
                                                            to={routes.doctorOfficeModal.getRoute(
                                                                'doctorOffice',
                                                                'gameBreak',
                                                                country,
                                                                supportedCountries
                                                            )}
                                                        >
                                                            <Translate i18nKey='menu.gameBreak' />
                                                        </NavLink>
                                                    </DoctorOfficeListItem>
                                                </ul>
                                            </DoctorOfficeHeader>
                                        )}
                                        <ul>
                                            {state.therapies.map(
                                                (therapy: Therapy) => (
                                                    <NavListItem
                                                        {...setDataForTracker({
                                                            category: 'menu',
                                                            action: therapy.name
                                                        })}
                                                        key={therapy.name}
                                                        highlighted={
                                                            clinicOverviewMenu
                                                                .highlightedArea
                                                                ?.id ===
                                                            therapy.name
                                                        }
                                                        color={getAreaColorFromTheme(
                                                            theme,
                                                            therapy.name
                                                        )}
                                                        onClick={() =>
                                                            onMenuOptionClicked(
                                                                therapy.name
                                                            )
                                                        }
                                                        onMouseEnter={() =>
                                                            clinicOverviewMenu.onMenuOptionHighlighted(
                                                                therapy.name
                                                            )
                                                        }
                                                        onMouseLeave={() =>
                                                            clinicOverviewMenu.onMenuOptionHighlighted(
                                                                null
                                                            )
                                                        }
                                                    >
                                                        <p>
                                                            {
                                                                therapy.nameLanguage
                                                            }
                                                        </p>
                                                    </NavListItem>
                                                )
                                            )}
                                        </ul>
                                    </MenuPrimary>

                                    <MenuSecondary
                                        className={
                                            selectedOption
                                                ? 'isVisible'
                                                : 'isHidden'
                                        }
                                    >
                                        <MenuHeader>
                                            <ButtonSecondary
                                                {...setDataForTracker({
                                                    category: 'menu',
                                                    action: 'back'
                                                })}
                                                btnSize='mini'
                                                iconName='arrow-left'
                                                onClick={onMenuGoBack}
                                            >
                                                <Translate i18nKey='menu.back' />
                                            </ButtonSecondary>
                                            <NavLink
                                                to={routes.clinicOverview.getRoute(
                                                    country,
                                                    supportedCountries,
                                                    selectedOption!
                                                )}
                                            >
                                                <RoomName>
                                                    {
                                                        state.therapies.find(
                                                            (th) =>
                                                                th.name ===
                                                                selectedOption
                                                        )?.nameLanguage
                                                    }
                                                </RoomName>
                                            </NavLink>
                                        </MenuHeader>
                                        <ul>
                                            {selectedOption
                                                ? state.topics
                                                      .filter(
                                                          (topic: Topic) =>
                                                              topic.therapyName ===
                                                              selectedOption
                                                      )
                                                      .map((topic) => (
                                                          <NavLink
                                                              key={topic.id}
                                                              to={routes.room.getRoute(
                                                                  selectedOption,
                                                                  topic.name,
                                                                  country,
                                                                  supportedCountries
                                                              )}
                                                          >
                                                              <NavListItem
                                                                  {...setDataForTracker(
                                                                      {
                                                                          category:
                                                                              'menu',
                                                                          action: topic.name
                                                                      }
                                                                  )}
                                                                  highlighted={
                                                                      false
                                                                  }
                                                                  color={getAreaColorFromTheme(
                                                                      theme,
                                                                      selectedOption!
                                                                  )}
                                                                  withBorder={
                                                                      false
                                                                  }
                                                              >
                                                                  <p>
                                                                      {
                                                                          topic.nameLanguage
                                                                      }
                                                                  </p>
                                                              </NavListItem>
                                                          </NavLink>
                                                      ))
                                                : null}
                                        </ul>
                                    </MenuSecondary>
                                </MenuWrapper>
                            </MenuHeaderWrapper>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export { Menu };
