import styled from '@emotion/styled';
import { HotspotContentSurveyOptions } from 'domain/hotspot';
import { forwardRef, InputHTMLAttributes, Ref } from 'react';
import { rem } from 'styles/utils/sizes';

const InputRadioWrapper = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    position: relative;
`;

const InputRadioLabel = styled.label`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig, lightFont }
            }
        }
    }) => `
        ${getTextsConfig('S')};
        font-family: ${lightFont};

        &:hover:not(:checked) {
            cursor: pointer;
        }
    `}
`;

const InputRadioBox = styled.div<{ hasError: boolean }>`
    ${({
        theme: {
            base: { zindex, colors }
        },
        hasError
    }) => `
        align-items: center;
        border: ${rem(2)} solid ${hasError ? colors.system.error : ''};
        box-sizing: border-box;
        ${hasError ? `color: ${colors.system.error};` : ''}
        display: inline-flex;
        min-height: ${rem(40)};
        padding: ${rem(2)} ${rem(20)};
        width: 100%;
        z-index: ${zindex.default};
    `}
`;

const InputRadio = styled.input`
    ${({
        theme: {
            base: {
                colors,
                fonts: { boldFont },
                zindex
            }
        }
    }) => `
        appearance: none;
        border: 0 none;
        border-radius: 0;
        height: 100%;
        left: 0;
        margin: 0;
        outline: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: ${zindex.XS};

        &:hover:not(:checked) {
            cursor: pointer;
        }

        &:hover,
        &:checked {
            ~ ${InputRadioBox} {
                background-color: ${colors.primary.deep};
                border-color: ${colors.primary.deep};

                ${InputRadioLabel} {
                    color: ${colors.neutral.fullLight};
                }
            }
        }

        &:checked {
            ~ ${InputRadioBox} {
                ${InputRadioLabel} {
                    font-family: ${boldFont};
                }
            }
        }
    `}
`;

type SatisfactionInputRadioProps = {
    satisfactionType: keyof HotspotContentSurveyOptions;
    description: string;
    hasError: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const SatisfactionInputRadio = forwardRef(
    (
        {
            satisfactionType,
            description,
            hasError,
            ...rest
        }: SatisfactionInputRadioProps,
        ref: Ref<HTMLInputElement>
    ) => (
        <InputRadioWrapper>
            <InputRadio
                ref={ref}
                id={satisfactionType}
                type='radio'
                value={satisfactionType}
                {...rest}
            />
            <InputRadioBox hasError={hasError}>
                <InputRadioLabel htmlFor={satisfactionType}>
                    {description}
                </InputRadioLabel>
            </InputRadioBox>
        </InputRadioWrapper>
    )
);

export { SatisfactionInputRadio };
