import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Translate } from 'modules/app/shared/translate';

const HelpPanelWrapper = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        background-color: ${colors.neutral.fullLight};
        box-sizing: border-box;
        color: ${colors.primary.deep};
        padding: ${rem(75)} ${rem(40)} ${rem(75)} ${rem(50)};
        width: 100%;
    `}
`;

const HelpPanelTitle = styled.h2`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('L')};
        margin-bottom: ${rem(40)};
    `}
`;

const HelpPanelList = styled.ul`
    list-style-type: decimal;
    margin-left: ${rem(22)};
`;

const HelpPanelItemDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        font-family: ${lightFont};
        margin-bottom: ${rem(30)};
    `}
`;

const HelpPanel = () => {
    return (
        <HelpPanelWrapper>
            <HelpPanelTitle>
                <Translate i18nKey='help.title' />
            </HelpPanelTitle>
            <HelpPanelList>
                <li>
                    <HelpPanelItemDescription>
                        <Translate i18nKey='help.panel.stepOne' />
                    </HelpPanelItemDescription>
                </li>
                <li>
                    <HelpPanelItemDescription>
                        <Translate i18nKey='help.panel.stepTwo' />
                    </HelpPanelItemDescription>
                </li>
            </HelpPanelList>
        </HelpPanelWrapper>
    );
};

export { HelpPanel };
