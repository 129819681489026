import { useEffect, useRef } from 'react';

const useClickOutsideAction = <TRef extends Element>(
    action: (event: MouseEvent) => void
) => {
    const actionRef = useRef(action);
    const ref = useRef<TRef>(null);

    useEffect(() => {
        actionRef.current = action;
    }, [action]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                actionRef.current?.(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return [ref];
};

export { useClickOutsideAction };
