import { useCallback } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

type DataCategoryForTracking = 'menu' | 'hotspot' | 'area' | 'form';

type DataForTracking = {
    category: DataCategoryForTracking;
    action: string;
};

const useTracker = () => {
    const dataAttributePrefix = 'data-tracker';

    const { trackEvent } = useMatomo();

    const setDataForTracker = useCallback((data: DataForTracking) => {
        return Object.entries(data).reduce((prev, [key, value]) => {
            return { ...prev, [`${dataAttributePrefix}-${key}`]: value };
        }, {});
    }, []);

    return { setDataForTracker, trackEvent };
};

export { useTracker };
