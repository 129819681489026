import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const DoctorOfficeHeader = styled.header`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        border-bottom: ${rem(1)} solid ${colors.neutral.grey};
        border-top: ${rem(1)} solid ${colors.neutral.grey};
        margin: ${rem(16)} ${rem(40)} ${rem(20)} ${rem(40)};
        padding: ${rem(16)} 0;
    `}
`;
