import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const DoctorOfficeListItem = styled.li`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getTextsConfig }
            }
        }
    }) => `
        color: ${colors.neutral.grey};
        ${getTextsConfig('XS')};

        &:hover {
            color: ${colors.primary.brand};
            cursor:pointer;
        }

        &:not(:last-of-type) {
            margin-bottom: ${rem(10)};
        }

        &:first-of-type {
            ${getTextsConfig('S')};
        }

        > a {
            display: block;
        }
    `}
`;
