import { useState, PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { PanoramicNavigationWrapper } from 'modules/app/shared/viewer/navigation/panoramic-navigation';
import { rem } from 'styles/utils/sizes';
import { Translate } from '../../translate';
import { DefaultModal } from '../default/default-modal';
import { FeedbackForm } from './modal-hotspot/feedback-form';
import { ButtonBase, ButtonPrimary } from '../../button/button';
import { RequestInfoForm } from './modal-hotspot/request-info-form';
import { ModalHotspotTitle } from './modal-hotspot/modal-hotspot-title';
import { ModalHotspotContent } from './modal-hotspot/modal-hotspot-content';
import { HotspotContent3DModel } from 'domain/hotspot';

const ModalHotspotWrapper = styled.div`
    align-items: flex-start;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 100%);
    overflow: hidden;
`;

const TITLEBOXSIZE = 355;

const HotspotOverviewWrapper = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        display: grid;
        grid-template-columns: ${rem(TITLEBOXSIZE)} auto ${rem(400)};
        transition: all ease 0.5s;

        &:after {
            background-color: ${colors.primary.brand};
            content: '';
            height: ${rem(61)};
            grid-column-end: 4;
            grid-column-start: 1;
        }

        > ${PanoramicNavigationWrapper} {
            position: absolute;
        }

        &.isVisible {
            transform: translateX(0);
        }

        &.isHidden {
            transform: translateX(-100%);
        }
    `}
`;

const FeedbackFormContent = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        background-color: ${colors.neutral.warmGrey};
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: ${rem(70)} ${rem(54)} ${rem(58)} ${rem(46)};
        position: relative;

        > ${ButtonBase} {
            height: ${rem(50)};
            margin-top: auto;
        }
    `}
`;

const RequestInformationWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    padding: ${rem(75)} ${rem(50)};
    transition: all ease 0.5s;

    &.isVisible {
        transform: translateX(-100%);
    }

    &.isHidden {
        transform: translateX(0);
    }
`;

export type ModalHotspotProps = {
    hotspotContent: HotspotContent3DModel;
    therapyAlphaNumericId?: string;
    onClose: () => void;
};

const ModalHotspot = ({
    hotspotContent: {
        areaId,
        roomId,
        contentId,
        title,
        description,
        contents,
        survey
    },
    therapyAlphaNumericId,
    onClose
}: PropsWithChildren<ModalHotspotProps>) => {
    const [showRequestForm, setShowRequestForm] = useState<boolean>(false);

    return (
        <DefaultModal
            onClose={onClose}
            bgStyle='dark'
            modalSize='fullWidth'
            originModal='left'
            isBgBlur={false}
            showCloseBtn={true}
        >
            <ModalHotspotWrapper>
                <HotspotOverviewWrapper
                    className={showRequestForm ? 'isHidden' : 'isVisible'}
                >
                    <ModalHotspotTitle
                        title={title}
                        description={description}
                    />
                    <ModalHotspotContent
                        contents={contents}
                        therapyAlphaNumericId={therapyAlphaNumericId}
                        onClose={onClose}
                    />
                    <FeedbackFormContent>
                        <FeedbackForm
                            areaId={areaId}
                            roomId={roomId}
                            hotspotId={contentId}
                            survey={survey}
                        />

                        <ButtonPrimary
                            btnSize='fullWidth'
                            btnAlign='left'
                            iconName='info-circle'
                            onClick={() => setShowRequestForm(true)}
                        >
                            <Translate i18nKey='contactForm.requestInfo.button' />
                        </ButtonPrimary>
                    </FeedbackFormContent>
                </HotspotOverviewWrapper>
                <RequestInformationWrapper
                    className={showRequestForm ? 'isVisible' : 'isHidden'}
                >
                    <RequestInfoForm
                        areaId={areaId}
                        roomId={roomId}
                        hotspotId={contentId}
                        onClickPrev={() => setShowRequestForm(false)}
                    />
                </RequestInformationWrapper>
            </ModalHotspotWrapper>
        </DefaultModal>
    );
};

export { ModalHotspot };
