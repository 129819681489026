import { get } from 'services/api/http/axios-client';
import { Topic } from 'domain/topic';
import { getCountryFromUrl } from '../../../../../utils/url-utils';
import i18n from 'services/i18n';

export const topicRepositoryImplementation = {
    getTopics: async () => {
        const country = getCountryFromUrl();

        const data = await get<Topic[]>(`${country}/topics/`, {
            headers: { 'Accept-Language': i18n.language }
        }).catch();

        return data as Topic[];
    }
};
