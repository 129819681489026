import { Actions, State } from './app.model';

export const initialValues: State = {
    currentArea: undefined,
    userId: Math.random().toString(36).substr(2, 9),
    showTutorial: true,
    country: 'de'
};

export const reducer = (state: State, action: Actions): State => {
    switch (action.type) {
        case 'CHANGE_CURRENT_AREA': {
            const { currentArea } = action.payload;

            return { ...state, currentArea: currentArea };
        }

        case 'SHOW_TUTORIAL': {
            const { showTutorial } = action.payload;

            return { ...state, showTutorial: showTutorial };
        }

        case 'CHANGE_COUNTRY': {
            const { country } = action.payload;

            return { ...state, country: country };
        }

        default:
            return state;
    }
};
