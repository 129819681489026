import styled from '@emotion/styled';
import { Translate } from 'modules/app/shared/translate';
import { rem } from 'styles/utils/sizes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { ButtonPrimary } from 'modules/app/shared/button/button';
import { useMutationVirtualClinic } from 'hooks/use-mutation-virtual-clinic';
import { surveyPost } from 'services/api/mutations/survey-mutations';
import { useTranslation } from 'react-i18next';
import { TextArea } from 'modules/app/shared/form/textarea/text-area';
import { Input } from 'modules/app/shared/form/input/input';
import { GridTemplate } from 'modules/app/shared/grid-template/grid-template';
import { Select } from 'modules/app/shared/form/select/select';
import { SuccessMessage } from 'modules/app/shared/form/messages/success-message';
import { Survey, surveyFormSchema } from 'domain/survey';
import { Spinner } from 'modules/app/shared/spinner/spinner';
import {
    PanoramicNavigation,
    PanoramicNavigationWrapper
} from 'modules/app/shared/viewer/navigation/panoramic-navigation';
import { useContext, useState } from 'react';
import { AppContext } from 'modules/context/app';
import { useTracker } from 'hooks/use-tracker';
import { ModalIframe } from '../modal-iframe';

const RequestInfoFormWrapper = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        ${PanoramicNavigationWrapper} {
            background-color: ${colors.primary.brand};
            bottom: 0;
            height: ${rem(60)};
            left: 0;
            position: absolute;
            width: 100%;
        }
    `}
`;

const RequestInfoFormTitle = styled.h3`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('L')};
        margin-bottom: ${rem(20)};
        max-width: ${rem(270)};
    `}
`;

const RequestInfoFormDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        font-family: ${lightFont};
        margin-bottom: ${rem(50)};
        max-width: ${rem(700)};
    `}
`;

const PrivacyPolicyLink = styled.span`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        color: ${colors.primary.medical};
        cursor: pointer;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    `}
`;

const InfoFormWrapper = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        background-color: ${colors.neutral.warmGrey};
        display: grid;
        gap: ${rem(20)};
        grid-template-columns: ${rem(400)} ${rem(400)} auto;
        margin-bottom: ${rem(20)};
        padding: ${rem(30)};
    `}
`;

type RequestInfoFormProps = {
    areaId: string;
    roomId: string;
    hotspotId: string;
    onClickPrev?: () => void;
};

const RequestInfoForm = ({
    areaId,
    roomId,
    hotspotId,
    onClickPrev
}: RequestInfoFormProps) => {
    const { t } = useTranslation();
    const { state } = useContext(AppContext);
    const { trackEvent } = useTracker();
    const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

    const {
        mutateAsync: createSurvey,
        isSuccess,
        isLoading
    } = useMutationVirtualClinic(surveyPost);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<Survey>({
        resolver: yupResolver(surveyFormSchema)
    });

    const onSubmit = (data: Survey) => {
        trackEvent({
            category: 'form',
            action: 'request-info-form submit'
        });
        createSurvey({
            ...data,
            areaId,
            roomId,
            hotspotId,
            userId: state.userId
        });
    };

    return (
        <RequestInfoFormWrapper>
            <RequestInfoFormTitle>
                <Translate i18nKey='contactForm.requestInfo.title' />
            </RequestInfoFormTitle>
            <RequestInfoFormDescription>
                <Translate i18nKey='contactForm.requestInfo.description' />
                <PrivacyPolicyLink
                    onClick={() => {
                        setShowPrivacyPolicyModal(!showPrivacyPolicyModal);
                    }}
                >
                    <Translate i18nKey='contactForm.requestInfo.privacy-policy' />
                </PrivacyPolicyLink>
                {showPrivacyPolicyModal && (
                    <ModalIframe
                        content='privacyPolicy'
                        onClose={() => {
                            setShowPrivacyPolicyModal(false);
                        }}
                    />
                )}
            </RequestInfoFormDescription>

            {isLoading && <Spinner />}
            {isSuccess ? (
                <SuccessMessage>
                    <Translate i18nKey='surveys.feedback.successMessage' />
                </SuccessMessage>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InfoFormWrapper>
                        <GridTemplate gap={20}>
                            <Select
                                hasError={Boolean(errors.title)}
                                defaultValue=''
                                {...register('title')}
                            >
                                <option value='' disabled hidden>
                                    {t('contactForm.requestInfo.form.title')}
                                </option>
                                <option value='1'>
                                    {t(
                                        'contactForm.requestInfo.form.person-title.mr'
                                    )}
                                </option>
                                <option value='2'>
                                    {t(
                                        'contactForm.requestInfo.form.person-title.mrs'
                                    )}
                                </option>
                                <option value='3'>
                                    {t(
                                        'contactForm.requestInfo.form.person-title.dr'
                                    )}
                                </option>
                                <option value='4'>
                                    {t(
                                        'contactForm.requestInfo.form.person-title.prof'
                                    )}
                                </option>
                            </Select>
                            <Input
                                placeholder={t(
                                    'contactForm.requestInfo.form.firstName'
                                )}
                                {...register('firstName')}
                                hasError={Boolean(errors.firstName)}
                            />
                            <Input
                                placeholder={t(
                                    'contactForm.requestInfo.form.lastName'
                                )}
                                {...register('lastName')}
                                hasError={Boolean(errors.lastName)}
                            />
                        </GridTemplate>
                        <GridTemplate gap={20}>
                            <Input
                                placeholder={t(
                                    'contactForm.requestInfo.form.email'
                                )}
                                {...register('email')}
                                hasError={Boolean(errors.email)}
                            />
                            <Input
                                placeholder={t(
                                    'contactForm.requestInfo.form.clinicHospitalOrganization'
                                )}
                                {...register('clinicName')}
                                hasError={Boolean(errors.clinicName)}
                            />
                            <Input
                                placeholder={t(
                                    'contactForm.requestInfo.form.country'
                                )}
                                {...register('country')}
                                hasError={Boolean(errors.country)}
                            />
                        </GridTemplate>
                        <TextArea
                            hasError={Boolean(errors.message)}
                            placeholder={t(
                                'contactForm.requestInfo.form.message'
                            )}
                            {...register('message')}
                        />
                    </InfoFormWrapper>
                    <ButtonPrimary type='submit'>
                        <Translate i18nKey='generics.submit' />
                    </ButtonPrimary>
                </form>
            )}

            <PanoramicNavigation
                prevLabel={t('hotspots.navigation.contentOverview')}
                onClickPrev={onClickPrev}
                isFontSizeLarge
            />
        </RequestInfoFormWrapper>
    );
};

export { RequestInfoForm };
