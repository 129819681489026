import styled from '@emotion/styled';
import { UIFactory } from 'bitmovin-player-ui';
import Player from 'modules/app/Player';
import { useEffect, useState } from 'react';
require('bitmovin-player-ui/dist/css/bitmovinplayer-ui.min.css');

type IframeProps = {
    src: string;
    thumbnailUrl?: string;
    kind?: string;
    bgColor?: boolean;
    mediaGrants?: boolean;
    height?: string;
    width?: string;
};

type AMPProps = {
    height?: string;
    width?: string;
    poster?: string;
};

const IframeWrapper = styled.iframe<IframeProps>`
    ${({
        theme: {
            base: { colors }
        },
        height,
        width,
        bgColor
    }) => `
    background-color: ${bgColor ? colors.primary.deep : 'transparent'};
    display: block;
    line-height: 0;
    ${height && height};
    ${width && width};
`}
`;

const AMPWrapper = styled.div<AMPProps>`
    ${({ height, width, poster }) => `
    line-height: 0;
    ${height && height};
    ${width && width};
    ${poster};
`}
`;

const Iframe = ({
    src,
    height,
    width,
    kind,
    bgColor,
    mediaGrants = false,
    thumbnailUrl
}: IframeProps) => {
    if (kind === 'Video') {
        return (
            <AMPWrapper>
                <Player
                    id={src}
                    sourceConfig={{
                        hls: src,
                        poster: thumbnailUrl
                    }}
                ></Player>
            </AMPWrapper>
        );
    }

    if (kind === 'Document') {
        return (
            <IframeWrapper
                bgColor={bgColor}
                src={`https://docs.google.com/viewerng/viewer?url=${src}&embedded=true`}
                height={height}
                width={width}
                thumbnailUrl={thumbnailUrl}
            />
        );
    }

    if (kind === 'Game') {
        return (
            <IframeWrapper
                bgColor={bgColor}
                src={src}
                allow={'camera; microphone'}
                thumbnailUrl={thumbnailUrl}
            />
        );
    }

    return (
        <IframeWrapper
            bgColor={bgColor}
            src={src}
            thumbnailUrl={thumbnailUrl}
        />
    );
};

export { Iframe, IframeWrapper };
