import { PropsWithChildren, MouseEvent, ReactElement } from 'react';
import { DefaultModal } from '../../shared/modal/default/default-modal';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Topic } from 'domain/topic';
import { ButtonPrimary } from '../../shared/button/button';
import { useTracker } from 'hooks/use-tracker';
import { useTranslation } from 'react-i18next';

export type TopicSelectorInfo = {
    topic: Topic;
    subTitle: string;
    description: ReactElement;
    areaId: string;
    roomId: string;
};

export type TopicSelectorModalProps = {
    onCancel: () => void;
    onSubmit: (areaId: string, roomId: string) => void;
    topicInfos: TopicSelectorInfo[];
};

const TopicSelectorModal: React.FC<TopicSelectorModalProps> = ({
    onCancel,
    onSubmit,
    topicInfos: topics
}: PropsWithChildren<TopicSelectorModalProps>) => {
    const { setDataForTracker } = useTracker();
    const { t } = useTranslation();

    const renderTopics = topics.map((topicInfo, index) => {
        return (
            <TopicSection>
                <TopicTitle>{topicInfo.topic.nameLanguage}</TopicTitle>
                <TopicSubTitle>{topicInfo.subTitle}</TopicSubTitle>
                <img
                    src={topicInfo.topic.panoramicImageUrl}
                    alt=''
                    className='tablet-img'
                />
                <TopicDescription>{topicInfo.description}</TopicDescription>
                <ButtonPrimary
                    {...setDataForTracker({
                        category: 'hotspot',
                        action: `${topicInfo.areaId}-${topicInfo.roomId}`
                    })}
                    btnSize='fullWidth'
                    iconName='arrow-right'
                    iconPosition='right'
                    btnAlign='between'
                    isFontSizeLarge={true}
                    onClick={(event: MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                        onSubmit(topicInfo.areaId, topicInfo.roomId);
                    }}
                >
                    {t('topics.selectorModal.viewButton') +
                        ' ' +
                        topicInfo.topic.nameLanguage}
                </ButtonPrimary>
            </TopicSection>
        );
    });

    return (
        <DefaultModal
            onClose={() => onCancel()}
            bgStyle='dark'
            originModal='bottom'
            isBgBlur={false}
            showCloseBtn={true}
            fullScreen={true}
            width={1000}
        >
            <ContentWrapper>{renderTopics}</ContentWrapper>
        </DefaultModal>
    );
};

export { TopicSelectorModal };

const ContentWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: ${rem(450)};
    justify-content: center;
    margin: 0 auto;
    position: relative;
    height: 100%;

    .tablet {
        width: ${rem(250)};
    }

    .tap {
        animation-delay: 2s;
        animation: moveTap 6s infinite;
        bottom: 0;
        left: 0;
        position: absolute;
        transform: translate(${rem(340)}, ${rem(30)});
        width: ${rem(50)};

        @keyframes moveTap {
            0%,
            20% {
                transform: translate(${rem(340)}, ${rem(30)});
            }
            30%,
            40% {
                transform: translate(${rem(190)}, ${rem(-70)});
            }
            50%,
            60% {
                transform: translate(${rem(80)}, ${rem(-70)});
            }
            70%,
            80% {
                transform: translate(${rem(190)}, ${rem(-70)});
            }
            100% {
                transform: translate(${rem(340)}, ${rem(30)});
            }
        }
    }
`;

const TopicSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
    margin-left: ${rem(20)};
    position: relative;
    width: ${rem(450)};
    padding-bottom: ${rem(20)};

    .tablet-img {
        width: ${rem(450)};
    }
`;

const TopicTitle = styled.h2`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('L')};
        padding-top: ${rem(10)};
        padding-bottom: ${rem(10)};
    `}
`;

const TopicSubTitle = styled.h4`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('S')};
        padding-bottom: ${rem(10)};
    `}
`;

const TopicDescription = styled.p`
    ${({
        theme: {
            base: {
                fonts: { lightFont }
            }
        }
    }) => `
        font-family: ${lightFont};
        margin: ${rem(20)} 0 ${rem(15)};
        height: ${rem(120)};

       ul {
         margin: 0;
       }
       ul.dashed {
         list-style-type: none;
       }
       ul.dashed > li {
         text-indent: -2px;
       }
       ul.dashed > li:before {
         content: "- ";
         text-indent: -5px;
       }
    `}
`;
