import { Global, css } from '@emotion/react';
import { customReset } from './custom-reset';
import { Theme } from './theme';

const GlobalStyles = () => {
    return (
        <Global
            styles={({
                base: {
                    colors,
                    fonts: { getTextsConfig, boldFont },
                    zindex
                }
            }: Theme) => css`
                ${customReset}

                @font-face {
                    font-family: 'Helvetica Neue LT Pro 75 Bold';
                    font-style: normal;
                    font-weight: normal;
                    src: url('/static/fonts/helveticaNeueLTPro/HelveticaNeueLTProBd.woff')
                        format('woff');
                }

                @font-face {
                    font-family: 'Helvetica Neue LT Pro 45 Light';
                    font-style: normal;
                    font-weight: normal;
                    src: url('/static/fonts/helveticaNeueLTPro/HelveticaNeueLTProLt.woff')
                        format('woff');
                }

                @font-face {
                    font-family: 'svg-iconsfont';
                    src: url('/static/fonts/iconsFont/svg-iconsfont.ttf')
                        format('truetype');
                }

                html,
                body {
                    overflow: hidden;
                }

                html {
                    @media screen and (orientation: portrait) {
                        height: 100vw;
                        left: 0;
                        overflow-x: hidden;
                        position: absolute;
                        top: 100%;
                        transform-origin: left top;
                        transform: rotate(-90deg);
                        width: 100vh;
                    }
                }

                body {
                    background-color: ${colors.layout.background};
                    color: ${colors.primary.deep};
                    ${getTextsConfig('S')};
                    font-family: ${boldFont};
                    margin: 0;
                }

                body,
                #root {
                    height: 100%;
                }

                .ReactModal__Overlay {
                    background-color: transparent !important;
                    z-index: ${zindex.modalOverlay};
                }

                #ot-sdk-btn-floating.ot-floating-button button {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }
            `}
        />
    );
};

export { GlobalStyles };
