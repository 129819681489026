import styled from '@emotion/styled';

import { rem } from 'styles/utils/sizes';
import { Icon, IconWrapper } from 'modules/app/shared/icon/icon';
import React from 'react';

const ClinicQuickTourPreviewWrapper = styled.div`
    animation: showQuickTour 0.3s;
    position: absolute;
    right: ${rem(30)};
    top: ${rem(67)};

    @keyframes showQuickTour {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }
`;

const ClinicQuickTourPreviewTitle = styled.h4`
    ${({
        theme: {
            base: {
                fonts: { getTextsConfig }
            }
        }
    }) => `
        ${getTextsConfig('S')};
        margin-bottom: ${rem(13)};
        text-align: left;

        &:hover {
            cursor: default;
        }
    `}
`;

const ClinicQuickTourPreviewPicture = styled.picture`
    ${({
        theme: {
            base: { colors, zindex }
        }
    }) => `
        align-items: center;
        display: flex;
        height: ${rem(158)};
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: ${rem(244)};

        &:hover {
            cursor: pointer;

            > ${IconWrapper} {
                transform: scale(1.1);
            }
        }

        > ${IconWrapper} {
            color: ${colors.neutral.fullLight};
            transition: transform 0.3s ease;
            z-index: ${zindex.default};
        }

        > * {
            pointer-events: none;
        }
    `}
`;

const ClinicQuickTourPreviewImage = styled.img`
    position: absolute;
    width: ${rem(244)};
`;

type ClinicQuickTourPreviewProps = {
    title: string;
    videoPoster: string;
    onClick: (event: React.MouseEvent) => void;
};

const ClinicQuickTourPreview = ({
    title,
    videoPoster,
    onClick
}: ClinicQuickTourPreviewProps) => (
    <ClinicQuickTourPreviewWrapper>
        <ClinicQuickTourPreviewTitle>{title}</ClinicQuickTourPreviewTitle>
        <ClinicQuickTourPreviewPicture onClick={onClick}>
            <Icon iconName='play-circle' iconSize={47} />
            <ClinicQuickTourPreviewImage alt={title} src={videoPoster} />
        </ClinicQuickTourPreviewPicture>
    </ClinicQuickTourPreviewWrapper>
);

export { ClinicQuickTourPreview };
