import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';

class HttpResponseError<T> extends Error {
    constructor(
        public status: number,
        public data: T,
        public message: string,
        stack?: string
    ) {
        super(message);
        this.stack = stack;
    }
}

export { HttpResponseError };

const addErrorInterceptor: (value: AxiosError) => AxiosResponse = (
    error: AxiosError
) => {
    if (error && error.response) {
        if (
            error.response.status >= StatusCodes.INTERNAL_SERVER_ERROR ||
            error.response.status === StatusCodes.FORBIDDEN
        ) {
            throw new HttpResponseError(
                error.response.status,
                error.response.data,
                'Error from API',
                `Request ${error.request.responseURL} failed with status ${error.response.status}`
            );
        }
    }
    throw error;
};

export { addErrorInterceptor };
