import { ReactElement } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { getRgbaStrFromHexColor } from 'styles/utils/color';

export interface SpinnerProps {
    isFullPage?: boolean;
}

const SpinnerOverlay = styled.div<SpinnerProps>`
    ${({
        theme: {
            base: { colors, zindex }
        },
        isFullPage
    }) => `
    align-items: center;
    background-color: ${getRgbaStrFromHexColor(colors.neutral.fullLight, 0.8)};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: ${isFullPage ? 'fixed' : 'absolute'};
    top: 0;
    width: 100%;
    z-index: ${zindex.spinner}
  `}
`;

const SpinnerWrapper = styled.div`
    display: inline-block;
    height: ${rem(75)};
    position: relative;
    width: ${rem(75)};
`;

const SpinnerItem = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: ${colors.primary.brand};
    height: ${rem(12)};
    position: absolute;
    top: ${rem(33)};
    width: ${rem(12)};

    &:nth-of-type(1) {
      animation: lds-ellipsis1 0.6s infinite;
      left: ${rem(8)};
    }
    &:nth-of-type(2) {
      animation: lds-ellipsis2 0.6s infinite;
      left: ${rem(8)};
    }
    &:nth-of-type(3) {
      animation: lds-ellipsis2 0.6s infinite;
      left: ${rem(32)};
    }
    &:nth-of-type(4) {
      animation: lds-ellipsis3 0.6s infinite;
      left: ${rem(56)};
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(${rem(24)}, 0);
      }
    }
  `}
`;

const Spinner = ({ isFullPage }: SpinnerProps): ReactElement => {
    return (
        <SpinnerOverlay isFullPage={isFullPage}>
            <SpinnerWrapper>
                <SpinnerItem></SpinnerItem>
                <SpinnerItem></SpinnerItem>
                <SpinnerItem></SpinnerItem>
                <SpinnerItem></SpinnerItem>
            </SpinnerWrapper>
        </SpinnerOverlay>
    );
};

export { Spinner };
