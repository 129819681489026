import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { DefaultModal } from '../default/default-modal';
import { HelpPanel } from 'modules/app/shared/modal/templates/modal-help/modal-help-panel';

const ModalHelpWrapper = styled.div`
    ${({
        theme: {
            base: { breakpoints }
        }
    }) => `
        display: grid;
        grid-template-columns: ${rem(398)} calc(100% - ${rem(398)});

        @media (min-width: ${rem(breakpoints.large)}) {
            grid-template-columns: ${rem(530)} calc(100% - ${rem(530)});
        }
    `}
`;

const ModalHelpImage = styled.img`
    width: 100%;
`;

export type ModalHelpProps = {
    onClose: (confirm: boolean) => void;
};

const ModalHelp: React.FC<ModalHelpProps> = ({
    onClose
}: PropsWithChildren<ModalHelpProps>) => {
    return (
        <DefaultModal
            onClose={() => onClose(false)}
            bgStyle='dark'
            modalSize='fullWidth'
            originModal='left'
            isBgBlur={false}
            showCloseBtn={true}
        >
            <ModalHelpWrapper>
                <HelpPanel />
                <ModalHelpImage
                    src='/static/images/shared/help-image.jpg'
                    alt=''
                />
            </ModalHelpWrapper>
        </DefaultModal>
    );
};

export { ModalHelp };
