import * as yup from 'yup';

export type Feedback = {
    satisfaction: string;
    comments?: string;
};

const feedbackFormSchema: yup.SchemaOf<Feedback> = yup
    .object<Record<keyof Feedback, yup.AnySchema>>()
    .shape<Record<keyof Feedback, yup.AnySchema>>({
        comments: yup.string().max(500),
        satisfaction: yup.string().required()
    });

export { feedbackFormSchema };
