import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

import { ClinicArea, clinicQuickTours } from 'domain/clinic-overview';

import {
    ClinicMapAreasLayer,
    ClinicMapAreasLayerProps
} from './clinic/clinic-map-areas-layer';
import {
    ClinicQuickTour,
    ClinicQuickTourProps
} from './clinic/clinic-quick-tour';
import { ClinicAreasLayer } from './clinic/clinic-areas-layer';
import { ClinicHotspotsLayer } from './clinic/clinic-hotspots-layer';
import { VirtualClinicAdvice } from '../shared/advice/advice';
import { getCountryFromUrl } from 'utils/url-utils';
import { useState } from 'react';

const ClinicWrapper = styled.div`
    height: 100%;
    position: relative;
`;

const ClinicBgImage = styled.img<{ scaledImage: number }>`
    ${({ scaledImage }) => `
        animation: lightnessImage 3s;
        left: 50%;
        opacity: 0.25;
        position: absolute;
        top: 0;
        transform-origin: 50% ${rem(330)};
        transition: transform 0.5s ease-out;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        width: auto;

        ${
            scaledImage === 2 || scaledImage === 1 || scaledImage === 0
                ? 'transform: translateX(-50%) scale(1.2);'
                : 'transform: translateX(-50%) scale(1);'
        };

        @keyframes lightnessImage {
            0%,
            70% {
                opacity: 1;
            }
            100% {
                opacity: 0.3;
            }
        }
    `}
`;

const ClinicMapAreasWrapper = styled.div`
    left: 50%;
    position: absolute;
    top: ${rem(-12)};
    transform: translateX(-50%);
`;

type ClinicProps = Pick<
    ClinicMapAreasLayerProps,
    'onClickArea' | 'onFocusArea' | 'onUnfocusArea'
> &
    Pick<ClinicQuickTourProps, 'onOpenMenu'> & {
        areas: ClinicArea[];
        activeAreaIndex: number;
        scaledImage: number;
        onClickOutArea: () => void;
        onOpenMenu: (open: boolean) => void;
        onAnimationEnd: () => void;
    };

const country = getCountryFromUrl();

const Clinic = ({
    areas,
    activeAreaIndex,
    scaledImage,
    onOpenMenu,
    onClickArea,
    onClickOutArea,
    onFocusArea,
    onUnfocusArea,
    onAnimationEnd
}: ClinicProps) => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <>
            <ClinicWrapper
                onClick={() => {
                    if (modalVisible) {
                        return;
                    }
                    onClickOutArea();
                }}
            >
                <ClinicBgImage
                    draggable='false'
                    src='/static/images/clinic-overview/bg-clinic-overview.jpg'
                    alt=''
                    scaledImage={scaledImage}
                    onAnimationEnd={() => onAnimationEnd()}
                />
                <ClinicMapAreasWrapper>
                    <ClinicMapAreasLayer
                        areas={areas}
                        activeAreaIndex={activeAreaIndex}
                        onClickArea={onClickArea}
                        onFocusArea={onFocusArea}
                        onUnfocusArea={onUnfocusArea}
                    />
                    <ClinicAreasLayer
                        areas={areas}
                        activeAreaIndex={activeAreaIndex}
                    />
                    <ClinicHotspotsLayer
                        area={areas[activeAreaIndex]}
                        onModalVisible={(visible) => {
                            setModalVisible(visible);
                        }}
                    />
                </ClinicMapAreasWrapper>
                <VirtualClinicAdvice />
            </ClinicWrapper>
            {!['us', 'e1', 'e2'].includes(country) && (
                <ClinicQuickTour
                    areaid={
                        areas[activeAreaIndex] &&
                        areas[activeAreaIndex]?.quickTour
                            ? areas[activeAreaIndex]?.id
                            : undefined
                    }
                    quickTour={
                        areas[activeAreaIndex]?.quickTour
                            ? areas[activeAreaIndex].quickTour!
                            : clinicQuickTours.default!
                    }
                    onOpenMenu={onOpenMenu}
                />
            )}
        </>
    );
};

export { Clinic };
