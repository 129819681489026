import { get } from 'services/api/http/axios-client';

interface CountriesResponse {
    code: string;
}

export const countryRepositoryImplementation = {
    getCountries: async () => {
        const data = await get<CountriesResponse[]>('countries', {}).catch();

        const countryCodes: string[] = [];

        data.forEach((country) => {
            countryCodes.push(country.code.toLowerCase());
        });

        return countryCodes;
    }
};
