import { ThemeProvider } from '@emotion/react';
import styled from '@emotion/styled';
import { configuration } from 'configuration';
import { Suspense, useContext } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { createQueryClient } from 'services/api/http/react-query-client';

import '../services/i18n/index';
import { GlobalStyles } from '../styles/global-styles';
import theme from '../styles/theme';
import { AppTracker } from './app/app-tracker';
import { QueryErrorResetBoundaryWrapper } from './app/error/error-boundary';
import { Header } from './app/shared/header/header';
import { Spinner } from './app/shared/spinner/spinner';
import {
    ClinicOverviewContext,
    ClinicOverviewProvider
} from './context/clinic-overview';

import { Provider } from 'react-redux';
import { initializeAppInsights } from 'services/logger/applicationInsightsService';
import { store } from '../store';
import { Routing } from './app/routing';

const Main = styled.main`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const Section = styled.section`
    flex-grow: 1;
`;

const Container = styled.div`
    height: 100%;
    text-align: center;
`;

initializeAppInsights();
const queryClient = createQueryClient();

const App = () => {
    const {
        state: { openMenu }
    } = useContext(ClinicOverviewContext);

    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <GlobalStyles />
                <Provider store={store}>
                    <Router>
                        <ClinicOverviewProvider>
                            <AppTracker>
                                <Main>
                                    <QueryErrorResetBoundaryWrapper>
                                        <Suspense fallback={<Spinner />}>
                                            <Header openMenu={openMenu} />
                                            <Section>
                                                <QueryErrorResetBoundaryWrapper>
                                                    <Container>
                                                        <Suspense
                                                            fallback={
                                                                <Spinner />
                                                            }
                                                        >
                                                            <Routing />
                                                        </Suspense>
                                                    </Container>
                                                </QueryErrorResetBoundaryWrapper>
                                            </Section>
                                        </Suspense>
                                    </QueryErrorResetBoundaryWrapper>
                                </Main>
                            </AppTracker>
                        </ClinicOverviewProvider>
                    </Router>
                </Provider>
                {configuration.app.environment !== 'production' && (
                    <ReactQueryDevtools />
                )}
                {/* <!-- OneTrust Cookies Settings button start --> */}
                <button id='ot-sdk-btn' className='ot-sdk-show-settings'>
                    Cookie Settings
                </button>
                {/* <!-- OneTrust Cookies Settings button end --> */}
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
