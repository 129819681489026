import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { ButtonSecondary } from '../shared/button/button';
import { Translate } from '../shared/translate';
import { PatientsMenu } from './patients-modal/patients-menu';

export type PatientsModalProps = {
    onClose?: () => void;
};

const PatientsModalWrapper = styled.div`
    ${({
        theme: {
            base: { breakpoints }
        }
    }) => `
        align-items: stretch;
        display: flex;
        min-height: ${rem(780)};
        max-height: ${rem(780)};
        height: 100vh;

        @media (min-width: ${rem(breakpoints.large)}) {
            min-height: ${rem(1050)};
            max-height: ${rem(1050)};
        }
    `}
`;

const PatientsIntro = styled.div`
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-width: ${rem(472)};
    padding: ${rem(70)} ${rem(140)} ${rem(20)} ${rem(70)};
`;

const PatientsIntroTitle = styled.h2`
    ${({
        theme: {
            base: {
                fonts: { getHeadlinesConfig }
            }
        }
    }) => `
        ${getHeadlinesConfig('L')};
    `}
`;

const PatientsModal = ({ onClose }: PatientsModalProps) => {
    return (
        <PatientsModalWrapper>
            <PatientsIntro>
                <PatientsIntroTitle>
                    <Translate i18nKey='patientsMenu.title' />
                </PatientsIntroTitle>
                <ButtonSecondary
                    iconName='arrow-left'
                    btnSize='mini'
                    onClick={onClose}
                >
                    <Translate i18nKey='patientsMenu.backBtn' />
                </ButtonSecondary>
            </PatientsIntro>
            <PatientsMenu />
        </PatientsModalWrapper>
    );
};

export { PatientsModal };
