import { useCallback, useMemo } from 'react';

import {
    ClinicArea,
    ClinicTransform,
    ClinicAreaPaintingInfo
} from 'domain/clinic-overview';
import { transformCSS, translateCoords } from 'domain/coords';

type UseClinicOverviewAreaProps = {
    areas: ClinicArea[];
    activeAreaIndex: number;
};

const useClinicOverviewArea = ({
    areas,
    activeAreaIndex
}: UseClinicOverviewAreaProps) => {
    const activeAreaOrder = useMemo(
        () => areas[activeAreaIndex]?.paintingInfo?.[0]?.order,
        [areas, activeAreaIndex]
    );

    const maxAreaOrder = useMemo(() => {
        const orders = areas
            .flatMap((area) => area.paintingInfo)
            .flatMap((info) => info.order);
        return Math.max(...orders);
    }, [areas]);

    const applyCSSTransformations = useCallback(
        (areaIndex: number, areaInfo: ClinicAreaPaintingInfo) => {
            const { order, base, highlight } = areaInfo;

            const applyToActiveLayer = areaIndex === activeAreaIndex;

            const applyToLastLayer =
                order === activeAreaOrder && order === maxAreaOrder;

            const applyToAboveLayer =
                activeAreaOrder != null &&
                order < activeAreaOrder &&
                areaIndex !== activeAreaIndex;

            const getTransformFor = (
                transform: ClinicTransform | undefined,
                forceApplyTranslation: boolean = false
            ) => {
                const applyScale = applyToActiveLayer || applyToLastLayer;
                const applyTranslation =
                    forceApplyTranslation ||
                    applyToAboveLayer ||
                    applyToLastLayer;

                return transformCSS({
                    scale: applyScale ? transform?.scale : 1,
                    translateX: applyTranslation ? transform?.translateX : 0,
                    translateY: applyTranslation ? transform?.translateY : 0
                });
            };

            const applyHighlightTransform =
                applyToActiveLayer || applyToLastLayer;

            return {
                applyImageCSS: getTransformFor(base.transform),
                applyHighlightCSS: getTransformFor(
                    applyHighlightTransform
                        ? highlight?.transform
                        : base.transform,
                    applyToActiveLayer
                )
            };
        },
        [activeAreaIndex, activeAreaOrder, maxAreaOrder]
    );

    const recalculateCoords = useCallback(
        (areaIndex: number, areaInfo: ClinicAreaPaintingInfo): string => {
            const {
                order,
                base: { coords = '', fullCoords = '', transform }
            } = areaInfo;

            const applyToAboveLayer =
                activeAreaOrder != null &&
                order < activeAreaOrder &&
                areaIndex !== activeAreaIndex;

            return translateCoords(
                applyToAboveLayer ? transform?.translateX : 0,
                applyToAboveLayer ? transform?.translateY : 0
            )(showFullMap(activeAreaOrder, order) ? fullCoords : coords);
        },
        [activeAreaIndex, activeAreaOrder]
    );

    const showHighlighted = useCallback((area: ClinicArea): boolean => {
        return area.state.highlight || area.state.active;
    }, []);

    const showFullMap = useCallback(
        (activeAreaOrder: number, order: number): boolean => {
            if (activeAreaOrder == 2 || activeAreaOrder == 3) {
                if (order == 1 || order == activeAreaOrder) {
                    return true;
                }
            }

            return false;
        },
        []
    );

    const applyCSSFilter = useCallback(
        (areaIndex: number): boolean => {
            return activeAreaIndex >= 0 && activeAreaIndex !== areaIndex;
        },
        [activeAreaIndex]
    );

    return {
        applyCSSTransformations,
        recalculateCoords,
        showHighlighted,
        applyCSSFilter
    };
};

export { useClinicOverviewArea };
