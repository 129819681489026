import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';
import { Translate } from '../../translate';
import { ButtonBase, ButtonPrimary } from '../../button/button';

const PanoramicNavigationWrapper = styled.div`
    ${({
        theme: {
            base: { zindex }
        }
    }) => `
        height: ${rem(40)};
        position: absolute;
        z-index: ${zindex.above};

        ${ButtonBase} {
            position: fixed;
            bottom: 0;

            &[data-button='prevBtn'] {
                left: 0;
            }

            &[data-button='nextBtn'] {
                right: 0;
            }
        }
    `}
`;

const PanoramicLabelWrapper = styled.span<{ withContent: boolean }>`
    ${({
        theme: {
            base: { colors }
        },
        withContent
    }) => `
        position: fixed;
        height: ${rem(40)};
        width: 300px;
        left: 50%;
        bottom: 0px;
        margin-left: -150px;
        color: white;

        ${withContent}

    `}
`;

export type PanoramicNavigationProps = {
    prevLabel?: string;
    nextLabel?: string;
    textLabel?: string;
    onClickPrev?: () => void;
    onClickNext?: () => void;
    isFontSizeLarge?: boolean;
};

const PanoramicNavigation = ({
    prevLabel,
    nextLabel,
    textLabel,
    onClickPrev,
    onClickNext,
    isFontSizeLarge
}: PanoramicNavigationProps) => {
    return (
        <PanoramicNavigationWrapper>
            {onClickPrev && (
                <ButtonPrimary
                    iconName='arrow-left'
                    onClick={onClickPrev}
                    data-button='prevBtn'
                    isFontSizeLarge={isFontSizeLarge}
                >
                    {prevLabel ? (
                        prevLabel
                    ) : (
                        <Translate i18nKey='panoramic.prevBtnLabel' />
                    )}
                </ButtonPrimary>
            )}
            <PanoramicLabelWrapper
                withContent={
                    typeof textLabel === 'string' && textLabel.trim().length > 0
                }
            >
                {textLabel}
            </PanoramicLabelWrapper>

            {onClickNext && (
                <ButtonPrimary
                    iconName='arrow-right'
                    onClick={onClickNext}
                    iconPosition='right'
                    data-button='nextBtn'
                    isFontSizeLarge={isFontSizeLarge}
                >
                    {nextLabel ? (
                        nextLabel
                    ) : (
                        <Translate i18nKey='panoramic.nextBtnLabel' />
                    )}
                </ButtonPrimary>
            )}
        </PanoramicNavigationWrapper>
    );
};

export {
    PanoramicNavigation,
    PanoramicNavigationWrapper,
    PanoramicLabelWrapper
};
