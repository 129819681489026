import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const RoomName = styled.div`
    ${({
        theme: {
            base: { colors }
        }
    }) => `
        border-bottom: ${rem(1)} solid ${colors.neutral.grey};
        border-top: ${rem(1)} solid ${colors.neutral.grey};
        color: ${colors.neutral.grey};
        padding: ${rem(16)} 0;

        &:hover {
            color: ${colors.primary.brand};
            cursor:pointer;
        }
    `}
`;
