import styled from '@emotion/styled';
import { rem } from 'styles/utils/sizes';

export const ModalVideoWrapper = styled.div`
    position: relative;
`;
export const ModalVideoSource = styled.div`
    ${({
        theme: {
            base: { zindex }
        }
    }) => `
        line-height: 0;
        overflow: hidden;
        width: 100%;

        > iframe {
            position: relative;
            width: 100%;
            z-index: ${zindex.tooltip * 2};
        }
    `}
`;
export const ModalVideoDataList = styled.ul`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getTextsConfig }
            }
        }
    }) => `
        background-color: ${colors.neutral.fullLight};
        ${getTextsConfig('ML')};
        padding: 0 ${rem(30)};

        > li {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
        }
    `}
`;
export const ModalVideoDataDescription = styled.li`
    ${({
        theme: {
            base: {
                colors,
                fonts: { getTextsConfig, lightFont }
            }
        }
    }) => `
        background-color: ${colors.neutral.fullLight};
        color: ${colors.primary.brand};
        font-family: ${lightFont};
        ${getTextsConfig('XS')};
    `}
`;
export const ModalVideoFooter = styled.footer`
    align-items: center;
    display: grid;
    grid-template-columns: auto ${rem(400)};
`;
