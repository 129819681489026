const getCountryFromUrl = () => {
    const slug = window.location.pathname.split('/').splice(1);

    if (slug.length > 0) {
        return slug[0].length === 2 ? slug[0] : '';
    } else {
        return '';
    }
};

export { getCountryFromUrl };
